import palette from '../palette';
import typography from '../typography';

const MuiTable = {
	root: {
		...typography.body1,
		backgroundColor: `${palette.primary.main} !important`
	}
};

export default MuiTable;
