/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ACTIVATE_DEACTIVATE_PRODUCT = 'ACTIVATE_DEACTIVATE_PRODUCT';

/**
 * Action called after get all products
 * @param products
 */
export const getAllProducts = (products) => {
	return {
		type: GET_ALL_PRODUCTS,
		products: products
	};
};

/**
 * Action called after add product
 * @param product
 */

export const addProduct = (product) => {
	return {
		type: ADD_PRODUCT,
		product
	};
};

/**
 * Action called after update product details
 * @param product
 */

export const updateProduct = (product) => {
	return {
		type: UPDATE_PRODUCT,
		product
	};
};

/**
 * Action called after activate deactivate product
 * @param product
 */

export const activateDeactivateProduct = (product) => {
	return {
		type: ACTIVATE_DEACTIVATE_PRODUCT,
		product
	};
};
