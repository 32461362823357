/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import {
	CLEAR_ROOMS,
	GET_ALL_CONFERENCE_ROOMS,
	GET_ALL_INDIVIDUAL_ROOMS,
	GET_ALL_ROOMS,
	GET_ALL_SESSION_ROOMS,
	MESSAGE_READ,
	READ_UNREAD_NOTIFICATIONS,
	SET_RECEIVED_MESSAGE_FROM_NEW_ROOM,
	SET_SELECTED_CHAT_FILTER,
	SET_SELECTED_ROOM,
	UPDATE_ROOM_DETAILS,
	UPDATE_SINGLE_ROOM
} from './MessagesActions';

const initialState = {
	rooms: [],
	conferenceRooms: [],
	sessionRooms: [],
	individualRooms: [],
	selectedChatFilter: 'All',
	selectedRoom: null,
	receivedMessageFromNewRoom: false
};

/**
 * Rooms reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const MessagesReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of rooms to be shown

		case SET_RECEIVED_MESSAGE_FROM_NEW_ROOM:
			return Object.assign({}, state, {
				receivedMessageFromNewRoom: action.value
			});

		case SET_SELECTED_ROOM:
			return Object.assign({}, state, {
				selectedRoom: action.room
			});

		case SET_SELECTED_CHAT_FILTER:
			return Object.assign({}, state, {
				selectedChatFilter: action.selectedChatFilter
			});

		case GET_ALL_INDIVIDUAL_ROOMS:
			return Object.assign({}, state, {
				individualRooms: action.rooms
			});

		case GET_ALL_SESSION_ROOMS:
			return Object.assign({}, state, {
				sessionRooms: action.rooms
			});
		case GET_ALL_CONFERENCE_ROOMS:
			return Object.assign({}, state, {
				conferenceRooms: action.rooms
			});

		case GET_ALL_ROOMS:
			return Object.assign({}, state, {
				rooms: action.rooms
			});

		case CLEAR_ROOMS:
			return {
				rooms: []
			};

		case MESSAGE_READ:
			const { roomData: targetedRoom } = action;
			const targetRoomRowIndex = state.rooms.findIndex((x) => x.id === targetedRoom.id);

			if (targetRoomRowIndex === -1) {
				return state;
			}

			const updatedRoomsWithMessageRead = [...state.rooms];
			updatedRoomsWithMessageRead[targetRoomRowIndex] = targetedRoom;

			return {
				...state,
				rooms: updatedRoomsWithMessageRead
			};

		case UPDATE_SINGLE_ROOM: {
			const { roomData } = action;
			// Function to update room in an array
			const updateRoomInArray = (rooms) => {
				const targetRowIndex = rooms.findIndex((x) => x.id === roomData.id);
				if (targetRowIndex === -1) {
					// If the room is not found, return the current rooms array unchanged
					return rooms;
				}
				// Create a new array with the updated room
				const updatedRooms = [...rooms];
				updatedRooms[targetRowIndex] = roomData;
				return updatedRooms;
			};

			// Update rooms, conferenceRooms, sessionRooms, and individualRooms
			return {
				...state,
				rooms: updateRoomInArray(state.rooms),
				conferenceRooms: updateRoomInArray(state.conferenceRooms),
				sessionRooms: updateRoomInArray(state.sessionRooms),
				individualRooms: updateRoomInArray(state.individualRooms)
			};
		}
		case UPDATE_ROOM_DETAILS: {
			let data = Object.assign([], state.rooms);
			data = data.map((room) => {
				if (room.RoomId === action.room.roomId) {
					room.isRead = false;
					room.message = action.room.message;
					room.createdAt = action.room.createdAt;
					return room;
				}
				return room;
			});
			return Object.assign({}, state, {
				rooms: data
			});
		}
		case READ_UNREAD_NOTIFICATIONS: {
			let data = Object.assign({}, state.notifications);
			data.rows = data.rows.map((notification) => {
				if (notification.id === action.notification.id) {
					notification.enabled = !notification.enabled;
					return notification;
					// return action.notification;
				}
				return notification;
			});

			return Object.assign({}, state, {
				notifications: data
			});
		}

		default:
			return state;
	}
};

export default MessagesReducer;
