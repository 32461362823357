/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_DIAGNOSIS, CREATE_DIAGNOSIS, GET_ALL_DIAGNOSIS, UPDATE_DIAGNOSIS } from './DiagnosisManagementActions';

const initialState = {
	diagnosis: []
};

/**
 * Diagnosis reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const DiagnosisReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of diagnosis to be shown
		case GET_ALL_DIAGNOSIS:
			return Object.assign({}, state, {
				diagnosis: action.diagnosis
			});

		//Append newly created diagnosis
		case CREATE_DIAGNOSIS:
			return Object.assign({}, state, {
				diagnosis: [...state.diagnosis.rows, action.diagnosis]
			});

		//Update diagnosis details
		case UPDATE_DIAGNOSIS: {
			let data = Object.assign({}, state.diagnosis);

			data.rows = data.rows.map((diagnosis) => {
				if (diagnosis.id === action.diagnosis.id) {
					return action.diagnosis;
				}
				return diagnosis;
			});

			return Object.assign({}, state, {
				diagnosis: data
			});
		}
		case ACTIVATE_DEACTIVATE_DIAGNOSIS: {
			let data = Object.assign({}, state.diagnosis);
			data.rows = data.rows.map((diagnosis) => {
				if (diagnosis.id === action.diagnosis.id) {
					return action.diagnosis;
				}
				return diagnosis;
			});

			return Object.assign({}, state, {
				diagnosis: data
			});
		}

		default:
			return state;
	}
};

export default DiagnosisReducer;
