// Export Constants (Action names)
export const SET_POSTS_SEND_REMINDER = 'SET_POSTS_SEND_REMINDER';
export const CLEAR_POSTS_SEND_REMINDER = 'CLEAR_POSTS_SEND_REMINDER';

export const setPostsSendReminder = (postId) => {
	return {
		type: SET_POSTS_SEND_REMINDER,
		postId
	};
};

export const clearPostsSendReminder = () => {
	return {
		type: CLEAR_POSTS_SEND_REMINDER
	};
};
