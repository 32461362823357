/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Auth from '../../utils/Auth';

// Export Constants
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FOTGOT_PASSWORD = 'FOTGOT_PASSWORD';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const SESSION_EXPIRED_HIDE_MESSAGE = 'SESSION_EXPIRED_HIDE_MESSAGE';
export const LOGGED_IN_AS = 'LOGGED_IN_AS';
export const VERIFIED_EMAIL = 'VERIFIED_EMAIL';
export const UPDATE_COMMUNITY_COUNT = 'UPDATE_COMMUNITY_COUNT';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const UPDATE_IS_INTUITIVE_ACCOUNT_CONNECTED = 'UPDATE_IS_INTUITIVE_ACCOUNT_CONNECTED';

/**
 * Actions related to the authentication module
 */

/**
 * Action called after sign in api call
 * @param data
 * @returns {{type: string, user: (*|number), accessToken: *}}
 */
export function signIn(user, accessToken, loggedInAs) {
	return {
		type: SIGN_IN,
		user: user,
		loggedInAs: loggedInAs
	};
}

/**
 * Action called after sign up api call
 * @param user
 * @returns {{type: string, user: *}}
 */
export function signUp(user) {
	return {
		type: SIGN_UP,
		user
	};
}

/**
 * Action called after change password api call
 * @param data
 * @returns {{type: string, data: *}}
 */
export function changePassword(data) {
	return {
		type: CHANGE_PASSWORD,
		data
	};
}

/**
 * User logout/signout action
 * @param data
 * @returns {{type: string, data: *}}
 */
export function signOut() {
	Auth.logout();
	return {
		type: SIGN_OUT
	};
}

/**
 * Update profile image action
 * @param imageUrl
 */
export function updateProfileImage(imageUrl) {
	Auth.updateProfileImage(imageUrl);
	return {
		type: UPDATE_USER_PROFILE,
		imageUrl
	};
}

/**
 * Delete profile image action
 * @param imageUrl
 */
export function deleteProfileImage() {
	return {
		type: DELETE_USER_PROFILE
	};
}

/**
 * session expire action
 * @param imageUrl
 */
export function sessionExpired(error = 'Session expired') {
	return {
		type: SESSION_EXPIRED,
		error
	};
}

/**
 * User sessionExpired action
 */
export const sessionExpiredHideMessage = () => {
	return {
		type: SESSION_EXPIRED_HIDE_MESSAGE
	};
};

/**
 * User logged in as
 */
export const setLoggedInAs = (role) => {
	return {
		type: LOGGED_IN_AS,
		loggedInAs: role
	};
};

/**
 * User logged in as
 */
export const emailOTPVerifivation = (isVerified) => {
	return {
		type: VERIFIED_EMAIL,
		isVerified: isVerified
	};
};

/**
 * User logged in as
 */
export const setIsChangeEmail = (isChangeEmail) => {
	Auth.isChangeEmail(isChangeEmail);
	return {
		type: CHANGE_EMAIL,
		isChangeEmail: isChangeEmail
	};
};

/**
 * User logged in as
 */
export const updateEmail = (email) => {
	return {
		type: UPDATE_EMAIL,
		email: email
	};
};

export const updateIsIntuitiveAccountConnected = (isIntuitiveAccountConnected) => {
	return {
		type: UPDATE_IS_INTUITIVE_ACCOUNT_CONNECTED,
		isIntuitiveAccountConnected
	};
};

/**
 * User logged in as
 */
export const updateCommunityCount = (count) => {
	return {
		type: UPDATE_COMMUNITY_COUNT,
		count: count
	};
};
