import { BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION, CREATE_CONFERENCE_PRESENTATION_PART, SET_CONFERENCE_PRESENTATION_PROGRESS, UNSET_CONFERENCE_PRESENTATION } from './BackgroundUploadActions';

const initialState = {
	backgroundUploadConferencePresentation: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const BackgroundUploadConferencePresentationReducer = (state = initialState, action) => {
	switch (action.type) {
		//Append newly created conferencePresentation
		case BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION:
			console.log('BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION reduces: ', action);
			return Object.assign({}, state, {
				backgroundUploadConferencePresentation: {
					conferencePresentation: action.conferencePresentation,
					mediaAssetsToBeAdded: action.conferencePresentation.mediaAssetsToBeAdded,
					isUploadStart: false,
					totalMediaSize: action.totalMediaSize,
					totalUploadedMediaSize: 0,
					progress: 0
				}
			});

		//Create new part in particular file
		case CREATE_CONFERENCE_PRESENTATION_PART: {
			let tempBackgroundUploadConferencePresentation = Object.assign({}, state.backgroundUploadConferencePresentation);
			if (tempBackgroundUploadConferencePresentation && tempBackgroundUploadConferencePresentation.mediaAssetsToBeAdded && tempBackgroundUploadConferencePresentation.mediaAssetsToBeAdded.length > 0) {
				tempBackgroundUploadConferencePresentation.mediaAssetsToBeAdded = tempBackgroundUploadConferencePresentation.mediaAssetsToBeAdded.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						const part = {
							partNumber: action.partNumber,
							presignedUrl: action.presignedUrl,
							totalPartSize: action.totalPartSize,
							uploadedPartSize: 0
						};
						if (singleMedia.parts.length > 0) {
							const findPart = singleMedia.parts.find((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPart === undefined) {
								singleMedia.parts.push(part);
							}
						} else {
							singleMedia.parts.push(part);
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});
				tempBackgroundUploadConferencePresentation.isUploadStart = true;
			}

			return Object.assign({}, state, {
				backgroundUploadConferencePresentation: tempBackgroundUploadConferencePresentation
			});
		}

		//Set conferencePresentation progress of part in particular file
		case SET_CONFERENCE_PRESENTATION_PROGRESS: {
			let tempBackgroundUploadConferencePresentationForSetProcess = Object.assign({}, state.backgroundUploadConferencePresentation);
			if (
				tempBackgroundUploadConferencePresentationForSetProcess &&
				tempBackgroundUploadConferencePresentationForSetProcess.mediaAssetsToBeAdded &&
				tempBackgroundUploadConferencePresentationForSetProcess.mediaAssetsToBeAdded.length > 0
			) {
				tempBackgroundUploadConferencePresentationForSetProcess.mediaAssetsToBeAdded = tempBackgroundUploadConferencePresentationForSetProcess.mediaAssetsToBeAdded.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						if (singleMedia.parts.length > 0) {
							const findPartIndex = singleMedia.parts.findIndex((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPartIndex > -1) {
								singleMedia.parts[findPartIndex].uploadedPartSize = action.uploadedSize;
							}
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});

				// sum of uploaded size of each file part
				const uploadedSizeForEachFile = tempBackgroundUploadConferencePresentationForSetProcess.mediaAssetsToBeAdded.map((item) => {
					return item.parts.reduce((sum, part) => sum + part.uploadedPartSize, 0);
				});

				// sum of uploaded size including all file
				const totalUploadedSize = uploadedSizeForEachFile.reduce((sum, eachFileSize) => sum + eachFileSize, 0);
				tempBackgroundUploadConferencePresentationForSetProcess.totalUploadedMediaSize = totalUploadedSize;
				const percentCompleted = Math.round((totalUploadedSize * 100) / tempBackgroundUploadConferencePresentationForSetProcess.totalMediaSize);
				tempBackgroundUploadConferencePresentationForSetProcess.progress = percentCompleted;
			}

			return Object.assign({}, state, {
				backgroundUploadConferencePresentation: tempBackgroundUploadConferencePresentationForSetProcess
			});
		}

		//Empty conferencePresentation list
		case UNSET_CONFERENCE_PRESENTATION:
			return Object.assign({}, state, {
				backgroundUploadConferencePresentation: undefined
			});

		default:
			return state;
	}
};

export default BackgroundUploadConferencePresentationReducer;
