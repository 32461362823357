import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';

import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import translations from './localization/translations';

import store from './store';

// const store = createStore(
//     rootReducer,
//     applyMiddleware(thunk)
// );
const selectedLanguageFromSessionStorage = sessionStorage.getItem('selectedLanguage');
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(selectedLanguageFromSessionStorage ? selectedLanguageFromSessionStorage : 'en'));

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
