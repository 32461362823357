const MuiMenuItem = {
	root: {
		padding: '4px 16px 5px 16px',
		fontFamily: 'Lato',
		color: '#9fb3c8',
		'&$selected': {
			padding: '11px 0 12px 16px',
			backgroundColor: '#2a4f72 !important'
		}
	}
};

export default MuiMenuItem;
