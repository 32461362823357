/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_ROOMS = 'GET_ALL_ROOMS';
export const CLEAR_ROOMS = 'CLEAR_ROOMS';
export const UPDATE_ROOM_DETAILS = 'UPDATE_ROOM_DETAILS';
export const READ_UNREAD_NOTIFICATIONS = 'READ_UNREAD_NOTIFICATIONS';
export const UPDATE_SINGLE_ROOM = 'UPDATE_SINGLE_ROOM';
export const MESSAGE_READ = 'MESSAGE_READ';
export const GET_ALL_CONFERENCE_ROOMS = 'GET_ALL_CONFERENCE_ROOMS';
export const GET_ALL_SESSION_ROOMS = 'GET_ALL_SESSION_ROOMS';
export const GET_ALL_INDIVIDUAL_ROOMS = 'GET_ALL_INDIVIDUAL_ROOMS';
export const SET_SELECTED_CHAT_FILTER = 'SET_SELECTED_CHAT_FILTER';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';
export const SET_RECEIVED_MESSAGE_FROM_NEW_ROOM = 'SET_RECEIVED_MESSAGE_FROM_NEW_ROOM';

/**
 * Action called after get all rooms
 * @param rooms
 */

export const setMessageReceivedFromNewRoom = (value) => {
	return {
		type: SET_RECEIVED_MESSAGE_FROM_NEW_ROOM,
		value: value
	};
};

export const setSelectedChatFilter = (selectedChatFilter) => {
	return {
		type: SET_SELECTED_CHAT_FILTER,
		selectedChatFilter: selectedChatFilter
	};
};

export const updateRoomState = (rooms) => {
	return {
		type: GET_ALL_ROOMS,
		rooms: rooms
	};
};

export const setSelectedRoom = (room) => {
	return {
		type: SET_SELECTED_ROOM,
		room: room
	};
};

export const updateConferenceRoomState = (rooms) => {
	return {
		type: GET_ALL_CONFERENCE_ROOMS,
		rooms: rooms
	};
};

export const updateSessionRoomState = (rooms) => {
	return {
		type: GET_ALL_SESSION_ROOMS,
		rooms: rooms
	};
};

export const updateIndividualRoomState = (rooms) => {
	return {
		type: GET_ALL_INDIVIDUAL_ROOMS,
		rooms: rooms
	};
};

export const clearRooms = () => {
	return {
		type: CLEAR_ROOMS,
		rooms: []
	};
};

export const markMessageAsRead = (roomData) => {
	return {
		type: MESSAGE_READ,
		roomData: roomData
	};
};

/**
 * Action called after get all room
 * @param room
 */
export const updateRoomDetils = (room) => {
	return {
		type: UPDATE_ROOM_DETAILS,
		room: room
	};
};

export const updateSingleRoom = (roomData) => {
	return {
		type: UPDATE_SINGLE_ROOM,
		roomData: roomData
	};
};

/**
 * Action called after activate deactivate notification
 * @param notification
 */

export const readUnreadNotification = (notification) => {
	return {
		type: READ_UNREAD_NOTIFICATIONS,
		notification
	};
};
