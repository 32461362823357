import { GET_COMMENTS, GET_SINGLE_POST } from './PostDetailsActions';

const initialState = {
	post: [],
	comments: []
};

/**
 * PageDetailsReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const PageDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of post to be shown
		case GET_SINGLE_POST:
			return Object.assign({}, state, {
				post: action.post
			});

		//Get list of comments realted post
		case GET_COMMENTS:
			return Object.assign({}, state, {
				comments: action.comments
			});

		default:
			return state;
	}
};

export default PageDetailsReducer;
