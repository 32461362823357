import {
	EMPTY_ALL_MENTIONS_COMMENTS,
	EMPTY_ALL_USER_COMMENTS,
	EMPTY_PROFILE_POST_LIST,
	GET_ALL_MENTIONS_COMMENTS,
	GET_ALL_PROFILE_POST,
	GET_ALL_USER_COMMENTS,
	PROFILE_UPDATE
} from './SurgeonProfileActions';

const initialState = {
	profileData: undefined,
	posts: undefined,
	userComments: undefined,
	mentionsComments: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const SurgeonProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case PROFILE_UPDATE:
			return Object.assign({}, state, {
				profileData: action.profileData
			});

		//Get list of posts to be shown
		case GET_ALL_PROFILE_POST:
			let postsData;
			if (state.posts !== undefined && action.currentPage > 0) {
				postsData = Object.assign({}, state.posts);
				// postsData.rows.concat(action.posts.rows);
				action.posts.rows = action.posts.rows.map((post, ind) => {
					const findPost = postsData.rows.findIndex((item) => item.id === post.id);
					if (findPost === -1) {
						postsData.rows.push(post);
					} else {
					}
					return post;
				});
				return Object.assign({}, state, {
					posts: postsData
				});
			} else {
				postsData = action.posts;
				return Object.assign({}, state, {
					posts: postsData
				});
			}

		//Get list of user comments to be shown
		case GET_ALL_USER_COMMENTS: {
			let commentsData;
			if (state.userComments !== undefined && action.currentPage > 0) {
				commentsData = Object.assign({}, state.userComments);
				action.comments.rows = action.comments.rows.map((post, ind) => {
					const findPost = commentsData.rows.findIndex((item) => item.id === post.id);
					if (findPost === -1) {
						commentsData.rows.push(post);
					} else {
					}
					return post;
				});
				return Object.assign({}, state, {
					userComments: commentsData
				});
			} else {
				commentsData = action.comments;
				return Object.assign({}, state, {
					userComments: commentsData
				});
			}
		}
		//Get list of mentions comments to be shown
		case GET_ALL_MENTIONS_COMMENTS: {
			let commentsData;
			if (state.mentionsComments !== undefined && action.currentPage > 0) {
				commentsData = Object.assign({}, state.mentionsComments);
				action.comments.rows = action.comments.rows.map((post, ind) => {
					const findPost = commentsData.rows.findIndex((item) => item.id === post.id);
					if (findPost === -1) {
						commentsData.rows.push(post);
					} else {
					}
					return post;
				});
				return Object.assign({}, state, {
					mentionsComments: commentsData
				});
			} else {
				commentsData = action.comments;
				return Object.assign({}, state, {
					mentionsComments: commentsData
				});
			}
		}

		case EMPTY_PROFILE_POST_LIST:
			return Object.assign({}, state, {
				posts: undefined
			});

		case EMPTY_ALL_USER_COMMENTS:
			return Object.assign({}, state, {
				userComments: undefined
			});

		case EMPTY_ALL_MENTIONS_COMMENTS:
			return Object.assign({}, state, {
				mentionsComments: undefined
			});

		default:
			return state;
	}
};

export default SurgeonProfileReducer;
