/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const READ_UNREAD_NOTIFICATIONS = 'READ_UNREAD_NOTIFICATIONS';

/**
 * Action called after get all notifications
 * @param notifications
 */
export const getAllNotifications = (notifications) => {
	return {
		type: GET_ALL_NOTIFICATIONS,
		notifications: notifications
	};
};

/**
 * Action called after activate deactivate notification
 * @param notification
 */

export const readUnreadNotification = (notification) => {
	return {
		type: READ_UNREAD_NOTIFICATIONS,
		notification
	};
};
