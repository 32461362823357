/**
 * @author Omkar Jadhav <omkarj@wemotiveforge.com>
 */

import { DELETE_COMMENT, DELETE_POST, GET_ALL_COMMENTS, GET_ALL_POSTS, RESTORE_COMMENT, RESTORE_POST } from './ContentManagementActions';

const initialState = {
	posts: [],
	comments: []
};

/**
 * User reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ContentManagementReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of posts to be shown
		case GET_ALL_POSTS:
			return Object.assign({}, state, {
				posts: action.posts
			});

		//Restore post
		case RESTORE_POST:
			return Object.assign({}, state, {
				posts: action.postRestore
			});

		//Delete post
		case DELETE_POST:
			return Object.assign({}, state, {
				posts: action.postDelete
			});

		//Get list of comments to be shown
		case GET_ALL_COMMENTS:
			return Object.assign({}, state, {
				comments: action.comments
			});

		//Restore Comment
		case RESTORE_COMMENT:
			return Object.assign({}, state, {
				comments: action.commentRestore
			});

		//Delete Comment
		case DELETE_COMMENT:
			return Object.assign({}, state, {
				comments: action.commentDelete
			});

		default:
			return state;
	}
};

export default ContentManagementReducer;
