/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_VENDOR, CREATE_VENDOR, GET_ALL_VENDORS, UPDATE_VENDOR } from './VendorActions';

const initialState = {
	vendors: [],
	vendorAdmins: []
};

/**
 * Vendor reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const VendorReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of vendors to be shown
		case GET_ALL_VENDORS:
			return Object.assign({}, state, {
				vendors: action.vendors
			});

		//Append newly created vendor
		case CREATE_VENDOR:
			return Object.assign({}, state, {
				vendors: [...state.vendors.rows, action.vendors]
			});

		//Update vendor details
		case UPDATE_VENDOR: {
			let data = Object.assign({}, state.vendors);

			data.rows = data.rows.map((vendor) => {
				if (vendor.id === action.vendor.id) {
					return action.vendor;
				}
				return vendor;
			});

			return Object.assign({}, state, {
				vendors: data
			});
		}

		case ACTIVATE_DEACTIVATE_VENDOR: {
			let data = Object.assign({}, state.vendors);
			data.rows = data.rows.map((vendor) => {
				if (vendor.id === action.vendor.id) {
					return action.vendor;
				}
				return vendor;
			});

			return Object.assign({}, state, {
				vendors: data
			});
		}

		default:
			return state;
	}
};

export default VendorReducer;
