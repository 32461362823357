import { i18nReducer } from 'react-redux-i18n';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import actualSteps from './modules/ActualStepsManagement/ActualStepsManagementReducer';
import approaches from './modules/ApproachManagement/ApproachManagementReducer';
import auth from './modules/Authentication/AuthenticationReducer';
import backgroundUploadComment from './modules/BackgroundUpload/BackgroundUploadCommentReducer';
import backgroundUploadConferencePresentation from './modules/BackgroundUpload/BackgroundUploadConferencePresentationReducer';
import backgroundUploadConference from './modules/BackgroundUpload/BackgroundUploadConferenceReducer';
import backgroundUploadConferenceSession from './modules/BackgroundUpload/BackgroundUploadConferenceSessionReducer';
import backgroundUploadPost from './modules/BackgroundUpload/BackgroundUploadReducer';
import community from './modules/Community/CommunityReducer';
import communityCategory from './modules/CommunityCategory/CommunityCategoryReducer';
import contentManagement from './modules/ContentManagement/ContentManagementReducers';
import diagnosis from './modules/DiagnosisManagement/DiagnosisManagementReducer';
import domains from './modules/DomainManagement/DomainManagementReducer';
import messages from './modules/Messages/MessagesReducer';
import notifications from './modules/Notifications/NotificationReducer';
import postDetails from './modules/PostDetails/PostDetailsReducer';
import procedures from './modules/ProcedureManagement/ProcedureManagementReducer';
import product from './modules/Product/ProductReducer';
import productCategory from './modules/ProductCategory/ProductCategoryReducer';
import specialities from './modules/SpecialityManagement/SpecialityManagementReducer';
import posts from './modules/SurgeonLandingPage/SurgeonLandingPageReducer';
import surgeonProfile from './modules/SurgeonProfile/SurgeonProfileReducer';
import user from './modules/UserManagement/UserReducer';
import vendor from './modules/Vendors/VendorReducer';
import showLoader from './utils/components/AppLoader/AppLoaderReducer';
import postReminderDetails from './utils/components/SingalFeed/SingalFeedReducer';

// Combine all reducers into one root reducer
export default combineReducers({
	i18n: i18nReducer,
	showLoader,
	auth,

	user,
	vendor,
	productCategory,
	product,
	communityCategory,
	community,

	diagnosis,
	specialities,
	domains,
	procedures,
	approaches,
	actualSteps,

	contentManagement,

	posts,
	backgroundUploadPost,
	backgroundUploadComment,
	backgroundUploadConference,
	backgroundUploadConferencePresentation,
	backgroundUploadConferenceSession,

	postDetails,
	surgeonProfile,
	notifications,
	messages: persistReducer({ key: 'messages', storage, keyPrefix: '', version: 0 }, messages),
	postReminderDetails
});
