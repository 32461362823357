/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';

import axios from 'axios';
import { API_ACTIONS } from '../../utils/Constants';
import { updateRoomState } from './MessagesActions';

export const createRoomRequest = (data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, '/chat/createRoom', 'post', data)
			.send()
			.then((res) => {
				return Promise.resolve(res.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const getAllRoomsRequest = (data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.ROOM}`, 'post', data)
			.send()
			.then((res) => {
				// res.data.data = res.data.data.rooms.map((item) => {
				// 	item.user.userRoles = {
				// 		isSystemSuperAdmin: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_SUPER_ADMIN),
				// 		isVendorAdmin: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_VENDOR_ADMIN),
				// 		isIndustryReps: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_INDUSTRY_REPRESENTATIVE),
				// 		isSurgeon: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_SURGEON),
				// 		isConferenceOrganizer: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_CONFERENCE_ORGANIZER),
				// 		isCommunityModerator: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_COMMUNITY_MODERATOR),
				// 		isLivePostAuthor: UtilHelper.checkRoleAvailability(item.user.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_LIVE_POST_AUTHOR)
				// 	};

				// 	return item;
				// });

				dispatch(updateRoomState(res.data.data.rooms));
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const fetchConferenceDetailsForChatFromCacheRequest = (conferenceId) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.FETCH_CONFERENCE_DETAIL_FOR_CHAT}/${conferenceId}`, 'get')
			.send(null, true)
			.then((res) => {
				return Promise.resolve(res.data?.conferenceDetails);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const fetchSessionDetailsForChatFromCacheRequest = (sessionId) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.FETCH_SESSION_DETAILS_FOR_CHAT}/${sessionId}`, 'get')
			.send(null, true)
			.then((res) => {
				return Promise.resolve(res.data?.conferenceSessionDetails);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export async function logActivityEventApi(event) {
	try {
		const serviceToken = localStorage.getItem('access_token');
		const response = await axios.post(
			`${process.env.REACT_APP_SERVER_API_URL}/sytemUtils/activityLog`,
			{ ...event },
			{
				headers: {
					'access-token': `Bearer ${serviceToken}`
				}
			}
		);
	} catch (error) {
		console.log('logActivityEvent error', error);
	}
}

export const getAllMessagesByRoomIdRequest = (roomId, paginationParams, lastMessageId) => {
	let limit = paginationParams.rowsPerPage;
	let url = '';
	let chatRoomId = roomId;
	let oldestMessageTimetoken = paginationParams.oldestMessageTimetoken;
	let offset = paginationParams.offset;

	// url = `${API_ACTIONS.ROOM_MESSAGES}?limit=${limit}&lastMessageId=${lastMessageId}&chatRoomId=${chatRoomId}&oldestMessageTimetoken=${oldestMessageTimetoken}&offset=${offset}`;
	url = `${API_ACTIONS.ROOM_MESSAGES}`;

	return (dispatch) => {
		return new HttpRequest(dispatch, url, 'post', paginationParams)
			.send(null, true)
			.then((res) => {
				// res.data.data.rows = res.data.data.rows.map((item) => {
				// 	item.userRoles = {
				// 		isSystemSuperAdmin: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_SUPER_ADMIN),
				// 		isVendorAdmin: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_VENDOR_ADMIN),
				// 		isIndustryReps: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_INDUSTRY_REPRESENTATIVE),
				// 		isSurgeon: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_SURGEON),
				// 		isConferenceOrganizer: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_CONFERENCE_ORGANIZER),
				// 		isCommunityModerator: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_COMMUNITY_MODERATOR),
				// 		isLivePostAuthor: UtilHelper.checkRoleAvailability(item.roles, Constants.HUMAN_READABLE_SYSTEM_ROLES.ROLE_LIVE_POST_AUTHOR)
				// 	};
				// 	return item;
				// });
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const sendToServerInboundChannelRequest = (data) => {
	let url = `${API_ACTIONS.SEND_TO_SERVER_INBOUND_CHANNEL}`;

	return (dispatch) => {
		return new HttpRequest(dispatch, url, 'post', data)
			.send(null, true)
			.then((res) => {
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const getLastMessageSyncedAtRequest = (channel) => {
	let url = `${API_ACTIONS.GET_LAST_MESSAGE_SYNCED_AT}/${channel}`;

	return (dispatch) => {
		return new HttpRequest(dispatch, url, 'get')
			.send(null, true)
			.then((res) => {
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const notifyInboundChannelRequest = (data) => {
	let url = `${API_ACTIONS.NOTIFY_INBOUND_CHANNEL}`;

	return (dispatch) => {
		return new HttpRequest(dispatch, url, 'post', data)
			.send(null, true)
			.then((res) => {
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};
