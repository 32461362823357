/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_PRODUCT, ADD_PRODUCT, GET_ALL_PRODUCTS, UPDATE_PRODUCT } from './ProductActions';

const initialState = {
	products: []
};

/**
 * Product reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ProductReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of products to be shown
		case GET_ALL_PRODUCTS:
			return Object.assign({}, state, {
				products: action.products
			});

		//Append newly add product
		case ADD_PRODUCT:
			return Object.assign({}, state, {
				products: [...state.products.rows, action.product]
			});

		//Update product details
		case UPDATE_PRODUCT: {
			let data = Object.assign({}, state.products);

			data.rows = data.rows.map((product) => {
				if (product.id === action.product.id) {
					return action.product;
				}
				return product;
			});

			return Object.assign({}, state, {
				products: data
			});
		}
		case ACTIVATE_DEACTIVATE_PRODUCT: {
			let data = Object.assign({}, state.products);
			data.rows = data.rows.map((product) => {
				if (product.id === action.product.id) {
					product.enabled = !product.enabled;
					return product;
				}
				return product;
			});

			return Object.assign({}, state, {
				products: data
			});
		}

		default:
			return state;
	}
};

export default ProductReducer;
