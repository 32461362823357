const MuiTabs = {
	root: {
		fontFamily: 'Lato',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		borderBottomColor: 'rgba(98, 125, 152, .25)'
	},

	indicator: {
		borderBottomWidth: '3px',
		borderBottomStyle: 'solid',
		borderBottomColor: '#4d9ce6'
	}
};

export default MuiTabs;
