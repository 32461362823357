/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_DOMAINS = 'GET_ALL_DOMAINS';
export const CREATE_DOMAIN = 'CREATE_DOMAIN';
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const ACTIVATE_DEACTIVATE_DOMAIN = 'ACTIVATE_DEACTIVATE_DOMAIN';

/**
 * Action called after get all domains
 * @param domains
 */
export const getAllDomains = (domains) => {
	return {
		type: GET_ALL_DOMAINS,
		domains: domains
	};
};

/**
 * Action called after create domain
 * @param domain
 */

export const createDomain = (domain) => {
	return {
		type: CREATE_DOMAIN,
		domain
	};
};

/**
 * Action called after update domain details
 * @param domain
 */

export const updateDomain = (domain) => {
	return {
		type: UPDATE_DOMAIN,
		domain
	};
};

/**
 * Action called after activate deactivate domain
 * @param domain
 */

export const activateDeactivateDomain = (domain) => {
	return {
		type: ACTIVATE_DEACTIVATE_DOMAIN,
		domain
	};
};
