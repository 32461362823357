/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_CATEGORY, CREATE_CATEGORY, GET_ALL_CATEGORIES, UPDATE_CATEGORY } from './ProductCategoryActions';

const initialState = {
	productCategory: []
};

/**
 * ProductCategory reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ProductCategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of productCategory to be shown
		case GET_ALL_CATEGORIES:
			return Object.assign({}, state, {
				productCategory: action.categories
			});

		//Append newly created category
		case CREATE_CATEGORY:
			return Object.assign({}, state, {
				productCategory: [...state.productCategory.rows, action.category]
			});

		//Update category details
		case UPDATE_CATEGORY: {
			let data = Object.assign({}, state.productCategory);

			data.rows = data.rows.map((category) => {
				if (category.id === action.category.id) {
					return action.category;
				}
				return category;
			});

			return Object.assign({}, state, {
				productCategory: data
			});
		}
		case ACTIVATE_DEACTIVATE_CATEGORY: {
			let data = Object.assign({}, state.productCategory);
			data.rows = data.rows.map((category) => {
				if (category.id === action.category.id) {
					return action.category;
				}
				return category;
			});

			return Object.assign({}, state, {
				productCategory: data
			});
		}

		default:
			return state;
	}
};

export default ProductCategoryReducer;
