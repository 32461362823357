import { Auth0Provider } from '@auth0/auth0-react';
import { Snackbar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import React, { useEffect, useState } from 'react';
import FullStory from 'react-fullstory';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import { getAllPostRequest } from './modules/SurgeonLandingPage/SurgeonLandingPageApiActions';
import Routes from './routes';
import store from './store';
import { ROW_PER_PAGE_5 } from './utils/Constants';
import theme from './utils/components/theme';

// Initialize the Firebase app in the service worker by passing the generated config
var firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

// Retrieve firebase messaging
const messaging = getMessaging(firebaseApp);

const AUTH0_RANDOM_STRING = new Date().getTime().toString() + '_SURGEON';

function App() {
	const [notificationAllowed, setNotificationAllowed] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const requestPermission = async () => {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			setNotificationAllowed(true);
			getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE })
				.then((currentToken) => {
					if (currentToken) {
						localStorage.setItem('firebaseToken', currentToken);
					} else {
						// Show permission request UI
						console.log('No registration token available. Request permission to generate one.');
						// ...
					}
					/** When app is active */
					onMessage(
						messaging,
						(remoteMessage) => {
							console.log(`foreground Message received`, remoteMessage);
							if (remoteMessage.data.page === 'postDetails') {
								const payload = JSON.parse(remoteMessage.data.payload);
								if (payload.type === 'POLL_CAST_VOTE' || payload.type === 'POLL_ENDED') {
									store.dispatch({ type: 'POLL_CAST_VOTE', payload: payload });
									const paginationData = {
										page: 0,
										rowsPerPage: ROW_PER_PAGE_5,
										searchData: {
											CommunityId: ''
										},
										sort: 'MOST_RECENT_COMMENT'
									};
									store.dispatch(getAllPostRequest(paginationData)).then(
										(res) => {
											console.log('STORE DISPATCH RESPONSE', res);
											return Promise.resolve(res);
										},
										(error) => {
											return Promise.reject(error);
										}
									);
								}
							}
						},
						(error) => {
							console.log(error);
						}
					);
				})
				.catch((err) => {
					console.log('An error occurred while retrieving token. ', err);
					// ...
				});
		} else if (permission === 'denied') {
			setNotificationAllowed(false);
			setOpenSnackbar(true);
		}
	};

	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('/firebase-messaging-sw.js')
			.then(function (registration) {})
			.catch(function (err) {});
	}

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/firebase-messaging-sw.js')
				.then(function (registration) {
					requestPermission();
				})
				.catch(function (err) {
					console.log('Service worker registration failed, error:', err);
				});
		}
	}, []);

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={!notificationAllowed && openSnackbar}
				onClose={() => setOpenSnackbar(false)}
				autoHideDuration={5000}
				message="Grant notification permissions to receive recent updates"
			/>

			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
				redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
				audience={process.env.REACT_APP_AUTH0_AUDIENCE}
				scope={process.env.REACT_APP_AUTH0_SCOPE}
				state={AUTH0_RANDOM_STRING}>
				<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
					<ThemeProvider theme={theme}>
						<Routes />
						<FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID} />
					</ThemeProvider>
				</GoogleOAuthProvider>
			</Auth0Provider>
		</>
	);
}

export default App;
