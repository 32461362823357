/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */
import React, { Suspense } from 'react';

const SuspenseContainer = React.memo(({ children }) => {
	return <Suspense fallback={<div />}>{children}</Suspense>;
});

export default SuspenseContainer;
