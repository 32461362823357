/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_PROCEDURES = 'GET_ALL_PROCEDURES';
export const CREATE_PROCEDURE = 'CREATE_PROCEDURE';
export const UPDATE_PROCEDURE = 'UPDATE_PROCEDURE';
export const ACTIVATE_DEACTIVATE_PROCEDURE = 'ACTIVATE_DEACTIVATE_PROCEDURE';

/**
 * Action called after get all procedures
 * @param procedures
 */
export const getAllProcedures = (procedures) => {
	return {
		type: GET_ALL_PROCEDURES,
		procedures: procedures
	};
};

/**
 * Action called after create procedure
 * @param procedure
 */

export const createProcedure = (procedure) => {
	return {
		type: CREATE_PROCEDURE,
		procedure
	};
};

/**
 * Action called after update procedure details
 * @param procedure
 */

export const updateProcedure = (procedure) => {
	return {
		type: UPDATE_PROCEDURE,
		procedure
	};
};

/**
 * Action called after activate deactivate procedure
 * @param procedure
 */

export const activateDeactivateProcedure = (procedure) => {
	return {
		type: ACTIVATE_DEACTIVATE_PROCEDURE,
		procedure
	};
};
