/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';
export const CREATE_VENDOR = 'CREATE_VENDOR';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const ACTIVATE_DEACTIVATE_VENDOR = 'ACTIVATE_DEACTIVATE_VENDOR';

/**
 * Action called after get all vendors
 * @param vendors
 */
export const getAllVendors = (vendors) => {
	return {
		type: GET_ALL_VENDORS,
		vendors: vendors
	};
};

/**
 * Action called after create/invite vendor
 * @param vendor
 */

export const createVendor = (vendor) => {
	return {
		type: CREATE_VENDOR,
		vendor
	};
};

/**
 * Action called after update vendor details
 * @param vendor
 */

export const updateVendor = (vendor) => {
	return {
		type: UPDATE_VENDOR,
		vendor
	};
};

/**
 * Action called after activate deactivate vendor
 * @param vendor
 */

export const activateDeactivateVendor = (vendor) => {
	return {
		type: ACTIVATE_DEACTIVATE_VENDOR,
		vendor
	};
};
