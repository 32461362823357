/**
 * @author Omkar Jadhav <omkarj@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const RESTORE_POST = 'RESTORE_POST';
export const DELETE_POST = 'DELETE_POST';
export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS';
export const RESTORE_COMMENT = 'RESTORE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
/**
 * Action called after get all Posts
 * @param posts
 */
export const getAllPosts = (posts) => {
	return {
		type: GET_ALL_POSTS,
		posts: posts
	};
};

/**
 * Action called after retore post
 * @param null
 */
export const restorePost = (postRestore) => {
	return {
		type: RESTORE_POST,
		postRestore: postRestore
	};
};

/**
 * Action called after delete post
 * @param null
 */
export const deletePost = (postDelete) => {
	return {
		type: DELETE_POST,
		postDelete: postDelete
	};
};

/**
 * Action called after get all Comments
 * @param comments
 */
export const getAllComments = (comments) => {
	return {
		type: GET_ALL_COMMENTS,
		comments: comments
	};
};

/**
 * Action called after retore comments
 * @param null
 */
export const restoreComment = (commentRestore) => {
	return {
		type: RESTORE_COMMENT,
		commentRestore: commentRestore
	};
};

/**
 * Action called after delete comment
 * @param null
 */
export const deleteComment = (commentDelete) => {
	return {
		type: DELETE_COMMENT,
		commentDelete: commentDelete
	};
};
