import axios from 'axios';
import { sessionExpired } from '../modules/Authentication/AuthenticationActions';
import Auth from './Auth';
import { DEVICE_TYPE, PATHS_NOT_REQUIRING_AUTH, SNACKBAR_VARIAINT } from './Constants';
import { hideLoader, showLoader, showSnackbar } from './components/AppLoader/AppLoaderActions';

let cancel;

/**
 * Used to communicate with server
 */
class HttpRequest {
	/**
	 *
	 * @param {*} url Resource URL
	 * @param {*} method HTTP method(GET | POST | PUT | PATCH | DELETE)
	 * @param {*} headers HTTP request headers
	 * @param {*} data HTTP request data (If applicable)
	 */
	constructor(dispatch, url, method = 'get', data = {}, headers = {}, options) {
		headers = {
			...headers,
			appVersion: process.env.REACT_APP_VERSION,
			deviceType: DEVICE_TYPE.WEB_TYPE_ID
		};

		this.url = url;
		this.method = method;
		this.data = data;
		this.headers = headers;
		this.options = options;
		this.isAuthNotRequired = false;
		this.dispatch = dispatch;
		const idMatch = window.location.href.match(/\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/);
		const id = idMatch ? idMatch[1] : null;

		// Check static paths
		if (PATHS_NOT_REQUIRING_AUTH.includes(this.url)) {
			this.isAuthNotRequired = true;
		} else {
			// Check dynamic paths
			PATHS_NOT_REQUIRING_AUTH.forEach((path) => {
				if (typeof path === 'function') {
					// Pass the extracted ID to the function
					const dynamicPath = path(id);
					if (this.url === dynamicPath) {
						this.isAuthNotRequired = true;
					}
				}
			});
		}
	}

	showHideLoader = (dispatch) => {
		let numberOfAjaxCAllPending = 0;
		// Add a request interceptor
		axios.interceptors.request.use(
			(config) => {
				if (!config.isHideLoader) {
					numberOfAjaxCAllPending++;
					// show loader
					dispatch(showLoader());
				}
				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);
		// Add a response interceptor
		axios.interceptors.response.use(
			(response) => {
				if (!response.config.isHideLoader) {
					numberOfAjaxCAllPending--;
					if (numberOfAjaxCAllPending === 0) {
						//hide loader
						dispatch(hideLoader());
					}
				} else {
				}
				return response;
			},
			function (error) {
				let originalRequest = Object.assign({}, error.config);

				// [Bulk import] Step 8.15: If the request is a S3 upload request and the trial number is less than max trial number, retry the request
				if (originalRequest.isS3UploadRequest && originalRequest.trialNumber < originalRequest.maxTrial) {
					originalRequest.trialNumber = originalRequest.trialNumber + 1;
					originalRequest._retry = true;
					return axios(originalRequest);
				} else {
					dispatch(hideLoader());
					return Promise.reject(error);
				}
			}
		);
	};

	/**
	 * Send http request to server to write data to / read data from server
	 * axios library provides promise implementation to send request to server
	 * Here we are using axios library for requesting a resource
	 */
	send = (accessToken = null, isHideLoader = false, hideSnackbar = false) => {
		return new Promise((resolve, reject) => {
			this.showHideLoader(this.dispatch);
			// Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
			let headers = { ...this.headers, 'Content-Type': 'application/json' };
			const token = accessToken === null ? Auth.getToken() : accessToken;

			// Check if the URL is one of the URLs that do not require authentication
			headers['access-token'] = `Bearer ${token}`;

			// Check if the user is already on the sign-in page to prevent infinite loop
			if ((window.location.pathname === '/signin' || window.location.pathname === '/') && !this.isAuthNotRequired && !token) {
				// If the user is already on the sign-in page and authentication is required,
				// do not redirect again to avoid infinite loop.
				console.error('Authentication required but no token available.');

				resolve('Authentication required but no token available');
			}

			if (!this.isAuthNotRequired && !token) {
				console.warn('making request to ', this.url, 'without JWT, Your session has expired. Please log in again.');
				this.dispatch(sessionExpired('Your session has expired. Please log in again.'));
				// If authentication is required and no token exists, redirect the user to the /login page
				window.location.href = '/signin';
				reject('Your session has expired. Please log in again');
			}

			// If authentication is not required for this URL, or if a token exists, proceed with the API call
			// Make server request using axios
			axios({
				baseURL: process.env.REACT_APP_SERVER_API_URL,
				withCredentials: true,
				url: this.url,
				method: this.method,
				headers: headers,
				data: JSON.stringify(this.data),
				isHideLoader: isHideLoader,
				hideSnackbar: hideSnackbar
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					// Users signature(JWT token) has been expired. Redirect user to Login page
					if (error.response === undefined) {
						this.dispatch(showSnackbar('Something went wrong. Please try again.', SNACKBAR_VARIAINT.ERROR));
					} else if (error.response.status === 401) {
						if (this.dispatch) {
							this.dispatch(sessionExpired(error.response.data.error));
						}
						// Fail safety implementation
						Auth.logoutAndNavigateToLogin();
					} else if (error.response.status === 428) {
					} else {
						if (!error.response.config.hideSnackbar && error.response.status !== 400) {
							let message = 'Something went wrong. Please try again.';
							this.dispatch(showSnackbar(error.response && error.response.data.error ? error.response.data.error : message, SNACKBAR_VARIAINT.ERROR));
						}
					}
					reject(error);
				});
		});
	};

	upload = (accessToken = null, isHideLoader = false) => {
		// Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
		let headers = { ...this.headers, 'Content-Type': 'multipart/form-data' };

		return new Promise((resolve, reject) => {
			this.showHideLoader(this.dispatch);
			// Make server request using axios
			axios({
				baseURL: process.env.REACT_APP_SERVER_API_URL,
				withCredentials: true,
				url: this.url,
				method: this.method,
				headers: headers,
				data: this.data,
				isHideLoader: isHideLoader
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					// Users signature(JWT token) has been expired. Redirect user to Login page
					console.log(error.toJSON());
					if (error.response === undefined) {
						this.dispatch(showSnackbar('Something went wrong. Please try again.', SNACKBAR_VARIAINT.ERROR));
					} else if (error.response.status === 401) {
						if (this.dispatch) {
							this.dispatch(sessionExpired(error.response.data.error));
						}
						// Fail safety implementation
						Auth.logoutAndNavigateToLogin();
					} else {
						let message = 'Something went wrong. Please try again.';
						this.dispatch(showSnackbar(error.response && error.response.data.error ? error.response.data.error : message, SNACKBAR_VARIAINT.ERROR));
					}
					reject(error);
				});
		});
	};

	/**
	 * [Bulk import] Step 8.13: Upload a file chunk to S3 asynchronoulsy.
	 *
	 * The HTTPRequest object is created in the 'uploadToS3Request' method in BackgroundUploadActions.js file. This object was initialized with the following parameters:
	 * @param url: S3 presigned URL
	 * @param file: file chunk to be uploaded
	 * @param uploadProgress: callback function to update the progress of the file upload
	 * @param fileType: type of the file to be uploaded
	 *
	 * The parameters for the s3Upload are:
	 * @param {*} accessToken			JWT token
	 * @param {*} isHideLoader			flag to show/hide loader
	 * @param {*} uploadProgress		callback function to update progress bar
	 * @param {*} contentType			content type of file
	 * @param {*} isCommentUpload	flag to check if the chunk belongs to a file being uploaded in a comment. This seems to be an optional parameter in the conference module workflow
	 * @returns
	 */
	s3Upload = (accessToken = null, isHideLoader = false, uploadProgress = undefined, contentType = undefined, isCommentUpload) => {
		// [Bulk import] Step: Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
		let headers = { ...this.headers, 'Content-Type': contentType ? contentType : 'multipart/form-data' };
		const CancelToken = axios.CancelToken;

		return new Promise((resolve, reject) => {
			this.showHideLoader(this.dispatch);
			// Make server request using axios
			axios({
				withCredentials: true, // This is required to send the cookie to the S3 upload endpoint
				url: this.url, // This is the S3 presigned URL for the file chunk being uploaded
				method: this.method, // POST request
				headers: headers, // Default headers + Content-Type: multipart/form-data
				data: this.data, // file chunk to be uploaded
				isHideLoader: isHideLoader, // flag to show/hide loader
				isS3UploadRequest: true, // flag to indicate that this is a S3 upload request
				trialNumber: 1, // trial number to keep track of the number of times the file chunk has been uploaded
				maxTrial: isCommentUpload ? parseInt(process.env.REACT_APP_MAX_NUMBER_OF_TRIAL_S3_UPLOAD_REQUEST_FOR_COMMENT) : parseInt(process.env.REACT_APP_MAX_NUMBER_OF_TRIAL_S3_UPLOAD_REQUEST), // maximum number of times the file chunk can be uploaded
				onUploadProgress: function (progressEvent) {
					// callback function to update the progress of the file upload
					if (uploadProgress) {
						// if the callback function is defined
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // calculate the percentage of the file chunk uploaded
						progressEvent.percentCompleted = percentCompleted;

						// [Bulk import] Step 8.14: Update the progress bar
						uploadProgress(progressEvent);
					}
				},
				cancelToken: new CancelToken(function (c) {
					cancel = c;
				})
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error.toJSON());
					if (error.response === undefined) {
						// this.dispatch(showSnackbar("Something went wrong. Please try again.", SNACKBAR_VARIAINT.ERROR));
					} else if (error.response.status === 401) {
						if (this.dispatch) {
							this.dispatch(sessionExpired(error.response.data.error));
						}
						// Fail safety implementation
						Auth.logoutAndNavigateToLogin();
					} else if (error.response.status === 428) {
					} else {
						let message = 'Something went wrong. Please try again.';
						this.dispatch(showSnackbar(error.response && error.response.data.error ? error.response.data.error : message, SNACKBAR_VARIAINT.ERROR));
					}
					// [Bulk import] Step 8.16: Reject the promise if the file chunk upload fails. Set the status to 499 if the request is cancelled
					if (axios.isCancel(error)) {
						error = {
							status: 499
						};
					} else {
					}
					reject(error);
				});
		});
	};
}

export default HttpRequest;

export function cancelAxiosMethod() {
	return function (dispatch) {
		if (cancel !== undefined) {
			cancel();
		}
		return true;
	};
}
