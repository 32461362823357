import { BACKGROUND_UPLOAD_CONFERENCE_SESSION, CREATE_CONFERENCE_SESSION_PART, SET_CONFERENCE_SESSION_PROGRESS, UNSET_CONFERENCE_SESSION } from './BackgroundUploadActions';

const initialState = {
	backgroundUploadConferenceSession: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const BackgroundUploadConferenceSessionReducer = (state = initialState, action) => {
	switch (action.type) {
		//Append newly created conferenceSession
		case BACKGROUND_UPLOAD_CONFERENCE_SESSION:
			console.log('BACKGROUND_UPLOAD_CONFERENCE_SESSION reduces: ', action);
			return Object.assign({}, state, {
				backgroundUploadConferenceSession: {
					conferenceSession: action.conferenceSession,
					mediaAssetsToBeAdded: action.conferenceSession.mediaAssetsToBeAdded,
					isUploadStart: false,
					totalMediaSize: action.totalMediaSize,
					totalUploadedMediaSize: 0,
					progress: 0
				}
			});

		//Create new part in particular file
		case CREATE_CONFERENCE_SESSION_PART: {
			let tempBackgroundUploadConferenceSession = Object.assign({}, state.backgroundUploadConferenceSession);
			if (tempBackgroundUploadConferenceSession && tempBackgroundUploadConferenceSession.mediaAssetsToBeAdded && tempBackgroundUploadConferenceSession.mediaAssetsToBeAdded.length > 0) {
				tempBackgroundUploadConferenceSession.mediaAssetsToBeAdded = tempBackgroundUploadConferenceSession.mediaAssetsToBeAdded.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						const part = {
							partNumber: action.partNumber,
							presignedUrl: action.presignedUrl,
							totalPartSize: action.totalPartSize,
							uploadedPartSize: 0
						};
						if (singleMedia.parts.length > 0) {
							const findPart = singleMedia.parts.find((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPart === undefined) {
								singleMedia.parts.push(part);
							}
						} else {
							singleMedia.parts.push(part);
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});
				tempBackgroundUploadConferenceSession.isUploadStart = true;
			}

			return Object.assign({}, state, {
				backgroundUploadConferenceSession: tempBackgroundUploadConferenceSession
			});
		}

		//Set conferenceSession progress of part in particular file
		case SET_CONFERENCE_SESSION_PROGRESS: {
			let tempBackgroundUploadConferenceSessionForSetProcess = Object.assign({}, state.backgroundUploadConferenceSession);
			if (
				tempBackgroundUploadConferenceSessionForSetProcess &&
				tempBackgroundUploadConferenceSessionForSetProcess.mediaAssetsToBeAdded &&
				tempBackgroundUploadConferenceSessionForSetProcess.mediaAssetsToBeAdded.length > 0
			) {
				tempBackgroundUploadConferenceSessionForSetProcess.mediaAssetsToBeAdded = tempBackgroundUploadConferenceSessionForSetProcess.mediaAssetsToBeAdded.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						if (singleMedia.parts.length > 0) {
							const findPartIndex = singleMedia.parts.findIndex((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPartIndex > -1) {
								singleMedia.parts[findPartIndex].uploadedPartSize = action.uploadedSize;
							}
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});

				// sum of uploaded size of each file part
				const uploadedSizeForEachFile = tempBackgroundUploadConferenceSessionForSetProcess.mediaAssetsToBeAdded.map((item) => {
					return item.parts.reduce((sum, part) => sum + part.uploadedPartSize, 0);
				});

				// sum of uploaded size including all file
				const totalUploadedSize = uploadedSizeForEachFile.reduce((sum, eachFileSize) => sum + eachFileSize, 0);
				tempBackgroundUploadConferenceSessionForSetProcess.totalUploadedMediaSize = totalUploadedSize;
				const percentCompleted = Math.round((totalUploadedSize * 100) / tempBackgroundUploadConferenceSessionForSetProcess.totalMediaSize);
				tempBackgroundUploadConferenceSessionForSetProcess.progress = percentCompleted;
			}

			return Object.assign({}, state, {
				backgroundUploadConferenceSession: tempBackgroundUploadConferenceSessionForSetProcess
			});
		}

		//Empty conferenceSession list
		case UNSET_CONFERENCE_SESSION:
			return Object.assign({}, state, {
				backgroundUploadConferenceSession: undefined
			});

		default:
			return state;
	}
};

export default BackgroundUploadConferenceSessionReducer;
