import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const MuiChip = {
	root: {
		backgroundColor: '#1d2b37',
		fontFamily: 'Lato',
		color: '#8b98a4',
		'&$clickable:hover': {
			backgroundColor: '#1d2b37'
		}
	}
};

export default MuiChip;
