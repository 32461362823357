import { BACKGROUND_UPLOAD_POST, CREATE_PART, SET_PROGRESS, UNSET_POST } from './BackgroundUploadActions';

const initialState = {
	backgroundUploadPost: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const SurgeonLandingPageReducer = (state = initialState, action) => {
	switch (action.type) {
		//Append newly created post
		case BACKGROUND_UPLOAD_POST:
			return Object.assign({}, state, {
				backgroundUploadPost: {
					post: action.post,
					media: action.post.media,
					isUploadStart: false,
					totalMediaSize: action.totalMediaSize,
					totalUploadedMediaSize: 0,
					progress: 0
				}
			});

		//Create new part in particular file
		case CREATE_PART: {
			let tempBackgroundUploadPost = Object.assign({}, state.backgroundUploadPost);
			if (tempBackgroundUploadPost && tempBackgroundUploadPost.media && tempBackgroundUploadPost.media.length > 0) {
				tempBackgroundUploadPost.media = tempBackgroundUploadPost.media.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						const part = {
							partNumber: action.partNumber,
							presignedUrl: action.presignedUrl,
							totalPartSize: action.totalPartSize,
							uploadedPartSize: 0
						};
						if (singleMedia.parts.length > 0) {
							const findPart = singleMedia.parts.find((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPart === undefined) {
								singleMedia.parts.push(part);
							}
						} else {
							singleMedia.parts.push(part);
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});
				tempBackgroundUploadPost.isUploadStart = true;
			} else {
			}

			return Object.assign({}, state, {
				backgroundUploadPost: tempBackgroundUploadPost
			});
		}

		//Set progress of part in particular file
		case SET_PROGRESS: {
			let tempBackgroundUploadPostForSetProcess = Object.assign({}, state.backgroundUploadPost);
			if (tempBackgroundUploadPostForSetProcess && tempBackgroundUploadPostForSetProcess.media && tempBackgroundUploadPostForSetProcess.media.length > 0) {
				tempBackgroundUploadPostForSetProcess.media = tempBackgroundUploadPostForSetProcess.media.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						if (singleMedia.parts.length > 0) {
							const findPartIndex = singleMedia.parts.findIndex((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPartIndex > -1) {
								singleMedia.parts[findPartIndex].uploadedPartSize = action.uploadedSize;
							}
						} else {
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});

				// sum of uploaded size of each file part
				const uploadedSizeForEachFile = tempBackgroundUploadPostForSetProcess.media.map((item) => {
					return item.parts.reduce((sum, part) => sum + part.uploadedPartSize, 0);
				});

				// sum of uploaded size including all file
				const totalUploadedSize = uploadedSizeForEachFile.reduce((sum, eachFileSize) => sum + eachFileSize, 0);
				tempBackgroundUploadPostForSetProcess.totalUploadedMediaSize = totalUploadedSize;
				const percentCompleted = Math.round((totalUploadedSize * 100) / tempBackgroundUploadPostForSetProcess.totalMediaSize);
				tempBackgroundUploadPostForSetProcess.progress = percentCompleted;
			} else {
			}

			return Object.assign({}, state, {
				backgroundUploadPost: tempBackgroundUploadPostForSetProcess
			});
		}

		//Empty post list
		case UNSET_POST:
			return Object.assign({}, state, {
				backgroundUploadPost: undefined
			});

		default:
			return state;
	}
};

export default SurgeonLandingPageReducer;
