import { colors } from '@material-ui/core';
import palette from '../palette';

const MuiIconButton = {
	root: {
		color: palette.icon,
		fontFamily: 'Lato',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.03)'
		},
		'&$disabled': {
			color: colors.blueGrey[600]
		}
	}
};

export default MuiIconButton;
