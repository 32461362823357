const AppleSiteAssociation = () => {
	return `
	{
		"applinks": {
			"apps": [],
			"details": [{
				"appIDs": ["Y8MYC47973.com.surg-eon.app"],
				"paths": ["/postDetails", "/inviteIR", "/resetPassword"]
			}]
		}
	}`;
};

export default AppleSiteAssociation;
