import { createMuiTheme } from '@material-ui/core/styles';
import palette from '../palette';

const theme = createMuiTheme();

const MuiInput = {
	root: {
		fontFamily: 'Lato',
		'&$focused': {
			color: palette.text.primary
		},
		'&$marginDense': {
			padding: '12px 0 11.5px 0'
		},
		'&$underline::before': {
			borderBottom: '1px solid #334e68 !important'
		},
		'&$underline::after': {
			borderBottom: '1px solid #334e68 !important'
		},
		'&.Mui-disabled': {
			color: '#8b98a4'
		}
	}
};

export default MuiInput;
