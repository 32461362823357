/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_APPROACH, CREATE_APPROACH, GET_ALL_APPROACHES, UPDATE_APPROACH } from './ApproachManagementActions';

const initialState = {
	approaches: []
};

/**
 * Approach reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ApproachReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of approaches to be shown
		case GET_ALL_APPROACHES:
			return Object.assign({}, state, {
				approaches: action.approaches
			});

		//Append newly created approach
		case CREATE_APPROACH:
			return Object.assign({}, state, {
				approaches: [...state.approaches.rows, action.approach]
			});

		//Update approach details
		case UPDATE_APPROACH: {
			let data = Object.assign({}, state.approaches);

			data.rows = data.rows.map((approach) => {
				if (approach.id === action.approach.id) {
					return action.approach;
				}
				return approach;
			});

			return Object.assign({}, state, {
				approaches: data
			});
		}

		//Enabled/Disabled approach
		case ACTIVATE_DEACTIVATE_APPROACH: {
			let data = Object.assign({}, state.approaches);
			data.rows = data.rows.map((approach) => {
				if (approach.id === action.approach.id) {
					approach.enabled = !approach.enabled;
					return approach;
					// return action.approach;
				}
				return approach;
			});

			return Object.assign({}, state, {
				approaches: data
			});
		}

		default:
			return state;
	}
};

export default ApproachReducer;
