import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { FIREBASE_CONFIG, SYSTEM_ROLES } from './Constants';

import { withStyles } from '@material-ui/core/styles';

import _clone from 'lodash/clone';
import _escapeRegExp from 'lodash/escapeRegExp';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import { logActivityEventApi } from '../modules/Messages/MessagesApiActions';
import { HUMAN_READABLE_SYSTEM_ROLES } from './Constants';
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = FIREBASE_CONFIG;

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 500,
		width: 500,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
	}
}))(Tooltip);

class UtilHelper {
	static logFirebaseAnalyticsEvents = async (screenName, data) => {
		// FIXME: Initialize app once.
		// const app = await initializeApp(firebaseConfig);
		// const analytics = await getAnalytics(app);
		// data.source = "Web";
		// logEvent(analytics, screenName, data);
	};

	static logActivityEvent = async (eventName, entity, entityId, note, metaData, screenNameForAnalytics) => {
		try {
			const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

			await logActivityEventApi({
				action: eventName,
				entity: entity,
				entityId: entityId,
				userId: loggedInUser?.id,
				metaData: metaData
			});
		} catch (error) {
			console.log('error logActivityEvent', error);
			throw error;
		}
	};

	static numberToCurrency = (number) => {
		return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	};

	static validateMobileNumber = (rule, value, callback) => {
		const regex = /\d+/g;

		if (value && value.length > 0 && (!regex.test(value) || value.length !== 10)) {
			callback('Please enter valid mobile number');
		}
		callback();
	};

	static validatePassword = (value) => {
		const pattern = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z0-9#?!@$%^&*-]{8,}$');
		return pattern.test(value);
	};

	static validateUserName = (rule, value, callback) => {
		const regex = /^[a-zA-Z '.-]*$/;

		if (value && !regex.test(value)) {
			callback('Please enter valid name');
		} else if (value && value.trim().length === 0) {
			callback('Only spaces are not allowed');
		} else {
			callback();
		}
	};

	static validateOnlyNumber = (rule, value, callback) => {
		const regex = /^[0-9]*$/;

		if (value && !regex.test(value)) {
			callback('Please enter only number');
		} else if (value && value.trim().length === 0) {
			callback('Only spaces are not allowed');
		} else {
			callback();
		}
	};

	static validateString = (value) => {
		const regex = /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$/;

		if (value && !regex.test(value)) {
			return false;
		} else {
			return true;
		}
	};

	static validateBlankSpaces = (value) => {
		if (!Array.isArray(value)) {
			return !(value && value?.trim().length === 0);
		} else {
			return true;
		}
	};

	static validateNegativeValue = (rule, value, callback) => {
		if (value && value < 0) {
			callback('Negative number not allowed');
		} else {
			callback();
		}
	};

	static blockSpecialCharacter = (value) => {
		const regex = /^[0-9a-zA-Z '.-]+$/;
		if (value && !regex.test(value)) {
			return false;
		} else {
			return true;
		}
	};

	static validAgeInput = (value) => {
		const regex = /^\d+(\.\d)?\d*$/;
		if (value && !regex.test(value)) {
			return false;
		} else {
			return true;
		}
	};

	static validateFloatValue = (rule, value, callback) => {
		if (value && value > 0) {
			value.indexOf('.') >= 0 ? callback('Decimal number not allowed') : callback();
		} else {
			callback();
		}
	};

	static isFloat = (n) => {
		return n === +n && n !== (n | 0);
	};

	static stringBeforeDecimal = (n) => {
		return n.split('.')[0];
	};

	static asyncForEach = async (array, callback) => {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	};

	static filterColumnReactTable = (filter, row) => {
		if (!row[filter.id]) return false;
		return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
	};

	static getCurrentRole = (user) => {
		let currentRole = {
			isSuperAdmin: false,
			isSurgeon: false
		};

		if (user && user.Roles) {
			user.Roles.forEach((role) => {
				if (role.name === SYSTEM_ROLES.ROLE_SUPER_ADMIN) {
					currentRole.isSuperAdmin = true;
				} else if (role.name === SYSTEM_ROLES.ROLE_SURGEON) {
					currentRole.isSurgeon = true;
				}
			});
		}

		return currentRole;
	};

	static sortJsonArray = (array, key) => {
		return array.sort(function (a, b) {
			if (a[key] < b[key]) {
				return -1;
			}
			if (a[key] > b[key]) {
				return 1;
			}
			return 0;
		});
	};

	static validateObject(obj) {
		for (var o in obj) if (!obj[o]) return false;

		return true;
	}

	static getBase64(img, callback) {
		var b64Response = btoa(unescape(encodeURIComponent(img)));
		callback('data:image/jpeg;base64,' + b64Response);
	}

	static trimObject = (myObject) => {
		for (var key in myObject) {
			if (typeof myObject[key] == 'string' && myObject[key] !== '') {
				myObject[key] = myObject[key].trim();
			}
		}

		return myObject;
	};

	static isPasswordMatch = (newPassword, confirmPassword) => {
		return newPassword === confirmPassword;
	};

	static ellipsisRender = (string, showCharCount) => {
		let showChar = showCharCount;
		let moretext = '...';
		let returnString = '';
		if (string && string.length > showChar) {
			var c = string.substr(0, showChar);

			returnString = (
				<>
					<span>
						<HtmlTooltip
							interactive
							title={
								<>
									<Typography color="inherit">{string}</Typography>
								</>
							}>
							<span className="morelink">{c + moretext}</span>
						</HtmlTooltip>
					</span>
				</>
			);
		} else {
			returnString = string;
		}

		return returnString;
	};

	static checkRoleAvailability = (roles = [], roleName) => {
		const isAvailable = roles.find((role) => role.name === roleName);
		return isAvailable ? true : false;
	};

	static checkProfileSetup = (user) => {
		let isSetup = false;
		if (user.handle && user.firstName && user.specialitiesCount && user.specialitiesCount > 0) {
			// if(user.handle && user.firstName){
			isSetup = true;
		} else {
		}
		return isSetup;
	};

	static checkAllowedExtension = (fileName, arrayExtensions) => {
		var ext = fileName.split('.');
		ext = `.${ext[ext.length - 1].toLowerCase()}`;
		if (arrayExtensions.lastIndexOf(ext) == -1) {
			return false;
		} else {
			return true;
		}
	};

	static loggedInUserPermissions = (userRoles, resources, rolesWithPermissions) => {
		let permissions = [];
		const roleName = userRoles.isSystemSuperAdmin ? 'System Super Admin' : userRoles.isVendorAdmin ? 'Vendor Admin' : 'Industry Representative';
		const role = rolesWithPermissions.find((role) => role.name === roleName);
		resources.forEach((resource) => {
			let resAction = {
				id: resource.id,
				name: resource.name,
				title: resource.title,
				actions: []
			};

			resource.actions.forEach((resourceAction) => {
				const isFind = role.permission.find((permission) => permission === resourceAction.action);
				if (isFind) {
					resAction.actions.push(resourceAction.action);
				} else {
				}
			});

			if (resAction.actions.length > 0) {
				permissions?.push(resAction);
			}
		});
		return permissions;
	};

	static swapTags = (text) => {
		let displayText = _clone(text);
		const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
		tags.map((myTag) => {
			const tagData = myTag.slice(3, -2);
			const tagDataArray = tagData.split('||');
			const tagDisplayValue = tagDataArray[2];
			displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue);
		});
		return displayText;
	};

	static getUsersFromTags = (text) => {
		let displayText = _clone(text);
		const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
		const allUserIds = tags.map((myTag) => {
			const tagData = myTag.slice(3, -2);
			const tagDataArray = tagData.split('||');
			return { _id: tagDataArray[1], name: tagDataArray[2] };
		});
		return _uniqBy(allUserIds, (myUser) => myUser._id);
	};

	static getTimeDifference = (date) => {
		const startTime = moment(date);
		const endTime = moment();
		const duration = moment.duration(endTime.diff(startTime));
		const hours = parseInt(duration.asHours());
		let message = '';

		if (hours > 24) {
			const days = parseInt(duration.asDays());
			if (days > 1) {
				message = days + ' days';
			} else {
				message = '1 day';
			}
		} else if (hours === 0) {
			const minutes = parseInt(duration.asMinutes());
			if (minutes > 0) {
				message = minutes + ' minutes';
			} else {
				message = 'Just now';
			}
		} else {
			message = hours + ' hours';
		}

		return message;
	};

	static isInt = (n) => {
		return n % 1 === 0;
	};

	static debounce = (callback, wait, context = this) => {
		let timeout = null;
		let callbackArgs = null;

		const later = () => callback.apply(context, callbackArgs);

		return function ({ id }) {
			callbackArgs = arguments;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	static formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	static isFileImage = (file) => {
		return file && file['type'].split('/')[0] === 'image';
	};

	static nFormatter = (num, digits) => {
		var si = [
			{ value: 1, symbol: '' },
			{ value: 1e3, symbol: 'k' },
			{ value: 1e6, symbol: 'M' },
			{ value: 1e9, symbol: 'G' },
			{ value: 1e12, symbol: 'T' },
			{ value: 1e15, symbol: 'P' },
			{ value: 1e18, symbol: 'E' }
		];
		var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var i;
		for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
				break;
			}
		}
		return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
	};

	static getAvatarText = (name) => {
		const avatar = name.split(' ').map((item, index) => {
			if (index < 2) return item.substr(0, 1);
			return '';
		});
		const result = avatar.join('');
		return result;
	};

	static getUserRole = (user) => {
		const role = user?.role?.find((r) => r.name === HUMAN_READABLE_SYSTEM_ROLES.ROLE_SURGEON || r.name === HUMAN_READABLE_SYSTEM_ROLES.ROLE_INDUSTRY_REPRESENTATIVE);
		return role ? role.name : '';
	};

	static formatFileSize = function (bytes) {
		const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
	};

	/**
	 * [Bulk import] Step 5.4: Prepare entity for background upload
	 * The serverSentEntity represents the conferenceDbInstance. The contents of the serverSentEntity.signedUrls is as follows:
		[
			// Each JSON in this array represents a media asset that was sent to the conference patch call in step 5.1
			{
				path: "<s3BasePath based on media type>/<uuid-v4>.<fileType>",
				urls: "<signedUrl>",
				fileName: "<Name of the file that is to be uploaded>",
				uploadId: "<s3 upload id>", // We must initiate a multipart upload before we can upload any part. In response to your initiate request, Amazon S3 returns an upload ID, a unique identifier, that you must include in your upload part request.
				size: "<size of the file that is to be uploaded>"
			}
		]
	 
	 * The mediaAssetsToBeAdded represents the media assets that are to be uploaded. The contents of the mediaAssetsToBeAdded prop is as follows:
				[
					{
						mediaUrl: "blob:http://localhost:3000/d9071c37-a10a-4162-bab6-b703b32e7c91", 
						path: "PDL_Conference_associate_registration.xlsx",
						lastModified: 1684318829196,
						lastModifiedDate: "2023-05-17T10:20:29.196Z",
						name: "PDL_Conference_associate_registration.xlsx",
						size: 101130,
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						webkitRelativePath: ""
					}
				]
	 	
		*	The goal of the prepareEntityForBackgroundUpload is iterate over the signedUrls from the updated conferenceDbInstance and match it with the mediaAssetsToBeAdded object
		* so that we can add the uploadId and s3Path to the mediaAssetsToBeAdded object. The final object that is returned is as follows:
		entityDetail = {
			<all properties of the updated conferenceDbInstance>,
			mediaAssetsToBeAdded: [
				{
					fileNumber: array index + 1,
					parts: [],
					uploadId: "<s3 upload id>",
					s3Path: "<s3BasePath based on media type>/<uuid-v4>.<fileType>",
				}
			],
			totalMediaSize: <total size of all the media assets that are to be uploaded>
		}
		*/
	static prepareEntityForBackgroundUpload = (serverSentEntity, mediaAssetsToBeAdded) => {
		let entityDetail = Object.assign({}, serverSentEntity);
		entityDetail.mediaAssetsToBeAdded = mediaAssetsToBeAdded;
		let totalMediaSize = 0;
		entityDetail.mediaAssetsToBeAdded = entityDetail.mediaAssetsToBeAdded.map((singleMedia, index) => {
			const mediaItem = entityDetail.signedUrls[index];
			singleMedia.fileNumber = index + 1;
			singleMedia.parts = [];
			if (mediaItem) {
				singleMedia.uploadId = mediaItem.uploadId;
				singleMedia.s3Path = mediaItem.path;
			}
			totalMediaSize += singleMedia.size;
			return singleMedia;
		});
		return { entityDetail, totalMediaSize };
	};
}

export default UtilHelper;
