import {
	CREATE_COMMENT,
	CREATE_POST,
	EMPTY_BACKGROUND_UPLOAD_COMMENT,
	EMPTY_BACKGROUND_UPLOAD_POST,
	EMPTY_POST_LIST,
	EMPTY_POST_LIST_BY_ID,
	GET_ALL_POST,
	GET_ALL_POST_BY_USER_ID,
	SAVE_UNSAVE_POST
} from './SurgeonLandingPageActions';

const initialState = {
	posts: undefined,
	postsById: undefined,
	backgroundUploadPost: undefined,
	backgroundUploadComment: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const SurgeonLandingPageReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of posts to be shown
		case GET_ALL_POST: {
			let postsData;
			if (state.posts !== undefined && action.currentPage > 0) {
				postsData = Object.assign({}, state.posts);
				// postsData.rows.concat(action.posts.rows);
				action.posts.rows = action.posts.rows.map((post, ind) => {
					const findPost = postsData.rows.findIndex((item) => item.id === post.id);
					if (findPost === -1) {
						postsData.rows.push(post);
					} else {
					}
					return post;
				});
				return Object.assign({}, state, {
					posts: postsData,
					postsById: state.postsById,
					backgroundUploadComment: state.backgroundUploadComment,
					backgroundUploadPost: state.backgroundUploadPost
				});
			} else {
				postsData = action.posts;
				return Object.assign({}, state, {
					posts: postsData,
					postsById: state.postsById,
					backgroundUploadComment: state.backgroundUploadComment,
					backgroundUploadPost: state.backgroundUploadPost
				});
			}
			// return Object.assign({}, state, {
			//     posts: postsData
			// });
		}

		//Append newly created post
		case CREATE_POST:
			return Object.assign({}, state, {
				backgroundUploadPost: action.post,
				backgroundUploadComment: state.backgroundUploadComment,
				postsById: state.postsById,
				posts: state.posts
			});

		case SAVE_UNSAVE_POST: {
			let feedPosts = Object.assign({}, state.posts);
			feedPosts.rows = feedPosts.rows.map((item) => {
				if (item.id === action.postId) {
					item.isSaved = action.isSave;
					return item;
				}
				return item;
			});

			return Object.assign({}, state, {
				posts: feedPosts,
				postsById: state.postsById,
				backgroundUploadComment: state.backgroundUploadComment,
				backgroundUploadPost: state.backgroundUploadPost
			});
		}

		//Append newly created post
		case CREATE_COMMENT:
			return Object.assign({}, state, {
				backgroundUploadComment: action.comment,
				backgroundUploadPost: state.post,
				postsById: state.postsById,
				posts: state.posts
			});

		//get list posts by user id
		case GET_ALL_POST_BY_USER_ID: {
			let postsData;
			if (state.postsById !== undefined && action.currentPage > 0) {
				postsData = Object.assign({}, state.postsById);
				// postsData.rows.concat(action.posts.rows);
				action.posts.rows = action.posts.rows.map((post, ind) => {
					const findPost = postsData.rows.findIndex((item) => item.id === post.id);
					if (findPost === -1) {
						postsData.rows.push(post);
					} else {
					}
					return post;
				});
				return Object.assign({}, state, {
					postsById: postsData,
					posts: state.posts,
					backgroundUploadComment: state.backgroundUploadComment,
					backgroundUploadPost: state.backgroundUploadPost
				});
			} else {
				postsData = action.posts;
				return Object.assign({}, state, {
					postsById: postsData,
					backgroundUploadComment: state.backgroundUploadComment,
					backgroundUploadPost: state.backgroundUploadPost
				});
			}
		}

		//Empty post list
		case EMPTY_POST_LIST:
			return Object.assign({}, state, {
				posts: undefined,
				postsById: state.postsById,
				backgroundUploadComment: state.backgroundUploadComment,
				backgroundUploadPost: state.backgroundUploadPost
			});

		//Empty post list
		case EMPTY_POST_LIST_BY_ID:
			return Object.assign({}, state, {
				postsById: undefined,
				posts: state.posts,
				backgroundUploadComment: state.backgroundUploadComment,
				backgroundUploadPost: state.backgroundUploadPost
			});

		//Empty background upload post list
		case EMPTY_BACKGROUND_UPLOAD_POST:
			return Object.assign({}, state, {
				posts: state.posts,
				postsById: state.postsById,
				backgroundUploadComment: state.backgroundUploadComment,
				backgroundUploadPost: undefined
			});
		//Empty background upload comment list
		case EMPTY_BACKGROUND_UPLOAD_COMMENT:
			return Object.assign({}, state, {
				posts: state.posts,
				postsById: state.postsById,
				backgroundUploadComment: undefined,
				backgroundUploadPost: state.backgroundUploadPost
			});
		default:
			return state;
	}
};

export default SurgeonLandingPageReducer;
