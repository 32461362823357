import palette from '../palette';

const MuiAvatar = {
	root: {
		backgroundColor: palette.secondary.main, //'#dadada !important',
		fontFamily: 'Lato'
	},

	colorDefault: {
		backgroundColor: palette.secondary.main //'#dadada !important',
	}
};

export default MuiAvatar;
