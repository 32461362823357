/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_SPECIALITY, CREATE_SPECIALITY, GET_ALL_SPECIALITIES, UPDATE_SPECIALITY } from './SpecialityManagementActions';

const initialState = {
	specialities: []
};

/**
 * Speciality reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const SpecialityReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of specialities to be shown
		case GET_ALL_SPECIALITIES:
			return Object.assign({}, state, {
				specialities: action.specialities
			});

		//Append newly created Speciality
		case CREATE_SPECIALITY:
			return Object.assign({}, state, {
				specialities: [...state.specialities.rows, action.speciality]
			});

		//Update speciality details
		case UPDATE_SPECIALITY: {
			let data = Object.assign({}, state.specialities);

			data.rows = data.rows.map((speciality) => {
				if (speciality.id === action.speciality.id) {
					return action.speciality;
				}
				return speciality;
			});

			return Object.assign({}, state, {
				specialities: data
			});
		}
		case ACTIVATE_DEACTIVATE_SPECIALITY: {
			let data = Object.assign({}, state.specialities);
			data.rows = data.rows.map((speciality) => {
				if (speciality.id === action.speciality.id) {
					speciality.enabled = !speciality.enabled;
					return speciality;
					// return action.speciality;
				}
				return speciality;
			});

			return Object.assign({}, state, {
				specialities: data
			});
		}

		default:
			return state;
	}
};

export default SpecialityReducer;
