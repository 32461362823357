import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const MuiDialogTitle = {
	root: {
		padding: '32px 32px 0px 32px',
		fontFamily: 'Lato',
		[theme.breakpoints.down('md')]: {
			padding: '32px 16px 0 16px !important'
		}
	}
};

export default MuiDialogTitle;
