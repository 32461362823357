/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_ACTUAL_STEPS = 'GET_ALL_ACTUAL_STEPS';
export const CREATE_ACTUAL_STEP = 'CREATE_ACTUAL_STEP';
export const UPDATE_ACTUAL_STEP = 'UPDATE_ACTUAL_STEP';
export const ACTIVATE_DEACTIVATE_ACTUAL_STEP = 'ACTIVATE_DEACTIVATE_ACTUAL_STEP';

/**
 * Action called after get all ActualSteps
 * @param actualSteps
 */
export const getAllActualSteps = (actualSteps) => {
	return {
		type: GET_ALL_ACTUAL_STEPS,
		actualSteps: actualSteps
	};
};

/**
 * Action called after create actualStep
 * @param actualStep
 */

export const createActualStep = (actualStep) => {
	return {
		type: CREATE_ACTUAL_STEP,
		actualStep
	};
};

/**
 * Action called after update actualStep details
 * @param actualStep
 */

export const updateActualStep = (actualStep) => {
	return {
		type: UPDATE_ACTUAL_STEP,
		actualStep
	};
};

/**
 * Action called after activate deactivate actualStep
 * @param actualStep
 */

export const activateDeactivateActualStep = (actualStep) => {
	return {
		type: ACTIVATE_DEACTIVATE_ACTUAL_STEP,
		actualStep
	};
};
