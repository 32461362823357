/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import UtilHelper from '../../utils/UtilHelper';

// Export Constants (Action names)
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ACTIVATE_DEACTIVATE_USER = 'ACTIVATE_DEACTIVATE_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

/**
 * Action called after get all users
 * @param users
 */
export const getAllUsers = (users) => {
	return {
		type: GET_ALL_USERS,
		users: users
	};
};

/**
 * Action called after create/invite user
 * @param user
 */

export const createUser = (user) => {
	return {
		type: CREATE_USER,
		user
	};
};

/**
 * Action called after update user details
 * @param users
 */

export const updateUser = (user) => {
	return {
		type: UPDATE_USER,
		user
	};
};

/**
 * Action called after activate deactivate user
 * @param user
 */

export const activateDeactivateUser = (user) => {
	return {
		type: ACTIVATE_DEACTIVATE_USER,
		user
	};
};

/**
 * Action called after get all roles with prmission api call
 * @param user
 */

export const setUserPermission = (userRoles, resources, rolesWithPermissions) => {
	const userPermissions = UtilHelper.loggedInUserPermissions(userRoles, resources, rolesWithPermissions);
	return {
		type: SET_PERMISSIONS,
		userPermissions: userPermissions
	};
};
