/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_COMMUNITY, ADD_COMMUNITY, GET_ALL_COMMUNITIES, GET_ALL_COMMUNITIES_WITHOUT_PAGINATION, GET_ALL_COMMUNITY_MEMBERS, UPDATE_COMMUNITY } from './CommunityActions';

const initialState = {
	communities: [],
	communityMembers: [],
	communitiesWOPagination: []
};

/**
 * Comminity reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const CommunityReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of communities to be shown
		case GET_ALL_COMMUNITIES:
			return Object.assign({}, state, {
				communities: action.communities
			});

		case GET_ALL_COMMUNITIES_WITHOUT_PAGINATION:
			return Object.assign({}, state, {
				communitiesWOPagination: action.communities
			});

		//Get list of communities to be shown
		case GET_ALL_COMMUNITY_MEMBERS:
			return Object.assign({}, state, {
				communityMembers: action.communityMembers
			});

		//Append newly add community
		case ADD_COMMUNITY:
			return Object.assign({}, state, {
				communities: [...state.communities.rows, action.community]
			});

		//Update community details
		case UPDATE_COMMUNITY: {
			let data = Object.assign({}, state.communities);

			data.rows = data.rows.map((community) => {
				if (community.id === action.community.id) {
					return action.community;
				}
				return community;
			});

			return Object.assign({}, state, {
				communities: data
			});
		}
		case ACTIVATE_DEACTIVATE_COMMUNITY: {
			let data = Object.assign({}, state.communities);
			data.rows = data.rows.map((community) => {
				if (community.id === action.community.id) {
					return action.community;
				}
				return community;
			});

			return Object.assign({}, state, {
				communities: data
			});
		}

		default:
			return state;
	}
};

export default CommunityReducer;
