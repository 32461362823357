/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { SIGN_OUT } from '../Authentication/AuthenticationActions';
import { ACTIVATE_DEACTIVATE_USER, CREATE_USER, GET_ALL_USERS, SET_PERMISSIONS, UPDATE_USER } from './UserActions';

const initialState = {
	users: [],
	userPermissions: null
};

/**
 * User reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of users to be shown
		case GET_ALL_USERS:
			return Object.assign({}, state, {
				users: action.users
			});

		//Append newly created user
		case CREATE_USER:
			return Object.assign({}, state, {
				users: [...state.users.rows, action.user]
			});

		//Update user details
		case UPDATE_USER: {
			let data = Object.assign({}, state.users);

			data.rows = data.rows.map((user) => {
				if (user.id === action.user.id) {
					return action.user;
				}
				return user;
			});

			return Object.assign({}, state, {
				users: data
			});
		}
		case ACTIVATE_DEACTIVATE_USER: {
			let data = Object.assign({}, state.users);
			data.rows = data.rows.map((user) => {
				if (user.id === action.user.id) {
					user.enabled = action.user.enabled;
					return user;
				}
				return user;
			});

			return Object.assign({}, state, {
				users: data
			});
		}

		case SET_PERMISSIONS: {
			return Object.assign({}, state, {
				userPermissions: action.userPermissions
			});
		}

		// Sign out current logged in user
		case SIGN_OUT:
			return Object.assign({}, initialState);

		default:
			return state;
	}
};

export default UserReducer;
