import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const MuiInputLabel = {
	root: {
		fontFamily: 'Lato',
		fontSize: '15px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.25px',
		textAlign: 'left',
		color: '#8b98a4',
		'&$focused': {
			color: '#8b98a4'
		},
		'&$marginDense': {
			transform: 'translate(0, 28px) scale(1)'
		},
		'&$shrink': {
			transform: 'translate(0, 0.2px) scale(1)'
		},
		'&$error': {
			color: '#dc545c !important'
		},
		'&.Mui-disabled': {
			color: '#8b98a4'
		}
	}
	// shrink: {
	//     transform: 'translate(0, 1.5px) scale(0.75)'
	// }
};

export default MuiInputLabel;
