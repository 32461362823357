/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import { setUserVars } from 'react-fullstory';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Used to manage the cookies of the application
 */
class Auth {
	static login(user) {
		/**
		 * Usage on both client side and server side, thats why we used a cookies storage
		 */
		localStorage.setItem('logged_in_user', JSON.stringify(user));
		setUserVars({
			userId: user?.id,
			displayName: user?.firstName,
			email: user?.email,
			env: process.env.REACT_APP_ENV_NAME,
			appVersion: process.env.REACT_APP_VERSION
		});
	}

	static setToken(token) {
		/**
		 * Usage only on client side, thats why we used a localStorage
		 */
		localStorage.setItem('access_token', token);
	}

	/**
	 * Remove all cookies so that user session will be expired
	 */
	static logout() {
		localStorage.removeItem('logged_in_user');
		localStorage.removeItem('access_token');
	}

	/**
	 * Remove all cookies so that user session will be expired
	 */
	static logoutAndNavigateToLogin() {
		cookies.set('CloudFront-Key-Pair-Id', '', { path: '/', domain: '.surgeonapp.com', expires: new Date(0) });
		cookies.set('CloudFront-Policy', '', { path: '/', domain: '.surgeonapp.com', expires: new Date(0) });
		cookies.set('CloudFront-Signature', '', { path: '/', domain: '.surgeonapp.com', expires: new Date(0) });
		localStorage.removeItem('access_token');
		localStorage.removeItem('loggedInAs');
		localStorage.removeItem('logged_in_user');
		window.location = '/';
	}

	/**
	 * Check if authentication JWT token exists in cookies
	 * If it exists then return true
	 */
	static isLoggedIn() {
		let user = localStorage.getItem('logged_in_user');
		return !!user;
	}

	static getToken() {
		return localStorage.getItem('access_token');
	}

	/**
	 * Get logged in user details
	 */
	static getUserDetails() {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		setUserVars({
			userId: userData?.id,
			displayName: userData?.firstName,
			email: userData?.email,
			env: process.env.REACT_APP_ENV_NAME,
			appVersion: process.env.REACT_APP_VERSION
		});

		return userData;
	}

	static setSystemGeneratedPassword(isSystemGeneratedPassword) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.isSystemGeneratedPassword = isSystemGeneratedPassword;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static setEmailVerified(isVerified) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.isEmailVerified = isVerified;
		userData.registrationComplete = true;
		userData.isAccountSetup = true;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static isChangeEmail(isChangeEmail) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.isChangeEmail = isChangeEmail;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static updateEmail(email) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.email = email;
		userData.isChangeEmail = false;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static updateProfileImage(imgUrl) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.profileImageURL = imgUrl;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static updateLoggedInUser(user) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		const updatedUser = { ...userData, ...user };
		localStorage.setItem('logged_in_user', JSON.stringify(updatedUser));
	}

	static setCommunityCount(count) {
		let userData = localStorage.getItem('logged_in_user');
		userData = JSON.parse(userData);
		userData.subscribedCommunityCount = count;
		userData.isEmailVerified = true;
		userData.registrationComplete = true;
		userData.isAccountSetup = true;
		localStorage.setItem('logged_in_user', JSON.stringify(userData));
	}

	static createCookieForCrossAppAuthentication() {
		console.log(`createCookieForCrossAppAuthentication invoked`);
		var cookieName = 'access-token';
		var cookieValue = localStorage.getItem('access_token');
		var myDate = new Date();
		myDate.setMonth(myDate.getMonth() + 12);
		document.cookie = cookieName + '=' + cookieValue + ';expires=' + myDate + ';domain=.surgeonapp.com;path=/';
	}
}

export default Auth;
