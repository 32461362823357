exports.SYSTEM_ROLES = ['ROLE_SUPER_ADMIN', 'ROLE_SURGEON'];
exports.HUMAN_READABLE_SYSTEM_ROLES = {
	'ROLE_SUPER_ADMIN': 'System Super Admin',
	'ROLE_SURGEON': 'Surgeon',
	'ROLE_INDUSTRY_REPRESENTATIVE': 'Industry Representative',
	'ROLE_VENDOR_ADMIN': 'Vendor Admin',
	'ROLE_CONFERENCE_ORGANIZER': 'Conference Organizer',
	'ROLE_COMMUNITY_MODERATOR': 'Community Moderator',
	'ROLE_LIVE_POST_AUTHOR': 'Live Post Author'
};

exports.ENTITY_MEDIA_TYPES = {
	RESOURCE: 'RESOURCE',
	CAROUSEL_IMAGE: 'CAROUSEL_IMAGE',
	PROFILE_IMAGE: 'PROFILE_IMAGE',
	EMAIL_BANNER_IMAGE: 'EMAIL_BANNER_IMAGE',
	VENUE_MAP_DOCUMENT: 'VENUE_MAP_DOCUMENT',
	POST_MEDIA: 'POST_MEDIA',
	CONTENT_IMPORT: 'CONTENT_IMPORT'
};

exports.CONFERENCE_ASSOCIATE_TYPES = {
	ATTENDEE: 'ATTENDEE',
	SPEAKER: 'SPEAKER',
	MODERATOR: 'MODERATOR',
	HOST: 'HOST'
};

exports.CONFERENCE_ANNOUNCEMENT_AUDIENCE = {
	ATTENDEE: 'Attendees',
	SPEAKER: 'Speakers',
	MODERATOR: 'Moderator',
	HOST: 'Hosts',
	ALL_ATTENDEES: 'All Attendees',
	ONLY_MODERATORS: 'Only Moderators',
	ONLY_SPEAKERS: 'Only Speakers',
	ALL_SPEAKERS_AND_MODERATORS: 'All Speakers & Moderators'
};

exports.CONFERENCE_GAMIFICATION_NOTIFICATION_TYPES = [
	'CONFERENCE_GAMIFICATION_LEADERBOARD_RANK_AND_MY_POINTS_UPDATED',
	'CONFERENCE_GAMIFICATION_LEADERBOARD_RANK_UPDATED',
	'CONFERENCE_GAMIFICATION_MY_POINTS_UPDATED'
];

//Route actions
exports.ROUTE_ACTIONS = {
	'SIGNIN': '/signin',
	'SIGNUP': '/signup',
	'CHANGE_EMAIL_SURGEON': '/verificationChangeEmail',
	'ENTER_EMAIL': '/enterEmail',
	'VERIFICATION': '/verification',
	'PROFILE_SETUP': '/profileSetup',
	'FORGOT_PASSWORD': '/forgetPassword',
	'SELECT_COMMUNITY': '/selectCommunities',
	'SELECT_SPECIALITY': '/selectSpecialities',
	'RESET_PASSWORD': '/resetPassword/:userId/:token',
	'INVITE_IR': '/inviteIR/:userId/:token',
	'FORCE_RESET_PASSWORD': '/changePassword',
	'CHANGE_PASSWORD': '/changePassword',
	'USERS': '/users',
	'ADD_USER': '/users/addUser',
	'UPDATE_USER': '/users/:userId/updateUser',

	'VENDORS': '/vendors',
	'VENDOR_DETAILS': '/vendorAccount/:vendorId/:tabValue',

	'CONFERENCES': '/conferences',
	'CONFERENCE_DETAILS': '/conferences/:conferenceId',
	'CONFERENCE_SESSION_DETAILS': '/conferences/sessions/:sessionId',
	'CONFERENCE_PRESENTATION_DETAILS': '/conferences/presentations/:presentationId',
	'LIVE_CONFERENCE_SESSION_DETAILS': '/surgeonConference/session/:conferenceSessionId',
	'PUBLIC_CONFERENCE': '/public/share/conference/:conferenceIdOrHandle',
	'PUBLIC_CONFERENCE_SESSION': '/public/share/conference/:conferenceIdOrHandle/session/:sessionId',
	'PUBLIC_CONFERENCE_PRESENTATION': '/public/share/conference/:conferenceIdOrHandle/session/:sessionId/presentation/:presentationId',

	'ADD_VENDOR_PRODUCT': '/vendor/:vendorId/addProduct',
	'UPDATE_VENDOR_PRODUCT': '/vendor/:vendorId/updateProduct/:productId',
	'PRODUCT_DETAILS': '/vendor/:vendorId/product/:productId',

	'COMMUNITIES': '/communities',
	'ADD_COMMUNITY': '/addCommunity',
	'UPDATE_COMMUNITY': '/updateCommunity/:communityId',
	'COMMUNITY_DETAILS': '/community/:communityId',

	'DASHBOARD': '/users',
	'CONTENT_MANAGEMENT': '/contentManagement',
	'PRODUCT_CATEGORY': '/productCategory',
	'COMMUNITY_CATEGORY': '/communityCategory',

	'DIAGNOSIS': '/diagnosis',
	'SPECIALITY': '/speciality',
	'DOMAIN': '/domain',
	'PROCEDURE': '/procedure',
	'APPROACH': '/approach',
	'ACTUAL_STEP': '/actualStep',

	'CHANGE_EMAIL': '/changeEmail',

	'SURGEON_DASHBOARD': '/surgeonDashboard/:tabValue',
	'SURGEON_DASHBOARD_LIVE_TAB': '/surgeonDashboard/live',
	'POST_DETAILS': '/postdetails/:postId',
	'LIVE_EVENT': '/liveEvent/:postId',
	'PUBLIC_POST': '/publicPost/:postId',
	'SHARE': '/share',
	'OPEN_MOBILE': '/openMobileApp',

	'SURGEON_PROFILE_EDIT': '/surgeonProfile/edit',
	'SURGEON_PROFILE_ABOUT': '/surgeonProfile/about',
	'SURGEON_PROFILE': '/surgeonProfile/:tabValue',
	'SURGEON_PROFILE_WITH_ID': '/profile/:userId/:tabValue',
	'NOTIFICATIONS': '/notifications',
	'MESSAGES': '/messages',
	'CHAT_BOX': '/messages/:roomId',
	'CHAT_BOX_USER': '/messages/room/:userId',
	'CONFERENCE_CHAT_BOX': '/messages/conferenceRoom/:conferenceId',
	'CONFERENCE_SESSION_CHAT_BOX': '/messages/sessionRoom/:sessionId',
	'NEW_CHAT_BOX': '/createNewRoom',
	'SURGEON_COMMUNITIES': '/surgeonCommunities',
	'ALL_COMMUNITIES': '/allCommunities',
	'SURGEON_COMMUNITY': '/surgeonCommunityDetails/:communityId',
	'SURGEON_COMMUNITY_DETAILS': '/surgeonCommunityDetails/:communityId/:tabValue',

	'INTUITIVE_SIGNUP': '/tps/intuitiveSignup',
	'APPLE_SIGNUP': '/tps/appleSignup',

	'APPLE_SITE_ASSOCIATION': '/apple-app-site-association',
	'RELEASE_VERSION': '/releaseVersion',

	'PAGE_NOT_FOUND': '/pageNotFound',
	'PAGE_NOT_AVAILABLE': '/pageNotAvailable',

	'APP_BASE_URL': '/'
};

exports.COMMUNITY_MEMBERSHIP_STATUS = {
	REQUESTED: 'Requested',
	JOINED: 'Joined'
};

exports.FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID
};

exports.PATHS_NOT_REQUIRING_AUTH = [
	'/auth/login',
	'/auth/google/login',
	'/auth/facebook/login',
	'/auth/intuitive/login',
	'/auth/apple/login',
	'/auth/v2/forgotPassword',
	'/auth/resetpassword',
	'/users/register/surgeon',
	'/users/getUserSummaries',
	(conferenceIdOrHandle) => `/conference/${conferenceIdOrHandle}/public`, // demo id 425ee9b0-ebf7-11ee-b9f6-199374f4fcbd
	(postId) => `/post/${postId}/public` //demo id eedf7fe0-4b22-11ee-9ab8-0b767c7bdd04
];

//Server api actions
exports.API_ACTIONS = {
	'LOGIN': '/auth/login',
	'LOGIN_GOOGLE': '/auth/google/login',
	'LOGIN_FACEBOOK': '/auth/facebook/login',
	'LOGIN_INTUITIVE': '/auth/intuitive/login',
	'LOGIN_APPLE': '/auth/apple/login',
	'LOGOUT': '/auth/logout',
	'FETCH_UNREAD_COUNT_FOR_ALL_CHANNELS': '/chat/getUnreadMessagesCount',
	'USER_REGISTRATION': '/users/register/surgeon',
	'FORGOT_PASSWORD': '/auth/v2/forgotPassword',
	'RESET_PASSWORD': '/auth/resetpassword',
	'SET_PASSWORD': '/auth/setpassword',
	'CHANGE_PASSWORD': '/auth/changePassword',
	'RESOURCES': '/auth/resources',
	'ROLES_PERMISSIONS': '/auth/roles/permissions',
	'ROLES': '/auth/roles',
	'RESEND_INVITATION': '/auth/resendInvitation',
	'LINK_INTUITIVE_ACCOUNT_URL_LAMBDA': (userId) => `/auth/user/${userId}/linkIntuitive`,
	'APPROVE_COMMUNITY_SUBSCRIPTION': (communitySubscriptionId) => `communitySubscription/${communitySubscriptionId}/membershipAccept`,
	'REJECT_COMMUNITY_SUBSCRIPTION': (communitySubscriptionId) => `communitySubscription/${communitySubscriptionId}/membershipReject`,
	'CONFERENCE_CREATE': '/conference',
	'CONFERENCE_LIST': '/conference/list',
	'CONFERENCE_LIST_FOR_ADMINISTRATION': '/conference/listForAdministration',
	'CONFERENCE_LIVE_STATUS': '/conference/fetchLiveStatus',
	'CONFERENCE_SUPPLEMENT_LIST': '/conference/supplements/list',
	'CONFERENCE_SUPPLEMENT': '/conference/supplements',
	'CONFERENCE_SUPPLEMENT_BY_ID': (entitySupplementId) => `/conference/supplements/${entitySupplementId}`,
	'CONFERENCE_BY_ID': (conferenceId) => `conference/${conferenceId}`,
	'CONFERENCE_START_BY_ID': (conferenceId) => `conference/${conferenceId}/start`,
	'CONFERENCE_NOIFY_USERS_BY_CONFERENCE_ID': (conferenceId) => `conference/${conferenceId}/notifyUsersOnConferenceUpdate`,
	'CONFERENCE_PUBLISH_BY_ID': (conferenceId) => `conference/${conferenceId}/publish`,
	'CONFERENCE_END_BY_ID': (conferenceId) => `conference/${conferenceId}/end`,
	'CONFERENCE_MEDIA_UPLOADED_BY_ID': (conferenceId) => `/conference/${conferenceId}/mediaUploadCompleted`,
	'CONFERENCE_PUBLIC_BY_ID_OR_HANDLE': (conferenceIdOrHandle) => `/conference/${conferenceIdOrHandle}/public`,
	'CONFERENCE_ORGANIZER_UPSERT': '/conference/upsertConferenceManagementPermissionsOfUser',
	'CONFERENCE_ORGANIZER_DELETE': '/conference/deleteConferenceManagementPermissionsOfUser',
	'CONFERENCE_ANNOUNCEMENT_LIST': '/conference/announcement/list',
	'CONFERENCE_ANNOUNCEMENT_CREATE': 'conference/announcement',
	'CONFERENCE_SESSION_LIST': '/conference/session/list',
	'CONFERENCE_SESSION_BY_ID': (sessionId) => `conference/session/${sessionId}`,
	'CONFERENCE_SESSION_START_BY_ID': (sessionId) => `conference/session/${sessionId}/start`,
	'CONFERENCE_SESSION_END_BY_ID': (sessionId) => `conference/session/${sessionId}/end`,
	'CONFERENCE_SESSION_CREATE': 'conference/session',
	'CONFERENCE_SESSION_PUBLISH_BY_ID': (sessionId) => `conference/session/${sessionId}/publish`,
	'CONFERENCE_SESSION_REACTION_BY_ID': (sessionId) => `conference/session/${sessionId}/liveChatReaction`,

	'CONFERENCE_TRACKS_LIST': 'conference/track/list',
	'CONFERENCE_USER_REGISTRATION_TYPE_LIST': 'conference/registrationType/list',

	'CONFERENCE_PRESENTATION_LIST': '/conference/presentation/list',
	'CONFERENCE_PRESENTATION_BY_ID': (presentationId) => `conference/presentation/${presentationId}`,
	'CONFERENCE_PRESENTATION_CREATE': 'conference/presentation',
	'CONFERENCE_PRESENTATION_MEDIA_UPLOADED_BY_ID': (presentationId) => `/conference/presentation/${presentationId}/mediaUploadCompleted`,

	'CONFERENCE_ASSOCIATE_CREATE': '/conference/associates',
	'CONFERENCE_ASSOCIATE_BULK_IMPORT': '/conference/associates/bulkImport',
	'CONFERENCE_ADMINISTRATION_ASSOCIATE_LIST': '/conference/admin/associates/list',
	'CONFERENCE_ASSOCIATE_BY_ID': (associateId) => `/conference/associates/${associateId}`,

	'USERS': '/users',
	'USERS_LIST': '/users/list',
	'GET_USERS_SUMMARIES': '/users/getUserSummaries',
	'CATEGORY': '/category',
	'PRODUCT': '/product',
	'COMMUNITY': '/community',
	'FETCH_COMMUNITIES_ELIGIBLE_FOR_POST_CREATION': '/fetchCommunitiesEligibleForPostCreation',
	'COMMUNITY_MEMBERS': (communityId) => `community/${communityId}/users`,
	'COMMUNITY_LEAVE': (communityId) => `community/${communityId}/membershipLeave`,
	'COMMUNITY_REVOKE': (communityId) => `community/${communityId}/membershipRevoke`,
	'COMMUNITY_SUPPLEMENTS': `community/supplements/list`,
	'COMMUNITY_SUPPLEMENT': `community/supplements`,
	'COMMUNITY_RESOURCES': `community/fetchResources`,
	'GET_FEED': '/post/feed/all',
	'GET_LIVE_EVENTS': '/post/liveEvents/all',
	'USER_INVITE': '/users/invite',
	'VENDORS': '/vendor',
	'COMMUNITY_CATEGORY': '/communityCategory',
	'TAGS': '/tag',
	'DIAGNOSIS': '/tag',
	'SPECIALITY': '/tag',
	'DOMAINS': '/tag',
	'PROCEDURES': '/tag',
	'NOTIFICATIONS': '/nes/notifications',
	'NOTIFICATION_GET_HTML_CONTENT': '/notify/user-engagement/getNotificationHtmlContent',
	'ROOM': '/chat/fetchRooms',
	'FETCH_CONFERENCE_DETAIL_FOR_CHAT': '/conference/fetchConferenceDetailsForChatFromCache',
	'FETCH_SESSION_DETAILS_FOR_CHAT': '/conference/fetchConferenceSessionDetailsForChatFromCache',
	'ROOM_MESSAGES': '/chat/fetchMessages',
	'SEND_TO_SERVER_INBOUND_CHANNEL': '/notify/pubnub/sendToServerInboundChannel',
	'GET_LAST_MESSAGE_SYNCED_AT': '/chat/channel/lastMessageSyncedAt',
	'NOTIFY_INBOUND_CHANNEL': 'notify/pubnub/sendToServerInboundChannel',
	'SUBSCRIBE_TOKEN_TO_TOPIC': '/nes/subscribeTokenToTopic',
	'APPROACHES': '/tag',
	'ACTUAL_STEPS': '/tag',
	'POST': '/post',
	'POST_STATS': '/view-stats/v1/updatePostStatsToSQS',
	'GENERIC_MEDIA_FETCH_PART_UPLOAD_URL': '/media/fetchMultipartUploadUrl',
	'GENERIC_MEDIA_COMPLETE_MULTIPART_UPLOAD': '/media/completeMultipartUpload',
	'GET_PART_UPLOAD_URL': '/post/multipart/getPartUploadUrl',
	'GET_PART_UPLOAD_URL_COMMENT': '/comment/multipart/getPartUploadUrl',
	'COMPLETE_MULTIPART_UPLOAD': '/post/multipart/completeUpload',
	'COMPLETE_MULTIPART_UPLOAD_COMMENT': '/comment/multipart/completeUpload',
	'SINGLE_POST': '/post',
	'USER_POSTS_BY_ID': '/post/user',
	'POST_REACTIONS': (postId) => `post/${postId}/reaction`,
	'LIVE_EVENT_SEND_REMINDER': (postId) => `post/${postId}/sendLiveEventReminder`,
	'LIVE_EVENT_STATUS_CHECK': (postId) => `post/liveEvent/flag/${postId}`,
	'SINGLE_POST_COMMENTS': '/comment',
	'UPDATE_POST_COMMENT_STAT': '/comment/read',
	'UPVOTE_DOWNVOTE': '/comment',
	'COMMENT': '/comment',
	'USER_COMMENTS': '/comment/user',
	'MENTION_COMMENTS': '/comment/mentioned',
	'REPORT_COMMENT': '/comment',
	'USER_SAVED_POSTS': '/post/saved',
	'REPORTING_OPTIONS': '/post/reportingOptions/all',
	'EDIT_COMMENT': '/comment',
	'ALL_REPORTED_POST': '/post/reported',
	'ALL_REPORTED_USERS': '/users/reported',
	'ALL_REPORTED_COMMENTS': '/comment/reported',
	'DELETE_REPORTED_POST': '/post',
	'DELETE_REPORTED_COMMENT': '/comment',
	'RESTORE_POSTS': '/post',
	'RESTORE_COMMENTS': '/comment',

	'SYSTEM_PROPERTIES': 'sytemUtils/systemProperty/fetch',
	'CREATE_ACTIVITY_LOG': 'sytemUtils/activityLog',
	// "SIGN_UP": "/signup",
	// "RESET_PASSWORD": "/resetpassword",

	'SUBMIT_VOTE': 'poll/vote'
};

exports.SIDE_MENU_SA = [
	{ label: 'side_menu.user_management', action: this.ROUTE_ACTIONS.DASHBOARD, resource: 'userManagement' },
	{ label: 'side_menu.vendors', action: this.ROUTE_ACTIONS.VENDORS, moreActions: [this.ROUTE_ACTIONS.VENDOR_DETAILS], resource: 'vendorManagement' },
	{
		label: 'side_menu.category',
		children: [
			// {label: 'side_menu.product_category', action: this.ROUTE_ACTIONS.PRODUCT_CATEGORY, resource: 'productCategoryManagement'},
			{
				label: 'side_menu.community_category',
				action: this.ROUTE_ACTIONS.COMMUNITY_CATEGORY,
				resource: 'communityCategoryManagement'
			}
		]
	},
	{
		label: 'side_menu.tag_management',
		children: [
			{ label: 'side_menu.diagnosis', action: this.ROUTE_ACTIONS.DIAGNOSIS, resource: 'diagnosisManagement' },
			{ label: 'side_menu.speciality', action: this.ROUTE_ACTIONS.SPECIALITY, resource: 'specialityManagement' },
			{ label: 'side_menu.domain', action: this.ROUTE_ACTIONS.DOMAIN, resource: 'domainManagement' },
			{ label: 'side_menu.procedure', action: this.ROUTE_ACTIONS.PROCEDURE, resource: 'procedureManagement' },
			{ label: 'side_menu.approach', action: this.ROUTE_ACTIONS.APPROACH, resource: 'approachManagement' },
			{ label: 'side_menu.actualStep', action: this.ROUTE_ACTIONS.ACTUAL_STEP, resource: 'actualStepManagement' }
		]
	},
	{
		label: 'side_menu.content_management',
		action: this.ROUTE_ACTIONS.CONTENT_MANAGEMENT,
		resource: 'contentManagement'
	},
	{
		label: 'side_menu.conferences',
		action: this.ROUTE_ACTIONS.CONFERENCES,
		resource: 'conferenceManagement'
	}
];

exports.SIDE_MENU_VENDOR = [{ label: 'side_menu.account', action: this.ROUTE_ACTIONS.VENDOR_DETAILS, resource: 'vendorAccountManagement' }];
exports.SIDE_MENU_IR = [{ label: 'side_menu.account', action: this.ROUTE_ACTIONS.VENDOR_DETAILS, resource: 'vendorAccountManagement' }];
exports.SIDE_MENU_COMMUNITY_MODERATOR = { label: 'side_menu.communities', action: this.ROUTE_ACTIONS.COMMUNITIES, resource: 'communityManagement' };

exports.LIVE_EVENT_CHAT_MAX_CHARACTERS = 400;
exports.ROW_PER_PAGE_10 = 10;
exports.ROW_PER_PAGE_20 = 20;
exports.ROW_PER_PAGE_5 = 5;
exports.POST_TITLE_LENGTH_VALIDATION_280 = 280;
exports.HANDLE_VALIDATION_REGEX = '^[0-9a-zA-Z_]+$';
exports.HANDLE_LENGTH_VALIDATION_4 = 4;
exports.WEBSITE_VALIDATION_REGEX = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';
exports.MIN_COUNT_SPECIALITY_SELECTED = 1;
exports.HANDLE_LENGTH_VALIDATION_15 = 15;
exports.NPI_LENGTH_VALIDATION_10 = 10;
exports.BIO_LENGTH_VALIDATION_280 = 280;
exports.PAGE_SIZE_OPTIONS = [1, 5, 10, 20, 30, 40, 50];

exports.SNACKBAR_VARIAINT = {
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info'
};

exports.ONLY_BLANK_SPACES = 'onlyBlankSpaces';
exports.VALIDATE_PASSWORD = 'validatePassword';
exports.IS_PASSWORD_MATCH = 'isPasswordMatch';
exports.IS_USER_HANDLE_AVAILABLE = 'isUserHandleAvailable';
exports.IS_VALID_NPI = 'isValidNPI';

exports.ONLY_CHARACTER_ACCEPT = 'onlyCharacterAccept';
exports.BLOCK_SPECIAL_CHARACTER = 'blockSpecialCharacter';
exports.VALID_EMAIL_ADDRESS = 'validEmailAddress';
exports.DECIMAL_INPUT = 'onlyDecimalInput';

exports.VALIDATIONS = {
	MAXIMUM_CHARACTERS_TEXT_FIELD: 30,
	MAXIMUM_CHARACTERS_TEXT_AREA: 255,
	MAXIMUM_CHARACTERS_TEXT_200: 200,
	MAXIMUM_CHARACTERS_TEXT_300: 300,
	MAXIMUM_CHARACTERS_NPI: 10,
	MINIMUM_CHARACTERS_NPI: 10,
	MAXIMUM_CHARACTERS_TEXT_120: 120,
	MAXIMUM_CHARACTERS_ZIP_CODE: 9,
	MINIMUM_CHARACTERS_ZIP_CODE: 4,
	MINIMUM_CHARACTERS_OTP_FIELD: 6,
	MAXIMUM_CHARACTERS_OTP_FIELD: 6,
	MAXIMUM_CHARACTERS_LIVE_EVENT_NOTIFICATION_TITLE: 40,
	MINIMUM_CHARACTERS_LIVE_EVENT_NOTIFICATION_TITLE: 3,
	MAXIMUM_CHARACTERS_LIVE_EVENT_NOTIFICATION_BODY: 80,
	MINIMUM_CHARACTERS_LIVE_EVENT_NOTIFICATION_BODY: 3,
	MAXIMUM_CHARACTERS_MEMBERSHIP_REJECT_REASON: 80,
	MINIMUM_CHARACTERS_MEMBERSHIP_REJECT_REASON: 3
};

exports.LOG_EVENTS = {
	COMMUNITIES: 'Communities_Landing',
	ALL_COMMUNITIES: 'All_Communities',
	COMMUNITY: 'Community',
	SEARCH: 'Master Search',
	USER_LOOKUP: 'User_Lookup',
	TAG_SEARCH: 'Tag_List',
	SIGN_UP: 'Sign_up',

	// This is in use on web
	LOGIN: 'Login',
	SIGN_OUT: 'Sign_out',
	MESSAGES: 'Messages',
	NOTIFICATIONS: 'Notifications',
	POST: 'Post',
	CONFERNECE: 'Conference',
	FEED: 'Feed',
	TRENDING: 'Trending',
	LIVE: 'Live',
	PROFILE: 'Profile'
};

exports.PERMISSIONS = {
	'name': 'System Super Admin',
	'description': 'Can view anything and everything in the system',
	'sidemenu': this.SIDE_MENU_SA,
	'landingPageAction': { name: 'user', action: '/users' },
	'actions': [
		{
			resource: 'userManagement',
			modules: [
				{
					name: 'user',
					permission: ['createUser', 'updateUser', 'disableUser', 'deleteUser', 'getAllUsers']
				}
			]
		},
		{
			resource: 'vendorManagement',
			modules: [
				{
					name: 'vendor',
					permission: ['createVendor', 'updateVendor', 'disableVendor', 'deleteVendor', 'getAllVendor']
				}
			]
		},
		{
			resource: 'vendorAccountManagement',
			modules: [
				{
					name: 'vendor',
					permission: ['createVendor', 'updateVendor', 'disableVendor', 'deleteVendor', 'getAllVendor']
				},
				{
					name: 'user',
					permission: ['createUser', 'updateUser', 'disableUser', 'deleteUser', 'getAllUsers']
				},
				{
					name: 'product',
					permission: ['createProduct', 'updateProduct', 'disableProduct', 'deleteProduct', 'getAllProduct']
				}
			]
		},
		{
			resource: 'productCategoryMangement',
			modules: [
				{
					name: 'category',
					permission: ['createCategory', 'updateCategory', 'disableCategory', 'deleteCategory', 'getAllCategory']
				}
			]
		},
		{
			resource: 'communityCategoryMangement',
			modules: [
				{
					name: 'category',
					permission: ['createCategory', 'updateCategory', 'disableCategory', 'deleteCategory', 'getAllCategory']
				}
			]
		},
		{
			resource: 'communityManagement',
			modules: [
				{
					name: 'community',
					permission: ['createCommunity', 'updateCommunity', 'disableCommunity', 'deleteCommunity', 'getAllCommunity']
				}
			]
		}
	]
};

exports.DEVICE_TYPE = {
	ANDROID_TYPE_ID: '00000000-0000-0000-0000-000000005001',
	IOS_TYPE_ID: '00000000-0000-0000-0000-000000005002',
	WEB_TYPE_ID: '00000000-0000-0000-0000-000000005003'
};

exports.NO_CONTAINER_GRID_ROUTES = [this.ROUTE_ACTIONS.MESSAGES, this.ROUTE_ACTIONS.CHAT_BOX];
