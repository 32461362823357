import palette from '../palette';
import typography from '../typography';

const MuiTableCell = {
	root: {
		...typography.body2,
		borderBottom: `1px solid rgba(98, 125, 152, .25)`
	},

	head: {
		fontSize: '16px',
		fontWeight: '500',
		backgroundColor: `${palette.primary.main} !important`,
		letterSpacing: '0.25px'
	}
};

export default MuiTableCell;
