import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTooltip from './MuiTooltip';
import MuiTypography from './MuiTypography';

import MuiAlert from './MuiAlert';
import MuiDrawer from './MuiDrawer';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiTimeline from './MuiTimeline';
import MuiTimelineOppositeContent from './MuiTimelineOppositeContent';

import MuiDialog from './MuiDialog';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogContent from './MuiDialogContent';
import MuiDialogTitle from './MuiDialogTitle';
import MuiListItemText from './MuiListItemText';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';

import MuiChip from './MuiChip';
import MuiInput from './MuiInput';
import MuiInputLabel from './MuiInputLabel';

import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';

import MuiDivider from './MuiDivider';

const index = {
	MuiButton,
	MuiIconButton,
	MuiPaper,
	MuiTable,
	MuiTableCell,
	MuiTableHead,
	MuiTypography,

	MuiDrawer,
	MuiTab,
	MuiTabs,
	MuiAlert,
	MuiTimelineOppositeContent,
	MuiTimeline,

	MuiMenu,
	MuiMenuItem,
	MuiListItemText,

	MuiDialog,
	MuiDialogTitle,
	MuiDialogContent,
	MuiDialogActions,

	MuiChip,
	MuiInput,
	MuiInputLabel,
	MuiAutocomplete,
	MuiAvatar,
	MuiTooltip,

	MuiDivider,
	MuiCheckbox
};

export default index;
