// Export Constants (Action names)
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const GET_COMMENTS = 'GET_COMMENTS';
export const UPVOTE_DOWNVOTE = 'UPVOTE_DOWNVOTE';

/**
 * Action called after get single post
 * @param post
 */
export const getSinglePost = (post) => {
	return {
		type: GET_SINGLE_POST,
		post: post
	};
};

/**
 * Action called after get comments for single post
 * @param comments
 */
export const getSinglePostComments = (comments) => {
	return {
		type: GET_COMMENTS,
		comments: comments
	};
};

/**
 * Action called after up vote down vote
 * @param comments
 */
export const getUpVoteDownVote = (comments) => {
	return {
		type: UPVOTE_DOWNVOTE,
		comments: comments
	};
};
