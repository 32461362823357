/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_COMMUNITIES = 'GET_ALL_COMMUNITIES';
export const GET_ALL_COMMUNITIES_WITHOUT_PAGINATION = 'GET_ALL_COMMUNITIES_WITHOUT_PAGINATION';
export const GET_ALL_COMMUNITY_MEMBERS = 'GET_ALL_COMMUNITY_MEMBERS';
export const ADD_COMMUNITY = 'ADD_COMMUNITY';
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';
export const ACTIVATE_DEACTIVATE_COMMUNITY = 'ACTIVATE_DEACTIVATE_COMMUNITY';

/**
 * Action called after get all communities
 * @param communities
 */
export const getAllCommunities = (communities) => {
	return {
		type: GET_ALL_COMMUNITIES,
		communities: communities
	};
};

/**
 * Action called after get all communities
 * @param communities
 */
export const getAllCommunitiesWOPagination = (communities) => {
	return {
		type: GET_ALL_COMMUNITIES_WITHOUT_PAGINATION,
		communities: communities
	};
};

/**
 * Action called after add community
 * @param community
 */

export const addCommunity = (community) => {
	return {
		type: ADD_COMMUNITY,
		community
	};
};

/**
 * Action called after update community details
 * @param community
 */

export const updateCommunity = (community) => {
	return {
		type: UPDATE_COMMUNITY,
		community
	};
};

/**
 * Action called after activate deactivate community
 * @param community
 */

export const activateDeactivateCommunity = (community) => {
	return {
		type: ACTIVATE_DEACTIVATE_COMMUNITY,
		community
	};
};

/**
 * Action called after get all community members
 * @param members
 */
export const getAllCommunityMembers = (members) => {
	return {
		type: GET_ALL_COMMUNITY_MEMBERS,
		communityMembers: members
	};
};
