import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const primary = '#15202a';
const secondary = '#4d9ce6';

const palette = {
	black,
	white,
	primary: {
		contrastText: white,
		dark: primary,
		main: primary,
		light: primary
	},
	secondary: {
		contrastText: white,
		dark: secondary,
		main: secondary,
		light: secondary
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: colors.green[600],
		light: colors.green[400]
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400]
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400]
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400]
	},
	text: {
		primary: '#bcccdc',
		secondary: colors.blueGrey[600],
		link: colors.blue[600],
		placeholder: '#828282'
	},
	background: {
		default: '#F4F6F8',
		paper: white
	},
	actionPostive: {
		primary: '#49D8A4',
		main: '#49D8A4',
		contrastText: '#fff'
	},
	actionNegative: {
		primary: '#E85961',
		main: '#E85961',
		contrastText: '#fff'
	},
	icon: '#bcccdc',
	divider: colors.grey[200]
};

export default palette;
