import { colors } from '@material-ui/core';

const MuiTableHead = {
	root: {
		backgroundColor: colors.grey[50],
		fontFamily: 'Lato'
	}
};

export default MuiTableHead;
