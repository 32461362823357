import palette from './palette';
// import { createMuiTheme } from '@material-ui/core/styles';
// const theme = createMuiTheme();

const typography = {
	h1: {
		color: palette.text.primary,
		fontSize: '101px',
		fontWeight: 300,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '-1.5px',
		fontFamily: 'Lato'
	},
	h2: {
		color: palette.text.primary,
		fontWeight: 300,
		fontSize: '63px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '-0.5px',
		fontFamily: 'Lato'
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 'normal',
		fontSize: '50px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		fontFamily: 'Lato'
	},
	h4: {
		color: palette.text.primary,
		fontWeight: 'normal',
		fontSize: '36px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.25px',
		fontFamily: 'Lato'
	},
	h5: {
		color: palette.text.primary,
		fontWeight: 'normal',
		fontSize: '25px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		fontFamily: 'Lato'
	},
	h6: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '20px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.15px',
		fontFamily: 'Lato'
	},
	subtitle1: {
		color: palette.text.primary,
		fontWeight: 'normal',
		fontSize: '17px',
		letterSpacing: '0.15px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		fontFamily: 'Lato'
	},
	subtitle2: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '15px',
		letterSpacing: '0.15px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		fontFamily: 'Lato'
	},
	subtitlebold: {
		color: palette.text.primary,
		fontWeight: 'bold',
		fontSize: '15px',
		letterSpacing: '0.4px',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		fontFamily: 'Lato'
	},
	body1: {
		color: palette.text.primary,
		fontSize: '17px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.5px',
		fontFamily: 'Lato'
	},
	body2: {
		color: palette.text.primary,
		fontSize: '15px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.25px',
		fontFamily: 'Lato'
	},
	button: {
		color: palette.text.primary,
		fontSize: '16px',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '1.25px'
	},
	caption: {
		color: palette.text.primary,
		fontSize: '13px',
		fontWeight: '700',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		fontFamily: 'Lato'
	},
	captionbold: {
		color: palette.text.primary,
		fontSize: '13px',
		fontWeight: '700',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.25px',
		fontFamily: 'Lato'
	},
	textcompact: {
		color: palette.text.primary,
		fontSize: '13px',
		fontWeight: '400',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		fontFamily: 'Lato'
	},
	overline: {
		color: palette.text.primary,
		fontSize: '10px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '1.5px',
		fontFamily: 'Lato'
	},
	tagslined: {
		color: palette.text.primary,
		fontSize: '12px',
		fontWeight: 600,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: '0.5px',
		fontFamily: 'Lato'
	},
	searchplaceholder: {
		'font-family': 'Lato',
		'font-style': 'normal',
		'font-weight': 400,
		'font-size': '15px',
		'line-height': '18px',
		'color': palette.text.placeholder,
		'letterSpacing': '0'
	}
};

export default typography;

// ,
//   body1: {
//     fontSize: '14px',
//     letterSpacing: '-0.05px',
//     lineHeight: '21px',
//     fontWeight: 300,
//     wordbreak: 'break-all',
//     fontSize: '1.2rem',
//     '@media (min-width:600px)': {
//       fontSize: '1.2rem',
//     },
//     [theme.breakpoints.up('xl')]: {
//       fontSize: '2rem',
//     },
//   }
