const MuiTab = {
	root: {
		// borderBottomWidth: '1px',
		// borderBottomStyle: 'solid',
		// borderBottomColor: '#d6d7d8',
		borderRadius: '0px',
		fontSize: '15px',
		textTransform: 'capitalize',
		fontFamily: 'Lato',
		'&$selected': {
			fontWeight: 'bold',
			color: '#4d9ce6'
		}
	},

	textColorInherit: {
		color: '#8b98a4',
		fontWeight: 'bold'
	},
	textColorPrimary: {
		color: '#8b98a4',
		fontWeight: 'bold'
	},
	textColorSecondary: {
		color: '#8b98a4',
		fontWeight: 'bold'
	}
};

export default MuiTab;
