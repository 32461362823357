const MuiMenu = {
	paper: {
		margin: '2px 0 4px 0px',
		// padding: '16px',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
		border: 'solid 0.5px #283745 !important',
		backgroundColor: '#15202a',
		minWidth: '108px'
	},
	list: {
		padding: 0
	}
};

export default MuiMenu;
