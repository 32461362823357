import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const MuiDialog = {
	paper: {
		borderRadius: '8px',
		boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.25)',
		backgroundColor: '#15202a',
		padding: '0px',
		minWidth: '700px',
		[theme.breakpoints.down('sm')]: {
			minWidth: '200px'
		}
	}
};

export default MuiDialog;
