import { BACKGROUND_UPLOAD_CONFERENCE, CREATE_CONFERENCE_PART, SET_CONFERENCE_PROGRESS, UNSET_CONFERENCE } from './BackgroundUploadActions';

const initialState = {
	backgroundUploadConference: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const BackgroundUploadConferenceReducer = (state = initialState, action) => {
	switch (action.type) {
		//Append newly created conference
		/**
			 * [Bulk import] Step 5.6: Mark the upload not started yet by setting isUploadStart to false, progress to 0, 
			 * totalUploadedMediaSize to 0, and totalMediaSize to the total size of all media files
			 *
			 * Store the following data in the redux store:
			 * - conference: Update conference DB instance sent in this action
			 * - mediaAssetsToBeAdded: Update mediaAssetsToBeAdded sent in this action. Structure:
						[{
							fileNumber: array index + 1,
							parts: [],
							uploadId: "<s3 upload id>",
							s3Path: "<s3BasePath based on media type>/<uuid-v4>.<fileType>",
						}]
				* - isUploadStart: false
				* - totalMediaSize: Update totalMediaSize sent in this action
				* - totalUploadedMediaSize: 0
				* - progress: 0
				*/
		case BACKGROUND_UPLOAD_CONFERENCE:
			return Object.assign({}, state, {
				backgroundUploadConference: {
					conference: action.conference,
					mediaAssetsToBeAdded: action.conference.mediaAssetsToBeAdded,
					isUploadStart: false,
					totalMediaSize: action.totalMediaSize,
					totalUploadedMediaSize: 0,
					progress: 0
				}
			});

		//Create new part in particular file
		/**
		 * [Bulk import] Step 8.9: Persist the presigned URL of the current file chunk being uploaded under the appropriate reducer state
		 */
		case CREATE_CONFERENCE_PART: {
			// [Bulk import] Step: Deep clone the backgroundUploadConference object
			let tempBackgroundUploadConference = Object.assign({}, state.backgroundUploadConference);
			if (tempBackgroundUploadConference?.mediaAssetsToBeAdded?.length > 0) {
				tempBackgroundUploadConference.mediaAssetsToBeAdded = tempBackgroundUploadConference.mediaAssetsToBeAdded.map((singleMedia) => {
					// [Bulk import] Step: Find the media file from the list of media files in the redux store that matches the file based on fileNumber sent in this action
					if (singleMedia.fileNumber === action.fileNumber) {
						// [Bulk import] Step: Create a new part object with the following properties:
						const part = {
							partNumber: action.partNumber, // partNumber: this represents the index of the file chunk being uploaded
							presignedUrl: action.presignedUrl, // presignedUrl: this is the presigned URL of the file chunk being uploaded
							totalPartSize: action.totalPartSize, // totalPartSize: this is the size of the file chunk being uploaded
							uploadedPartSize: 0 // uploadedPartSize: this is the size of the file chunk that has been uploaded so far
						};
						if (singleMedia.parts.length > 0) {
							// [Bulk import] Step: If the media file already has parts, check if the part being uploaded already exists in the parts array
							const findPart = singleMedia.parts.find((singlePart) => singlePart.partNumber === action.partNumber);

							// [Bulk import] Step: Add the new part JSON to the current media (singleMedia) only if the part does not already exist in the parts array
							if (findPart === undefined) {
								singleMedia.parts.push(part);
							}
						} else {
							// [Bulk import] Step: If the media file does not have any parts, add the new part JSON to the current media (singleMedia)
							singleMedia.parts.push(part);
						}

						// [Bulk import] Step: Return the updated media file
						return singleMedia;
					} else {
						// [Bulk import] Step: Return the media file as is if the fileNumber does not match
						return singleMedia;
					}
				});
				// [Bulk import] Step: Update the isUploadStart property to true in the redux store
				tempBackgroundUploadConference.isUploadStart = true;
			}

			return Object.assign({}, state, {
				backgroundUploadConference: tempBackgroundUploadConference
			});
		}

		//Set conference progress of part in particular file
		case SET_CONFERENCE_PROGRESS: {
			let tempBackgroundUploadConferenceForSetProcess = Object.assign({}, state.backgroundUploadConference);
			if (
				tempBackgroundUploadConferenceForSetProcess &&
				tempBackgroundUploadConferenceForSetProcess.mediaAssetsToBeAdded &&
				tempBackgroundUploadConferenceForSetProcess.mediaAssetsToBeAdded.length > 0
			) {
				tempBackgroundUploadConferenceForSetProcess.mediaAssetsToBeAdded = tempBackgroundUploadConferenceForSetProcess.mediaAssetsToBeAdded.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						if (singleMedia.parts.length > 0) {
							const findPartIndex = singleMedia.parts.findIndex((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPartIndex > -1) {
								singleMedia.parts[findPartIndex].uploadedPartSize = action.uploadedSize;
							}
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});

				// sum of uploaded size of each file part
				const uploadedSizeForEachFile = tempBackgroundUploadConferenceForSetProcess.mediaAssetsToBeAdded.map((item) => {
					return item.parts.reduce((sum, part) => sum + part.uploadedPartSize, 0);
				});

				// sum of uploaded size including all file
				const totalUploadedSize = uploadedSizeForEachFile.reduce((sum, eachFileSize) => sum + eachFileSize, 0);
				tempBackgroundUploadConferenceForSetProcess.totalUploadedMediaSize = totalUploadedSize;
				const percentCompleted = Math.round((totalUploadedSize * 100) / tempBackgroundUploadConferenceForSetProcess.totalMediaSize);
				tempBackgroundUploadConferenceForSetProcess.progress = percentCompleted;
			}

			return Object.assign({}, state, {
				backgroundUploadConference: tempBackgroundUploadConferenceForSetProcess
			});
		}

		// Empty conference list
		// [Bulk import] Step 8.30: Empty the conference list in the redux store and mark the background upload as complete
		case UNSET_CONFERENCE:
			return Object.assign({}, state, {
				backgroundUploadConference: undefined
			});

		default:
			return state;
	}
};

export default BackgroundUploadConferenceReducer;
