import HttpRequest from '../../utils/HttpRequest';

import Constants, { API_ACTIONS, DEVICE_TYPE, SNACKBAR_VARIAINT } from '../../utils/Constants';
import { showSnackbar } from '../../utils/components/AppLoader/AppLoaderActions';

import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import Auth from '../../utils/Auth';
import { ROW_PER_PAGE_5 } from '../../utils/Constants';
import { signIn } from '../Authentication/AuthenticationActions';
import { getAllPost, getAllPostByUser } from './SurgeonLandingPageActions';

let paginationParams = {
	page: 0,
	rowsPerPage: Constants.ROW_PER_PAGE_10
};

let trendingPostPaginationParams = {
	page: 0,
	rowsPerPage: Constants.ROW_PER_PAGE_10
};

let livePostPaginationParams = {
	page: 0,
	rowsPerPage: Constants.ROW_PER_PAGE_10
};

/**
 * Server API call to get all post
 * - Dispatch getAllPost action after successful response from server
 * @returns {function(*)}
 */
export const getAllPostRequest = (data) => {
	paginationParams = data;
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = data.searchData;
	let communityType = data.communityType ?? 'all';

	const params = {
		offset: data.page * data.rowsPerPage,
		limit: data.rowsPerPage,
		sort: data.sort,
		searchData: data.searchData,
		communityType: data.communityType ?? 'all'
	};

	return (dispatch) => {
		// const url = `${API_ACTIONS.POST}/feed/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}&sort=${data.sort}&communityType=${communityType}`;
		const url = `v2/post/fetchHomeFeedPosts`;

		return new HttpRequest(dispatch, url, 'post', params).send(null, true).then(
			(res) => {
				dispatch(getAllPost(res.data.data, data.page));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all post
 * - Dispatch getAllPost action after successful response from server
 * @returns {function(*)}
 */
export const getAllPostByCommunityIdRequest = (data) => {
	paginationParams = data;
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = data.searchData;

	return (dispatch) => {
		const url = `${API_ACTIONS.POST}?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

		return new HttpRequest(dispatch, url, 'get').send(null, true).then(
			(res) => {
				dispatch(getAllPost(res.data.data, data.page));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

export const getCommunityFeedPageRequest = (data) => {
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = data.searchData;
	let sort = data?.sort;

	const params = {
		offset: data.page * data.rowsPerPage,
		limit: data.rowsPerPage,
		sort: data?.sort,
		searchData: data.searchData
	};

	return (dispatch) => {
		// const url = `${API_ACTIONS.GET_FEED}?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}&sort=${sort}`;
		const url = `v2/post/fetchCommunityFeedPosts`;

		return new HttpRequest(dispatch, url, 'post', params).send(null, true).then(
			(res) => {
				const result = res.data.data;
				dispatch(getAllPost(result, data.page));
				return Promise.resolve(result);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all trending post
 * - Dispatch getAllTrendingPost action after successful response from server
 * @returns {function(*)}
 */
export const getAllTrendingPostRequest = (data) => {
	trendingPostPaginationParams = data;
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = data.searchData;

	return (dispatch) => {
		const url = `${API_ACTIONS.POST}/trending/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

		return new HttpRequest(dispatch, url, 'get').send(null, true).then(
			(res) => {
				dispatch(getAllPost(res.data.data, data.page));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all trending post
 * - Dispatch getAllTrendingPost action after successful response from server
 * @returns {function(*)}
 */
export const getAllLivePostRequest = (data) => {
	livePostPaginationParams = data;
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = data.searchData;

	return (dispatch) => {
		const url = `${API_ACTIONS.POST}/liveEvents/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

		return new HttpRequest(dispatch, url, 'get').send(null, true).then(
			(res) => {
				dispatch(getAllPost(res.data.data, data.page));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all post os user
 * - Dispatch getAllPostByUser action after successful response from server
 * @returns {function(*)}
 */
export const getAllPostsByUserId = (data, userId) => {
	paginationParams = data;
	let offset = data.page * data.rowsPerPage;
	let limit = data.rowsPerPage;
	let searchData = {
		targetUserId: userId,
		'searchString': ''
	};

	const params = {
		offset: data.page * data.rowsPerPage,
		limit: data.rowsPerPage,
		sort: data?.sort,
		searchData: searchData
	};

	return (dispatch) => {
		// const url = `${API_ACTIONS.USER_POSTS_BY_ID}/${userId}?offset=${offset}&limit=${limit}`;

		const url = `v2/post/fetchUserFeedPosts`;

		return new HttpRequest(dispatch, url, 'post', params).send(null, true).then(
			(res) => {
				dispatch(getAllPostByUser(res.data.data, data.page));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all post os user
 * - Dispatch getAllPostByUser action after successful response from server
 * @returns {function(*)}
 */
export const submitVote = (data) => {
	return (dispatch) => {
		console.log('DATA: ', data);
		const url = `${API_ACTIONS.SUBMIT_VOTE}`;
		return new HttpRequest(dispatch, url, 'post', data).send().then(
			(res) => {
				console.log('RES: ', res);
				const paginationData = {
					page: 0,
					rowsPerPage: ROW_PER_PAGE_5,
					searchData: {
						CommunityId: ''
					},
					sort: 'MOST_RECENT_COMMENT'
				};
				dispatch(getAllPostRequest(paginationData)).then(
					(res) => {
						console.log('STORE DISPATCH RESPONSE', res);
						return Promise.resolve(res);
					},
					(error) => {
						return Promise.reject(error);
					}
				);
				return Promise.resolve(res.data);
			},
			(error) => {
				console.log('ERROR: ', error);
				dispatch(showSnackbar(error?.response?.data?.error, SNACKBAR_VARIAINT.ERROR));
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all post os user
 * - Dispatch getAllPostByUser action after successful response from server
 * @returns {function(*)}
 */
export const endPoll = (postId) => {
	return (dispatch) => {
		const url = `${API_ACTIONS.POST}/${postId}/end`;
		return new HttpRequest(dispatch, url, 'patch').send().then(
			(res) => {
				// dispatch(getSinglePostRequest(postId))
				const paginationData = {
					page: 0,
					rowsPerPage: ROW_PER_PAGE_5,
					searchData: {
						CommunityId: ''
					},
					sort: 'MOST_RECENT_COMMENT'
				};
				dispatch(getAllPostRequest(paginationData)).then(
					(res) => {
						console.log('STORE DISPATCH RESPONSE', res);
						return Promise.resolve(res);
					},
					(error) => {
						return Promise.reject(error);
					}
				);
				return Promise.resolve(res.data);
			},
			(error) => {
				dispatch(showSnackbar(error?.response?.data?.error, SNACKBAR_VARIAINT.ERROR));
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all tags
 * - Dispatch getAllTags action after successful response from server
 * @returns {function(*)}
 */
export const getAllTagsRequest = (searchData, isHideLoader = false) => {
	let offset = null;
	let limit = null;

	return (dispatch) => {
		const url = `${API_ACTIONS.TAGS}/list?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

		return new HttpRequest(dispatch, url, 'get').send(null, isHideLoader).then(
			(res) => {
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all tags
 * - Dispatch getAllHandles action after successful response from server
 * @returns {function(*)}
 */
export const getAllHandlesRequest = (name) => {
	return (dispatch) => {
		const url = `users/handle/all?name=${name}`;

		return new HttpRequest(dispatch, url, 'get').send().then(
			(res) => {
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get all tags
 * - Dispatch getAllProductTags action after successful response from server
 * @returns {function(*)}
 */
export const getAllProductTagsRequest = (searchData) => {
	return (dispatch) => {
		const url = `product?offset=${null}&limit=${null}&searchData=${JSON.stringify(searchData)}`;
		return new HttpRequest(dispatch, url, 'get').send().then(
			(res) => {
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to create a new post
 * - Dispatch createPost action after successful response from server
 * @returns {function(*)}
 */
export const createPostRequest = (data, communityId) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${communityId}`, 'post', data)
			.send()
			.then((res) => {
				dispatch(showSnackbar(I18n.t('feed.post.add_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				// dispatch(getAllPostRequest(paginationParams));
				// dispatch(addPost(res.data.data));
				return Promise.resolve(res.data);
			})
			.catch((error) => {
				if (error?.response?.data?.error?.length) {
					dispatch(showSnackbar(error?.response?.data?.error, SNACKBAR_VARIAINT.ERROR));
				}
				return Promise.reject(error);
			});
	};
};

/**
 * Server API call to edit post
 * - Dispatch editPost action after successful response from server
 * @returns {function(*)}
 */
export const updatePostRequest = (postId, data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}`, 'patch', data).send().then(
			(res) => {
				dispatch(showSnackbar(I18n.t('feed.post.update_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				// dispatch(addPost(res.data.data));
				return Promise.resolve(res.data);
			},
			(error) => {
				if (error?.response?.data?.error?.length) {
					dispatch(showSnackbar(error?.response?.data?.error, SNACKBAR_VARIAINT.ERROR));
				}
				return Promise.reject(error);
			}
		);
	};
};

export const getSignedUrlRequest = (data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/files/signedURL`, 'post', data)
			.send()
			.then((res) => {
				return Promise.resolve(res.data.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

export const uploadToS3Request = (url, file) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, url, 'put', file)
			.s3Upload(null, true)
			.then((res) => {
				return Promise.resolve(res.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	};
};

/**
 * Server API call to save unsve post
 * - Dispatch saveUnsavePost action after successful response from server
 * @returns {function(*)}
 */
export const saveUnsavePostRequest = (postId, data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}/save`, 'patch', data).send().then(
			(res) => {
				dispatch(showSnackbar(data.save ? I18n.t('feed.post.save_post_success_message') : I18n.t('feed.post.unsave_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to report a post
 * - Dispatch reportPost action after successful response from server
 * @returns {function(*)}
 */
export const reportPostRequest = (postId, data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}/report`, 'put', data).send().then(
			(res) => {
				dispatch(showSnackbar(I18n.t('feed.post.report_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to report a post
 * - Dispatch reportPost action after successful response from server
 * @returns {function(*)}
 */
export const sharePostRequest = (postId) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}/share`, 'patch').send().then(
			(res) => {
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to report a post
 * - Dispatch deletePost action after successful response from server
 * @returns {function(*)}
 */
export const deletePostRequest = (postId) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}`, 'delete').send().then(
			(res) => {
				dispatch(showSnackbar(I18n.t('feed.post.delete_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to rating a post
 * - Dispatch ratingPost action after successful response from server
 * @returns {function(*)}
 */
export const ratingPostRequest = (postId, data) => {
	console.log('Rating post request: ', postId, data);
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/${postId}/rate`, 'patch', data).send().then(
			(res) => {
				// dispatch(showSnackbar(I18n.t('feed.post.report_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to change the live streaming status
 * @returns {function(*)}
 */
export const changeLiveStreamingStatusRequest = (postId, data) => {
	return (dispatch) => {
		return new HttpRequest(dispatch, `${API_ACTIONS.POST}/liveEvent/${postId}`, 'patch', data).send().then(
			(res) => {
				// dispatch(showSnackbar(I18n.t('feed.post.report_post_success_message'), SNACKBAR_VARIAINT.SUCCESS));
				return Promise.resolve(res.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

/**
 * Server API call to get live event flag
 * @returns {function(*)}
 */
export const getLiveEventFlagRequest = (hideLoader = false) => {
	return (dispatch) => {
		const url = `${API_ACTIONS.POST}/liveEvent/flag`;
		return new HttpRequest(dispatch, url, 'get').send(null, hideLoader).then(
			(res) => {
				let userObj = Auth.getUserDetails();
				userObj.eventsAvailable = res.data.data.eventsAvailable;
				userObj.liveEventStarted = res.data.data.liveEventStarted;
				Auth.login(userObj);
				dispatch(signIn(userObj, ''));
				return Promise.resolve(res.data.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

export const updatePostStatsRequest = (data) => {
	return (dispatch) => {
		const url = `${API_ACTIONS.POST_STATS}`;
		return new HttpRequest(dispatch, url, 'post', data).send(null, true).then(
			(res) => {
				return Promise.resolve(res.data.data);
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};
};

export function getAllSubCommunitiesRequest({ communityId, params }) {
	return async () => {
		try {
			const serviceToken = localStorage.getItem('access_token');

			const response = await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/community/subcommunity/${communityId}`, {
				params: { ...params },
				headers: {
					'access-token': `Bearer ${serviceToken}`,
					appVersion: process.env.REACT_APP_VERSION,
					deviceType: DEVICE_TYPE.WEB_TYPE_ID
				}
			});

			return response.data?.data;
		} catch (error) {
			console.log('getAllSubCommunitiesRequest error', error);
		}
	};
}
