/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_APPROACHES = 'GET_ALL_APPROACHES';
export const CREATE_APPROACH = 'CREATE_APPROACH';
export const UPDATE_APPROACH = 'UPDATE_APPROACH';
export const ACTIVATE_DEACTIVATE_APPROACH = 'ACTIVATE_DEACTIVATE_APPROACH';

/**
 * Action called after get all approaches
 * @param approaches
 */
export const getAllApproaches = (approaches) => {
	return {
		type: GET_ALL_APPROACHES,
		approaches: approaches
	};
};

/**
 * Action called after create approach
 * @param approach
 */

export const createApproach = (approach) => {
	return {
		type: CREATE_APPROACH,
		approach
	};
};

/**
 * Action called after update approach details
 * @param approach
 */

export const updateApproach = (approach) => {
	return {
		type: UPDATE_APPROACH,
		approach
	};
};

/**
 * Action called after activate deactivate approach
 * @param approach
 */

export const activateDeactivateApproach = (approach) => {
	return {
		type: ACTIVATE_DEACTIVATE_APPROACH,
		approach
	};
};
