// Export Constants (Action names)
export const GET_ALL_POST = 'GET_ALL_POST';
export const EMPTY_POST_LIST = 'EMPTY_POST_LIST';
export const EMPTY_POST_LIST_BY_ID = 'EMPTY_POST_LIST_BY_ID';
export const CREATE_POST = 'CREATE_POST';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const GET_ALL_POST_BY_USER_ID = 'GET_ALL_POST_BY_USER_ID';
export const EMPTY_BACKGROUND_UPLOAD_POST = 'EMPTY_BACKGROUND_UPLOAD_POST';
export const EMPTY_BACKGROUND_UPLOAD_COMMENT = 'EMPTY_BACKGROUND_UPLOAD_COMMENT';
export const SAVE_UNSAVE_POST = 'SAVE_UNSAVE_POST';
/**
 * Action called after get all post
 * @param posts
 */
export const getAllPost = (posts, currentPage) => {
	return {
		type: GET_ALL_POST,
		posts: posts,
		currentPage: currentPage
	};
};

/**
 * Action called after create post
 * @param posts
 */
export const addPost = (post) => {
	return {
		type: CREATE_POST,
		post: post
	};
};

/**
 * Action called after create post
 * @param posts
 */
export const saveUnsavePost = (postId, isSave) => {
	return {
		type: SAVE_UNSAVE_POST,
		postId: postId,
		isSave: isSave
	};
};

/**
 * Action called after create comment
 * @param posts
 */
export const addComment = (comment) => {
	return {
		type: CREATE_COMMENT,
		comment: comment
	};
};

/**
 * Action called after get all post for user by id
 * @param posts
 */
export const getAllPostByUser = (posts, currentPage) => {
	return {
		type: GET_ALL_POST_BY_USER_ID,
		posts: posts,
		currentPage: currentPage
	};
};

/**
 * Action called after tab change
 */
export const setPostListEmpty = () => {
	return {
		type: EMPTY_POST_LIST
	};
};

/**
 * Action called after tab change
 */
export const setPostListByIdEmpty = () => {
	return {
		type: EMPTY_POST_LIST_BY_ID
	};
};

/**
 * Action called after background uplaod post complete
 */
export const setBackgroundUploadPostEmpty = () => {
	return {
		type: EMPTY_BACKGROUND_UPLOAD_POST
	};
};

/**
 * Action called after background uplaod comment complete
 */
export const setBackgroundUploadCommentEmpty = () => {
	return {
		type: EMPTY_BACKGROUND_UPLOAD_COMMENT
	};
};
