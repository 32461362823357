/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { GET_ALL_NOTIFICATIONS, READ_UNREAD_NOTIFICATIONS } from './NotificationActions';

const initialState = {
	notifications: []
};

/**
 * Notifications reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const NotificationReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of notifications to be shown
		case GET_ALL_NOTIFICATIONS:
			return Object.assign({}, state, {
				notifications: action.notifications
			});

		case READ_UNREAD_NOTIFICATIONS: {
			let data = Object.assign({}, state.notifications);
			data.rows = data.rows.map((notification) => {
				if (notification.id === action.notification.id) {
					notification.enabled = !notification.enabled;
					return notification;
					// return action.notification;
				}
				return notification;
			});

			return Object.assign({}, state, {
				notifications: data
			});
		}

		default:
			return state;
	}
};

export default NotificationReducer;
