/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ACTIVATE_DEACTIVATE_CATEGORY = 'ACTIVATE_DEACTIVATE_CATEGORY';

/**
 * Action called after get all categories
 * @param categories
 */
export const getAllCategories = (categories) => {
	return {
		type: GET_ALL_CATEGORIES,
		categories: categories
	};
};

/**
 * Action called after create category
 * @param category
 */

export const createCategory = (category) => {
	return {
		type: CREATE_CATEGORY,
		category
	};
};

/**
 * Action called after update category details
 * @param category
 */

export const updateCategory = (category) => {
	return {
		type: UPDATE_CATEGORY,
		category
	};
};

/**
 * Action called after activate deactivate category
 * @param category
 */

export const activateDeactivateCategory = (category) => {
	return {
		type: ACTIVATE_DEACTIVATE_CATEGORY,
		category
	};
};
