import { CLEAR_POSTS_SEND_REMINDER, SET_POSTS_SEND_REMINDER } from './SingalFeedActions';

const initialState = {
	postId: null
};

const SingalFeedReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_POSTS_SEND_REMINDER:
			return Object.assign({}, state, {
				postId: action.postId
			});

		case CLEAR_POSTS_SEND_REMINDER:
			return Object.assign({}, state, {
				comments: action.comments,
				postId: null
			});

		default:
			return state;
	}
};

export default SingalFeedReducer;
