import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const MuiDialogActions = {
	root: {
		padding: '0px 32px 32px 32px',
		fontFamily: 'Lato',
		[theme.breakpoints.down('md')]: {
			padding: '16px 16px 16px 16px !important'
		}
	}
};

export default MuiDialogActions;
