const MuiDrawer = {
	paper: {
		borderRadius: '2px',
		boxShadow: '4px 0 4px -1px rgba(0, 0, 0, 0.1)',
		border: 'solid 1px rgba(214, 215, 216, 0.5)',
		backgroundColor: '#15202A'
	},
	paperAnchorDockedLeft: {
		borderRight: '1px solid #3a434c'
	}
};

export default MuiDrawer;
