const en = {
	live_event_transition_dialog: {
		started: {
			title: 'Live event started',
			action_name: 'See live stream'
		},
		ended: {
			title: 'Live event ended',
			action_name: 'Go to the post',
			default_body: 'The live event has ended'
		}
	},
	live_conference_session_transition_dialog: {
		started: {
			title: 'Conference session started',
			action_name: 'See live stream'
		},
		ended: {
			title: 'Conference session ended',
			action_name: 'Reload',
			default_body: 'The live conference session has ended'
		}
	},
	live_conference_transition_dialog: {
		started: {
			title: 'Conference started',
			action_name: 'See live stream'
		},
		ended: {
			title: 'Conference ended',
			action_name: 'See all events',
			default_body: 'The live conference has ended'
		}
	},
	live_event_notification_dialog: {
		title: 'Live event notification',
		action_name: 'Send notification'
	},
	liveEvent: {
		chatViewHeaderLiveChat: 'Live chat',
		chatViewHeaderParticipants: 'Participants',
		messageInputPlaceholder: 'Add a comment',
		userLiveStreamingIn: '@%{userHandle} is livestreaming in %{communityName}'
	},
	error_messages: {
		field_required: 'This field is required',
		only_required: 'required',
		email_invalid_error: 'E-mail not valid',
		mobile_number_invalid_error: 'Please enter valid mobile number',
		blank_spaces_not_allowed: 'Blank spaces not allowed',
		digit_not_allowed: 'Digit is not allow',
		network_error: 'Something went wrong. Please try again.',
		invalid_credentials: 'Invalid credentials',
		error: 'Error',
		invalid_file_type: 'File type not supported',
		username_password_incorrect: 'User name or password is incorrect',
		registration_date_invalid_error: 'Please select registration date',
		maximum_allowed_characters_for_text_field: 'Maximum 30 characters are allowed',
		maximum_allowed_characters_for_text_area: 'Maximum 255 characters are allowed',
		maximum_200_allowed_characters_for_text: 'Maximum 200 characters are allowed',
		maximum_300_allowed_characters_for_text: 'Maximum 300 characters are allowed',
		maximum_120_allowed_characters_for_text: 'Maximum 120 characters are allowed',
		regex_match_for_handle: 'Only alphanumeric and underscore characters allowed',
		minimum_allowed_characters_for_handle: 'Minimum %{count} characters are required',
		maximum_allowed_characters_for_handle: 'Maximum %{count} characters are allowed',
		maximum_allowed_characters: 'Maximum %{count} characters are allowed',
		minimum_allowed_characters: 'Minimum %{count} characters are allowed',
		only_digit_allow: 'Only digits are allowed',
		password_mismatch: 'Password mismatch',
		valid_password: 'Password should have minimum 1 uppercase, 1 lowercase, 1 numeric, 1 special characters',
		invalid_npi: 'Invalid NPI number',
		user_handle_already_used: 'That username has been taken. Please choose another one.',
		specialities_should_be_selected: 'Please select at least %{count} speciality',
		website_should_valid_url: 'Please enter a valid URL for your website'
	},

	global: {
		app_name: 'SurgeOn',
		no_activity_detected_title: 'No Activity Detected',
		no_activity_detected_message: 'System has detected no activity in past 30 minutes. Please login again to continue.',
		session_expired_title: 'Session Expired',
		logout_user_message: 'Are you sure you want to logout?',
		logout_user_title: 'Logout',
		logout_button_label: 'Logout',
		cancel_button_label: 'Cancel',
		ok_button_label: 'Ok',
		back_button_label: 'Previous Step',
		next_button_label: 'Next',
		finish_button_label: 'Finish',
		message_modal_note_field_label: 'Note'
	},

	side_menu: {
		user_management: 'User Management',
		account: 'Account',
		vendors: 'Vendors',
		content_management: 'Content Management',
		communities: 'Communities',
		conferences: 'Conferences',
		community_details: 'Communities Details',
		category: 'Categories',
		product_category: 'Product',
		community_category: 'Community',
		tag_management: 'Tag Management',
		diagnosis: 'Diagnosis',
		speciality: 'Speciality',
		domain: 'Domain',
		procedure: 'Procedure',
		approach: 'Approach',
		actualStep: 'Actual Step',
		change_password: 'Change Password',
		change_email: 'Change E-mail',
		base_url: 'Home'
	},

	sign_in: {
		sign_in_label: 'Login',
		sign_in_form_username_label: 'E-mail ID',
		sign_in_form_password_label: 'Password',
		sign_in_button_lavel: 'Sign in',
		login_label: 'Login',
		forgot_password_label: 'Forgot password',
		do_not_have_an_account: 'Don’t have an account? ',
		sign_up_from_mobile: 'Sign up from mobile',

		become_a_member_label: 'Become a member',
		or_continue_with_label: 'Or continue with',
		google_label: 'Google',
		facebook_label: 'Facebook',
		apple_label: 'Apple',
		my_intuitive_label: 'Intuitive',
		by_continuing_label: 'By continuing you agree to our',
		terms_of_service_label: ' Terms of Service'
	},

	sign_up: {
		signup_header_label: 'Create a new account',
		signup_reset_password_header_label: 'Reset your password',
		signup_form_email_label: 'E-mail',
		signup_form_password_label: 'Password',
		signup_form_re_type_password_label: 'Retype Password',
		signup_password_field_validation_header: 'Your password should contain',
		signup_password_field_eight_char_message: '8 or more characters',
		signup_password_field_lower_upper_case_message: 'Both lower and upper case characters',
		signup_password_field_one_digit_one_special_char: 'At least one digit and one special character',
		signup_submit_button_label: 'Send verification e-mail'
	},

	verification_code: {
		verification_form_header_label: 'Please enter the verification code sent to the e-mail address %{email}',
		verification_form_verification_code_label: 'Verification code',
		verification_form_invalid_verification_code_message: 'Invalid verification code. Please try again.',
		verification_form_submit_button_label: 'Create my profile',
		verification_form_try_another_email_label: 'Try another e-mail address',
		or_label: 'or',
		resend_code_label: 'Request another e-mail after ',
		resend_code_button_label: 'Resend'
	},

	profile_setup: {
		profile_setup_form_header_label: 'Profile information',
		profile_setup_form_header_message: 'Please tell us a bit about yourself so that other medical professionals in the community can know you better',
		profile_setup_form_upload_button_label: 'Upload a profile picture',
		profile_setup_form_handle_field_label: 'Username (Required)',
		profile_setup_form_npi_field_label: 'NPI (Required)',
		profile_setup_form_dont_have_an_npi_label: 'I don’t have an NPI',
		profile_setup_form_invalid_npi_error_message: 'Invalid NPI',
		profile_setup_dont_have_an_npi_dialog_title: "Don't have an NPI?",
		profile_setup_dont_have_an_npi_dialog_message_1: 'We take user verifacation seriously, and want to ensure we keep SurgeOn a safe space.',
		profile_setup_dont_have_an_npi_dialog_message_2: "Since you don't have an NPI number, send a message to us below explaining your situation and we'll get you sorted out!",
		profile_setup_dont_have_an_npi_dialog_button_label: 'Send a message',
		profile_setup_form_fullname_field_label: 'Full name (Required)',
		profile_setup_form_academic_title_field_label: 'Academic Title',
		profile_setup_form_institution_name_field_label: 'Institution Name',
		profile_setup_form_bio_field_label: 'Bio',
		profile_setup_form_speciality_field_label: 'Supported specialities (Required)',
		profile_setup_form_surgical_speciality_field_label: 'Surgical speciality (Required)',
		profile_setup_form_city_field_label: 'City, State of Operation',
		profile_setup_form_website_field_label: 'Website',
		profile_setup_form_next_button_label: 'Next',

		profile_setup_do_not_have_an_npi_mail_to_subject: '[%{email}] I don’t have an NPI',
		profile_setup_do_not_have_an_npi_mail_to_message: 'My e-mail address is %{email}. I need your help in setting up my profile.',

		profile_setup_support_mail_to_subject: '%{email} I am stuck on the profile page',
		profile_setup_support_mail_to_message: 'My e-mail address is %{email}. I need your help with',

		profile_setup_need_help_label: 'Need help?',
		profile_setup_contact_support_label: 'Contact SurgeOn Support'
	},

	profile_edit: {
		profile_edit_form_education_label: 'Education',
		profile_edit_form_hospitals_label: 'Hospitals',
		profile_edit_form_about_label: 'About',
		profile_edit_form_save_button_label: 'Save'
	},

	dialog_connect_to_intuitive: {
		title: 'Connect to Intuitive',
		title_with_username: '@%{username} is connected to Intuitive',
		content_start: 'Connecting to Intuitive gives you:',
		content_bullets: ['Daily, automatic import of your procedure names and counts', 'One-time import of your profile information', 'Ability to log in with Intuitive single sign-on'],
		content_end: 'You can disconnect at any time.',
		action_name: 'Connect to Intuitive',
		supplement_action_name: 'Go to My Intuitive'
	},

	dialog_disconnect_to_intuitive: {
		title: 'Disconnect from Intuitive',
		content_start: 'Disconnecting to Intuitive means:',
		content_bullets: [
			'Procedure names and counts will be removed from SurgeOn and no longer import from Intuitive',
			'Profile data will remains',
			'You can no longer log in to SurgeOn with Intuitive single sign-on'
		],
		action_name: 'Disconnect from Intuitive',
		supplement_action_name: 'Go to My Intuitive'
	},

	dialog_both_connect_intuitive_send_message: {
		title: '@%{username} is also connected to Intuitive',
		content_start: 'Connect with a fellow Intuitive user!',
		action_name: 'Send a Message'
	},

	dialog_intuitive_connected_success: {
		title: 'You are now connected!',
		content_start: 'Your profile information from Intuitive will be reflected in your SurgeOn profile shortly. Your procedure counts (dV cases) will be automatically imported in your profile.',
		action_name: 'OK'
	},

	dialog_intuitive_dvCases: {
		title: 'da Vinci (dV) Cases',
		content_start: 'Cases performed using a da Vinci robotic system. Information imported from My Intuitive.',
		action_name: 'Send a message',
		supplement_action_name_if_both_accounts_are_connected: 'See my cases in My Intuitive',
		supplement_action_name_if_logged_in_user_is_not_connected: 'Join My Intuitive'
	},

	speciality_selection: {
		speciality_selection_header_label: 'Specialities',
		speciality_selection_supported_header_label: 'Supported Specialities',
		speciality_selection_header_message: 'What do you specialize in?',
		profile_setup_form_done_button_label: 'Done'
	},

	community_selection: {
		community_selection_header_label: 'Communities',
		community_selection_header_message: 'Tap communities that spark your interest to personalize your feed.',
		community_selection_button_label: 'You’re ready to go!',
		community_selection_logout_dialog_title: 'Do you want to log out?',
		community_selection_logout_dialog_content: 'This will log you out of SurgeOn. Do you want to continue?',
		community_selection_logout_dialog_action_name: 'Logout'
	},

	change_email: {
		change_email_header_label: 'Enter an e-mail address',
		change_email_header_message: 'Please type your registered e-mail address to receive a password reset link in your registered e-mail',
		change_email_form_username_label: 'E-mail',
		change_email_form_button_label: 'Send verification e-mail'
	},

	forgot_password: {
		forgot_password_label: 'Forgot password',
		forgot_password_header_detail: 'Please type your registered e-mail address to receive a password reset link in your registered e-mail',
		forgot_password_action_button: 'Send password reset e-mail',

		email_sent_label: 'E-mail sent',
		reset_password_link_sent: 'Please click on the link sent to %{email} to reset your password.',
		go_to_sign_in: 'Go back',

		redirection_to_login_page: ' You will be redirected to the login page. Please use the one time password send to your e-mail address to login into the application',
		enter_email_message: 'Please type your registered e-mail address to receive a password reset link in your registered e-mail.'
	},

	reset_password: {
		new_password_label: 'Enter password',
		re_enter_password_label: 'Enter password again',
		reset_password_button: 'Change password',
		reset_password_label: 'Reset password',
		go_to_sign_in: 'go to sign in!',
		or_label: 'Or',
		redirection_to_login_page: ' You will be redirected to the login page. Please use the new password to login into the application',
		reset_password_submit_button: 'Change password'
	},

	force_reset_password: {
		header_label: 'Reset your password',
		action_button: 'Change Password'
	},

	change_password: {
		current_password_form_label: 'Enter Current Password',
		new_password_form_label: 'Enter New Password',
		re_enter_new_password_form_label: 'Re-enter new password',
		reset_password_button: 'Reset Password',
		cancel_button: 'Cancel'
	},

	invite_ir: {
		launch_app_label: 'Launch App',
		do_not_have_app_label: "Don't have app installed? ",
		download_now_label: 'Download Now'
	},

	user: {
		add_user: {
			form_title_add_user_label: 'Add User',
			form_title_update_user_label: 'Update User',

			form_field_name_label: 'Name',
			form_field_email_label: 'E-mail',
			form_field_phone_label: 'Phone',
			form_field_vendor_label: 'Vendor',
			form_field_role_label: 'Role',
			form_field_products_label: 'Products',

			form_field_is_industry_reps_label: 'Is Industry Rep?',
			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_user_success_message: 'User invited successfully',
			update_user_success_message: 'User details updated successfully',

			change_role_confirmation_dialog_title: 'Warning',
			change_role_confirmation_dialog_message:
				'If the current user has any data (posts, ratings given, comments, votes) it will get purged from the system. <br/><br/>Are you sure you want to change the user role from Surgeon to IR?'
		},

		user_table: {
			table_header_label: 'User List',
			add_user_button_label: 'Add User',

			user_resend_invitation_success_message: 'Successfully resend the invitation to the user',
			user_activate_success_message: 'User activated successfully',
			user_deactivate_success_message: 'User deactivated successfully',

			user_block_success_message: 'User blocked successfully',
			user_unblock_success_message: 'User unblocked successfully'
		},

		vendor_admin_table: {
			add_user_button_label: 'Add User',
			table_column_name_label: 'Name',
			table_column_vendor_label: 'Vendor',
			table_column_email_label: 'E-mail',
			table_column_phone_label: 'Phone',
			table_column_joined_on_label: 'Joined On',
			table_column_status_label: 'Invitation status',
			table_column_accepted_label: 'Accepted',
			table_column_pending_label: 'Pending',
			table_column_resend_invitation_tooltip_title: 'Resend Invitation',
			table_column_resend_invitation_dialog_title: 'Resend Invitation',
			table_column_number_of_products_label: 'Products',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',
			table_filter_user_type_label: 'User Type',

			table_action_column_deactivate_tooltip_title: 'Deactivate',
			table_action_column_active_tooltip_title: 'Activate',

			table_action_column_disabled_tooltip_title: 'Disable',
			table_action_column_enabled_tooltip_title: 'Enable',

			filter_active_status_label: 'Active Status',
			table_filter_search_by_name_email_phone_label: 'Search by Name or E-mail'
		},

		conference_organizer_table: {
			add_user_button_label: 'Add User',
			table_column_name_label: 'Name',
			table_column_email_label: 'E-mail',
			table_column_phone_label: 'Phone',
			table_column_joined_on_label: 'Joined On',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',
			table_filter_user_type_label: 'User Type',
			table_filter_search_by_name_email_phone_label: 'Search by Name or E-mail'
		},

		system_super_admin_table: {
			add_user_button_label: 'Add Super Admin',
			table_column_name_label: 'Name',
			table_column_email_label: 'E-mail',
			table_column_phone_label: 'Phone',
			table_column_joined_on_label: 'Joined On',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			table_column_status_label: 'Invitation status',
			table_column_accepted_label: 'Accepted',
			table_column_pending_label: 'Pending',
			table_column_resend_invitation_tooltip_title: 'Resend Invitation',
			table_column_resend_invitation_dialog_title: 'Resend Invitation',

			table_action_column_deactivate_tooltip_title: 'Deactivate',
			table_action_column_active_tooltip_title: 'Activate',

			filter_active_status_label: 'Active Status',
			table_filter_search_by_name_email_phone_label: 'Search by Name or E-mail'
		},

		industry_representative_table: {
			add_user_button_label: 'Add Industry Rep',
			table_column_name_label: 'Name',
			table_column_vendor_label: 'Vendor',
			table_column_email_label: 'E-mail',
			table_column_phone_label: 'Phone',
			table_column_joined_on_label: 'Joined On',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			table_column_status_label: 'Invitation status',
			table_column_accepted_label: 'Accepted',
			table_column_pending_label: 'Pending',
			table_column_resend_invitation_tooltip_title: 'Resend Invitation',
			table_column_resend_invitation_dialog_title: 'Resend Invitation',

			table_action_column_deactivate_tooltip_title: 'Deactivate',
			table_action_column_active_tooltip_title: 'Activate',

			filter_active_status_label: 'Active Status',
			table_filter_search_by_name_email_phone_label: 'Search by Name or E-mail'
		},

		surgeon_table: {
			add_user_button_label: 'Add Surgeon',
			table_column_name_label: 'Name',
			table_column_email_label: 'E-mail',
			table_column_handle_label: 'Username',
			table_column_phone_label: 'Phone',
			table_column_specialities_label: 'Specialities',
			table_column_device_info_label: 'Device Info',
			table_column_joining_date_label: 'Joined On',
			table_column_last_login_label: 'Last login',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',
			table_column_status_label: 'Invitation status',
			table_column_pending_label: 'Pending',
			table_column_accepted_label: 'Accepted',

			table_action_column_deactivate_tooltip_title: 'Deactivate',
			table_action_column_active_tooltip_title: 'Activate',

			filter_active_status_label: 'Active Status',
			table_filter_search_by_name_email_phone_label: 'Search by Name or E-mail'
		}
	},

	content_management: {
		content_management_posts: {
			table_column_content: 'Content',
			table_column_owner: 'Owner',
			table_column_number_of_users: 'Number of Users',
			table_column_status: 'Status',
			table_column_contact_info_label: 'Contact Info',
			table_column_reported_by_label: 'Reported By',
			table_column_actions_label: 'Actions',
			table_column_restore_label: 'Review',
			table_column_delete_label: 'Delete',
			table_column_restore_dialog_title_label: 'Review Post',
			table_column_delete_dialog_title_label: 'Delete Post',
			table_column_Comments_restore_dialog_title_label: 'Restore Comment',
			table_column_Comments_delete_dialog_title_label: 'Delete Comment',
			table_column_post_details_dialog_title_label: 'Post Details',
			table_column_comments_details_dialog_title_label: 'Comments Details',
			post_restore_success_message: 'Post restored successfully',
			post_delete_success_message: 'Post delete successfully',
			comment_restore_success_message: 'Comment restored successfully',
			comment_delete_success_message: 'Comment delete successfully'
		},
		content_management_users: {
			table_column_name: 'Name',
			table_column_email: 'E-mail',
			table_column_handle: 'Username',
			table_column_reported_by_label: 'Reported By',
			table_column_actions_label: 'Actions',
			table_column_enable_label: 'Enable',
			table_column_disable_label: 'Disable',
			dialog_title_enable_label: 'Enable',
			dialog_title_disable_label: 'Disable',
			dialog_title_enable_message: 'Are you sure you want to enable this user?',
			dialog_title_disable_message: 'Are you sure you want to disable this user?'
		}
	},

	product: {
		add_product: {
			form_title_add_product_label: 'Add Product',
			form_title_update_product_label: 'Update Product',
			form_field_name_label: 'Name',
			form_field_overview_label: 'Overview',
			form_field_description_label: 'Description',
			form_field_link_label: 'Link',
			form_field_image_label: 'Image',
			form_field_uploaded_media_label: 'Uploaded Files',
			form_field_category_label: 'Category',
			form_field_IRS_label: 'Select Industry Reps',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_product_success_message: 'Product added successfully',
			update_product_success_message: 'Product details updated successfully'
		},

		product_table: {
			add_product_button_label: 'Add Product',
			table_column_name_label: 'Name',
			table_column_overview_label: 'Overview',
			table_column_category_label: 'Category',
			table_column_IRS_label: 'Associated IRs',
			table_column_no_of_mentions_label: 'No of Mentions',
			table_action_column_edit_tooltip_title: 'Edit',
			table_column_action_label: 'Action',

			table_filter_product_category_label: 'Category',

			product_disabled_tooltip_title: 'Disable',
			product_enabled_tooltip_title: 'Enable',

			product_disable_success_message: 'Product disabled successfully',
			product_enable_success_message: 'Product enabled successfully'
		},

		product_details: {
			page_header_label: 'Product details',

			product_field_name_label: 'Name',
			product_field_overview_label: 'Overview',
			product_field_description_label: 'Description',
			product_field_link_label: 'Link',
			product_field_category_label: 'Category',
			product_field_IRS_label: 'Associated Industry Reps',
			product_field_media_label: 'Media',
			product_field_media_preview_button_label: 'Preview',
			product_field_no_of_mentions_label: 'No of Mentions',
			product_field_preview_label: 'Preview',
			product_field_close_button_label: 'Close',
			product_field_column_edit_tooltip_title: 'Edit'
		}
	},

	vendor: {
		add_vendor: {
			form_title_add_vendor_label: 'Add Vendor',
			form_title_update_vendor_label: 'Update Vendor',
			form_field_name_label: 'Name',
			form_field_title_label: 'Title',
			form_field_description_label: 'Description',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_vendor_success_message: 'Vendor invited successfully',
			update_vendor_success_message: 'Vendor details updated successfully'
		},
		vendor_table: {
			table_header_label: 'Vendor Dashboard',
			add_vendor_button_label: 'Add Vendor',
			filter_vendor_name_label: 'Vendor',
			filter_publication_status_label: 'Publication Status',
			filter_active_status_label: 'Active Status',
			table_column_name_label: 'Name',
			table_column_date_of_page_creation_label: 'Joining Date',
			table_column_number_of_admins_label: 'Admins',
			table_column_number_of_irs_label: 'IRs',
			table_column_publication_status_label: 'Publication Status',
			table_column_published_status_label: 'Published',
			table_column_unpublished_label: 'UnPublished',
			table_column_activation_status_label: 'Activation Status',
			table_column_enabled_status_label: 'Enabled',
			table_column_disabled_label: 'Disabled',
			table_column_number_of_product_label: 'Products',
			table_column_number_of_mentions_label: 'Mentions',
			table_action_column_view_tooltip_title: 'View',
			table_action_column_edit_tooltip_title: 'Edit'
		},

		vendor_details: {
			tab_name_vendor_users: 'Users',
			tab_name_products: 'Products',
			tab_name_activity: 'Activity',

			vendor_publish_tooltip_title: 'Publish',
			vendor_published_tooltip_title: 'Published',
			vendor_unpublish_tooltip_title: 'UnPublish',

			vendor_enable_tooltip_title: 'Enable',
			vendor_disable_tooltip_title: 'Disable',

			vendor_edit_tooltip_title: 'Edit',
			vendor_back_tooltip_title: 'Back',

			table_filter_date_label: 'Filter by Date',

			vendor_activate_success_message: 'Vendor activated successfully',
			vendor_deactivate_success_message: 'Vendor deactivated successfully',

			vendor_publish_success_message: 'Vendor published successfully',
			vendor_unpublish_success_message: 'Vendor unpublished successfully'
		}
	},

	product_category: {
		add_product_category: {
			form_title_add_category_label: 'Add Product Category',
			form_title_update_category_label: 'Update Product Category',
			form_field_name_label: 'Name',
			form_field_image_label: 'Image',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_product_category_success_message: 'Product category added successfully',
			update_product_category_success_message: 'Product category details updated successfully'
		},
		product_category_table: {
			table_header_label: 'Product Categories',
			add_product_category_button_label: 'Add Product Category',
			table_column_name_label: 'Name',
			table_column_associated_products_label: 'Associated Products',
			table_column_image_label: 'Image',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			category_deactivate_tooltip_title: 'Deactivate',
			category_activate_tooltip_title: 'Activate',

			category_disable_success_message: 'Category disabled successfully',
			category_enable_success_message: 'Category enabled successfully',

			category_disabled_tooltip_title: 'Disable',
			category_enabled_tooltip_title: 'Enable'
		},
		product_category_details: {
			title_category_details_label: 'Category Details',
			name_label: 'Name',
			product_label: 'Associated Products',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	community_category: {
		add_community_category: {
			form_title_add_category_label: 'Add Community Category',
			form_title_update_category_label: 'Update Community Category',
			form_field_name_label: 'Name',
			form_field_image_label: 'Image',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_community_category_success_message: 'Community category added successfully',
			update_community_category_success_message: 'Community category details updated successfully'
		},
		community_category_table: {
			table_header_label: 'Community Categories',
			add_community_category_button_label: 'Add Community Category',
			table_column_name_label: 'Name',
			table_column_image_label: 'Image',
			table_column_associated_communities_label: 'Associated Communities',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			category_deactivate_tooltip_title: 'Deactivate',
			category_activate_tooltip_title: 'Activate',

			category_disable_success_message: 'Category disabled successfully',
			category_enable_success_message: 'Category enabled successfully',

			category_disabled_tooltip_title: 'Disable',
			category_enabled_tooltip_title: 'Enable'
		},
		community_category_details: {
			title_category_details_label: 'Category Details',
			name_label: 'Name',
			community_label: 'Associated Commuity',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	conference: {
		conference_table: {
			navigate_user_to_new_ui: 'Try out the new look',
			navigate_user_to_web_v2: 'Web V2',
			navigate_user_to_conference_module_ui: 'Try out the conference module',
			add_conference_button_label: 'Add Conference',

			conference_name: 'Conference Name',
			conference_handle: 'Handle',
			conference_start_date: 'Start Date',
			conference_end_date: 'End Date',
			conference_status: 'Status',
			conference_timezone: 'Timezone',
			conference_speaker_count: 'Speaker Count',
			conference_attendee_count: 'Attendees Count',
			conference_host_count: 'Host Count'
		},
		conference_details: {
			tab_name_sessions: 'Sessions',
			tab_name_announcements: 'Announcements',
			tab_name_media: 'Media',
			tab_name_associates: 'Associates',
			tab_name_supplements: 'Supplements',

			action_start_label: 'Start',
			action_end_label: 'End',
			action_publish_label: 'Publish',
			action_push_update: 'Force update'
		},
		conference_session_details: {
			action_edit_label: 'Edit',
			action_delete_label: 'Delete',
			tab_name_presentations: 'Presentations',
			tab_name_media: 'Media',
			tab_name_associates: 'Associates'
		},
		conference_presentation_details: {
			action_edit_label: 'Edit',
			action_delete_label: 'Delete',
			tab_name_media: 'Media',
			tab_name_associates: 'Associates'
		},
		conference_announcement_table: {
			add_conference_announcement_button_label: 'Add Announcement',
			announcement_title: 'Announcement Title',
			announcement_visibility: 'Send To',
			announcement_description: 'Announcement Description',
			announcement_created_on: 'Created On'
		},
		conference_session_table: {
			add_conference_session_button_label: 'Add Session',

			session_title: 'Session Title',
			session_description: 'Session Description',
			session_status: 'Status',
			session_starts_at: 'Starts At',
			session_ends_at: 'Ends At',
			session_actions: 'Actions'
		},
		conference_associate_table: {
			add_conference_associate_button_label: 'Add Associate',
			add_conference_attendee_button_label: 'Create Attendee',
			bulk_import_conference_associate_button_label: 'Bulk Import Associate',

			associate_name: 'Name',
			associate_email: 'Email',
			associate_institution_name: 'Institution Name',
			associate_academic_title: 'Academic Title',
			associate_added_on: 'Added On',
			associate_actions: 'Actions',
			associate_type: 'Associate Type'
		},
		conference_presentation_table: {
			add_conference_presentation_button_label: 'Add Presentation',

			presentation_title: 'Presentation Title',
			presentation_description: 'Presentation Description',
			presentation_starts_at: 'Starts At',
			presentation_ends_at: 'Ends At',
			presentation_actions: 'Actions'
		},
		session_delete_confirmation_dialog: {
			title: 'Delete Session',
			action_name: 'Delete',
			description: 'Do you really want to delete %{sessionName} Session?'
		},
		presentation_delete_confirmation_dialog: {
			title: 'Delete Presentation',
			action_name: 'Delete',
			description: 'Do you really want to delete %{presentationName} Presentation?'
		},
		associate_delete_confirmation_dialog: {
			title: 'Delete Associate',
			action_name: 'Delete',
			description: 'Do you really want to delete %{associateType} - %{associateName} as an Associate?'
		},
		supplement_delete_confirmation_dialog: {
			title: 'Delete Supplement',
			action_name: 'Delete',
			description: 'Do you really want to delete %{supplementType} - %{supplementName} as a Supplement?'
		},
		conference_supplemets_tab: {
			add_conference_supplements_button_label: 'Add Supplements',
			supplement_table: {
				position: 'Position',
				title: 'Title',
				description: 'Description',
				actions: 'Actions'
			}
		},
		conference_media_tab: {
			add_conference_media_button_label: 'Add Media',
			cover_image: 'Cover Images',
			media_assets: 'Media',
			profile_image: 'Profile Image',
			email_banner_image: 'Email Banner Image',
			venue_map: 'Venue Map',
			resources: 'Resources',
			no_media: 'No media for this section',

			conference_media_table: {
				title: 'Title',
				actions: 'Actions'
			}
		},
		add_announcement: {
			form_title_create_annoucement_label: 'Create Announcement',
			form_title_view_annoucement_label: 'Announcement Details',
			form_field_announcement_visibility_label: 'Send To',
			form_field_title_label: 'Title',
			form_field_description_label: 'Description',
			form_field_body_label: 'Body',
			form_field_announcement_registrationType_label: 'Attendee registration types',
			form_field_announcement_filter_label: 'Send announcements based on',
			form_field_announcement_associate_type_label: 'Conference roles'
		},
		add_associate: {
			form_title_create_associate_label: 'Create Associate',
			form_title_view_associate_label: 'Associate Details',
			form_field_user_label: 'Select Users',
			form_field_type_label: 'Associate Type'
		},
		add_conferenceOrganizer: {
			form_title_update_conferenceOrganizer_label: 'Update conference organizer ',
			form_title_create_conferenceOrganizer_label: 'Add a conference organizer',
			form_field_user_label: 'Select user',
			form_field_conference_label: 'Select conference'
		},
		add_attendee: {
			form_title_create_attendee_label: 'Create Attendee',
			form_field_name_label: 'Name',
			form_field_email_label: 'Email',
			form_field_bio_label: 'Bio',
			form_field_academic_title_label: 'Academic Title',
			form_field_institution_name_label: 'Institution Name',
			form_field_city_label: 'City, State of Operation',
			form_field_isIr_label: 'Is IR',
			form_field_should_skip_npi_validation_label: 'Skip NPI Validation',
			form_field_should_send_invite_email_label: 'Should send invite email',
			form_field_vendor_name_label: 'Vendor Name',
			form_field_profile_image_url_label: 'Profile Image URL'
		},
		bulk_upload_associate: {
			form_title_bulk_upload_associate_label: 'Bulk Import Associate',
			form_content_bulk_upload_template_text: `Download template file for bulk attendees upload: `,
			form_field_filepath_label: 'S3 filepath',
			form_upload_button_label: 'Import'
		},
		add_session: {
			form_title_create_session_label: 'Create Session',
			form_title_update_session_label: 'Update Session',
			form_title_view_session_label: 'Session Details',
			form_field_name_label: 'Name',
			form_field_description_label: 'Description',
			form_field_venue_name_label: 'Venue Name',
			form_field_tracks_label: 'Tracks',
			form_field_registrationTypes_label: 'User Registration Types',
			form_field_start_datetime_label: 'Start Datetime',
			form_field_end_datetime_label: 'End Datetime',
			form_field_is_live_session_label: 'Is Session Live',
			form_field_live_session_url_label: 'Live Session URL',
			form_field_availability_post_conference_label: 'Availability Post Conference',
			form_field_visibility_post_conference_label: 'Visibility Post Conference',
			form_field_callToActionLabel: 'Call To Action Label',
			form_field_callToActionLink: 'Call To Action Link',
			form_field_callToActionEndedVisibility: 'Call To Action Visibility After Conference Ends'
		},
		add_conference: {
			form_title_create_conference_label: 'Create Conference',
			form_title_update_conference_label: 'Update Conference',
			form_field_name: 'Name',
			form_field_short_name: 'Short Name',
			form_field_handle: 'Handle',
			form_field_description: 'Description',
			form_field_start_date_label: 'Start Date*',
			form_field_end_date_label: 'End Date*',
			form_field_address_venue_name: 'Venue Name',
			form_field_address_street: 'Street',
			form_field_address_city: 'City',
			form_field_address_state: 'State',
			form_field_address_zip: 'Zip',
			form_field_address_country: 'Country',
			form_field_geo_loc_lat: 'Latitude',
			form_field_geo_loc_long: 'Longitude',
			form_field_timezone: 'Timezone*',
			form_field_type: 'Conference Type',
			form_field_visibility: 'Conference Visibility',
			form_field_is_guided_conference: 'Guided conference?',
			form_field_ended_visibility: 'Ended Conference Visibility',
			form_field_venue_map_doc_url: 'Venue Map Document URL',
			form_field_social_link_twitter: 'Twitter Link',
			form_field_social_link_website: 'Website Link',
			form_field_callToActionLabel: 'Call To Action Button Label',
			form_field_callToActionLink: 'Call To Action Button Link',
			form_field_callToActionEndedVisibility: 'Call To Action Visibility After Conference Ends'
		},
		add_presentation: {
			form_title_create_presentation_label: 'Create Presentation',
			form_title_update_presentation_label: 'Update Presentation',
			form_title_view_presentation_label: 'Presentation Details',
			form_field_name_label: 'Name',
			form_field_description_label: 'Description',
			form_field_start_datetime_label: 'Start Datetime',
			form_field_end_datetime_label: 'End Datetime'
		},
		add_media: {
			form_title_create_media_label: 'Create Media',
			form_title_update_media_label: 'Update Media',
			form_field_title_label: 'Title',
			form_field_type_label: 'Type',
			form_field_description_label: 'Description',
			table_filename_label: 'Filename',
			table_mime_label: 'Mime',
			table_size_label: 'Size',
			table_action_label: 'Action'
		},
		add_supplement: {
			form_title_create_supplement_label: 'Create Supplement',
			form_title_update_supplement_label: 'Update Supplement',
			form_field_title_label: 'Title',
			form_field_type_label: 'Type',
			form_field_position_label: 'Position',
			form_field_description_label: 'Description',
			table_link_label: 'Link',
			table_body_label: 'Body',
			table_image_url_label: 'Image URL'
		}
	},

	community: {
		add_community: {
			form_title_add_community_label: 'Add Community',
			form_title_update_community_label: 'Update Community',
			form_field_name_label: 'Name',
			form_field_description_label: 'Description',
			form_field_rules_label: 'Rules',
			form_field_category_label: 'Category',
			form_field_icon_label: 'Icon',
			form_field_cover_photo_label: 'Cover Photo',
			form_field_private_community: 'Private community',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_community_success_message: 'Community added successfully',
			update_community_success_message: 'Community details updated successfully',

			add_cover_photo_message: 'Your cover photo will go here'
		},

		community_table: {
			add_community_button_label: 'Add Community',
			table_column_icon_label: 'Icon',
			table_column_name_label: 'Community Name',
			table_column_category_label: 'Category',
			table_column_posts_label: 'Posts',
			table_column_members_label: 'Members',
			table_column_requesting_members_label: 'Requesting Members',
			table_column_favourite_label: 'Favorite',
			table_column_trending_rank_label: 'Trending Rank',
			table_column_active_status_label: 'Active Status',
			table_column_created_at_label: 'Created On',
			table_action_column_edit_tooltip_title: 'Edit',

			table_filter_community_category_label: 'Category',
			table_filter_active_status_label: 'Active Status',
			table_filter_community_type_label: 'Community Type',
			table_filter_name_label: 'Name',

			table_action_column_deactivate_tooltip_title: 'Disabled',
			table_action_column_active_tooltip_title: 'Activate'
		},

		community_details: {
			page_header_label: 'Community details',

			community_field_name_label: 'Name',
			community_field_statistics_label: 'Statistics',
			community_field_description_label: 'Description',
			community_field_rules_label: 'Rules',
			community_field_category_label: 'Category',
			community_field_icon_label: 'Icon',
			community_field_cover_photo_label: 'Cover Photo',
			community_field_media_preview_button_label: 'Preview',
			community_field_preview_label: 'Preview',
			community_field_close_button_label: 'Close',
			community_field_column_edit_tooltip_title: 'Edit',

			table_filter_date_label: 'Filter by Date',

			tab_name_posts: 'Posts',
			tab_name_members: 'Members',
			tab_name_activity: 'Activity',
			tab_name_about: 'About',
			tab_name_requested_members: 'Requested Members',

			no_details_available_message: 'No details available',

			table_action_column_deactivate_tooltip_title: 'Disabled Now',
			table_action_column_active_tooltip_title: 'Enabled Now',

			community_disable_success_message: 'Community disabled successfully',
			community_enable_success_message: 'Community enabled successfully'
		},

		members: {
			community_field_name_label: 'Name',
			community_field_email_label: 'E-mail',
			community_field_phone_label: 'Mobile Number',
			community_field_favourite_label: 'Favorite',
			community_field_joining_date_label: 'Joined On',

			table_filter_favourite_label: 'Favorite',
			table_filter_joining_date_label: 'Joining Date',
			table_filter_search_by_name_email_label: 'Search by Name or E-mail'
		},
		requested_members: {
			requested_members_name_label: 'Name',
			requested_members_npi: 'NPI',
			requested_members_npi_name: 'NPI Name',
			last_active_platform: 'Last Active Platform',
			requested_members_requested_date_label: 'Requested On',
			requested_members_actions: 'Actions',
			requested_members_accept_success_toast_message: 'Success accepting user community join request',
			requested_members_accept_failed_toast_message: 'Failed accepting user community join request',
			requested_members_reject_success_toast_message: 'Success rejecting user community join request',
			requested_members_reject_failed_toast_message: 'Failed rejecting user community join request',
			reject_dialog: {
				title: 'Reject Membership Request',
				action_name: 'Reject',
				field_label_rejection_reason: 'Reason for rejection'
			}
		}
	},

	diagnosis: {
		add_diagnosis: {
			form_title_add_diagnosis_label: 'Add Diagnosis',
			form_title_update_diagnosis_label: 'Update Diagnosis',
			form_field_name_label: 'Name',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_diagnosis_success_message: 'Diagnosis added successfully',
			update_diagnosis_success_message: 'Diagnosis details updated successfully'
		},
		diagnosis_table: {
			table_header_label: 'Diagnosis',
			add_diagnosis_button_label: 'Add Diagnosis',
			table_column_name_label: 'Name',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			diagnosis_deactivate_tooltip_title: 'Deactivate',
			diagnosis_activate_tooltip_title: 'Activate',

			diagnosis_disable_success_message: 'Diagnosis disabled successfully',
			diagnosis_enable_success_message: 'Diagnosis enabled successfully',

			diagnosis_disabled_tooltip_title: 'Disable',
			diagnosis_enabled_tooltip_title: 'Enable'
		}
	},

	feed: {
		post: {
			add_post_success_message: 'Post added successfully',
			update_post_success_message: 'Post updated successfully',
			save_post_success_message: 'Post saved successfully',
			unsave_post_success_message: 'Post removed from saved',
			report_post_success_message: 'Post reported successfully',
			delete_post_success_message: 'Post deleted successfully'
		}
	},

	post_details: {
		not_found: 'Post no longer available',
		comment_report: {
			report_comment_success_message: 'Comment reported successfully',
			report_comment_already_reported_message: 'Comment already reported'
		},
		comment_delete: {
			delete_comment_success_message: 'Comment deleted successfully'
		}
	},

	speciality: {
		add_speciality: {
			form_title_add_speciality_label: 'Add Speciality',
			form_title_update_speciality_label: 'Update Speciality',
			form_field_name_label: 'Name',
			form_field_domain_label: 'Domain',
			form_field_selected_domain_label: 'Selected Domains',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_speciality_success_message: 'Speciality added successfully',
			update_speciality_success_message: 'Speciality details updated successfully'
		},
		speciality_table: {
			table_header_label: 'Speciality',
			add_speciality_button_label: 'Add Speciality',
			table_column_name_label: 'Name',
			table_column_domain_label: 'Domain',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			speciality_deactivate_tooltip_title: 'Deactivate',
			speciality_activate_tooltip_title: 'Activate',

			speciality_disable_success_message: 'Speciality disabled successfully',
			speciality_enable_success_message: 'Speciality enabled successfully',

			speciality_disabled_tooltip_title: 'Disable',
			speciality_enabled_tooltip_title: 'Enable'
		},
		speciality_details: {
			title_speciality_details_label: 'Speciality Details',
			name_label: 'Name',
			domain_label: 'Domains',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	domain: {
		add_domain: {
			form_title_add_domain_label: 'Add Domain',
			form_title_update_domain_label: 'Update Domain',
			form_field_name_label: 'Name',
			form_field_speciality_label: 'Speciality',
			form_field_speakers_label: 'Speakers',
			form_field_selected_speciality_label: 'Selected Specialities',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_domain_success_message: 'Domain added successfully',
			update_domain_success_message: 'Domain details updated successfully'
		},
		domain_table: {
			table_header_label: 'Domain',
			add_domain_button_label: 'Add Domain',
			table_column_name_label: 'Name',
			table_column_speciality_label: 'Speciality',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			domain_deactivate_tooltip_title: 'Deactivate',
			domain_activate_tooltip_title: 'Activate',

			domain_disable_success_message: 'Domain disabled successfully',
			domain_enable_success_message: 'Domain enabled successfully',

			domain_disabled_tooltip_title: 'Disable',
			domain_enabled_tooltip_title: 'Enable'
		},
		domain_details: {
			title_domain_details_label: 'Domain Details',
			name_label: 'Name',
			speciality_label: 'Specialities',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	procedure: {
		add_procedure: {
			form_title_add_procedure_label: 'Add Procedure',
			form_title_update_procedure_label: 'Update Procedure',
			form_field_name_label: 'Name',
			form_field_domain_label: 'Domain',
			form_field_selected_domain_label: 'Selected Domains',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_procedure_success_message: 'Procedure added successfully',
			update_procedure_success_message: 'Procedure details updated successfully'
		},
		procedure_table: {
			table_header_label: 'Procedure',
			add_procedure_button_label: 'Add Procedure',
			table_column_name_label: 'Name',
			table_column_domain_label: 'Domain',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			procedure_deactivate_tooltip_title: 'Deactivate',
			procedure_activate_tooltip_title: 'Activate',

			procedure_disable_success_message: 'Procedure disabled successfully',
			procedure_enable_success_message: 'Procedure enabled successfully',

			procedure_disabled_tooltip_title: 'Disable',
			procedure_enabled_tooltip_title: 'Enable'
		},
		procedure_details: {
			title_procedure_details_label: 'Procedure Details',
			name_label: 'Name',
			domain_label: 'Domain',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	approach: {
		add_approach: {
			form_title_add_approach_label: 'Add Approach',
			form_title_update_approach_label: 'Update Approach',
			form_field_name_label: 'Name',
			form_field_procedure_label: 'Procedure',
			form_field_selected_procedure_label: 'Selected Procedures',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_approach_success_message: 'Approach added successfully',
			update_approach_success_message: 'Approach details updated successfully'
		},
		approach_table: {
			table_header_label: 'Approach',
			add_approach_button_label: 'Add Approach',
			table_column_name_label: 'Name',
			table_column_procedure_label: 'Procedure',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			approach_deactivate_tooltip_title: 'Deactivate',
			approach_activate_tooltip_title: 'Activate',

			approach_disable_success_message: 'Approach disabled successfully',
			approach_enable_success_message: 'Approach enabled successfully',

			approach_disabled_tooltip_title: 'Disable',
			approach_enabled_tooltip_title: 'Enable'
		},
		approach_details: {
			title_approach_details_label: 'Approach Details',
			name_label: 'Name',
			procedure_label: 'Procedures',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	actualStep: {
		add_actualStep: {
			form_title_add_actualStep_label: 'Add Actual Step',
			form_title_update_actualStep_label: 'Update Actual Step',
			form_field_name_label: 'Name',
			form_field_procedure_label: 'Procedure',
			form_field_selected_procedure_label: 'Selected Procedures',

			form_add_button_label: 'Add',
			form_update_button_label: 'Update',
			form_cancel_button_label: 'Cancel',

			add_actualStep_success_message: 'Actual step added successfully',
			update_actualStep_success_message: 'Actual step details updated successfully'
		},
		actualStep_table: {
			table_header_label: 'Actual Step',
			add_actualStep_button_label: 'Add Actual Step',
			table_column_name_label: 'Name',
			table_column_procedure_label: 'Procedure',
			table_column_action_label: 'Action',
			table_action_column_edit_tooltip_title: 'Edit',

			actualStep_deactivate_tooltip_title: 'Deactivate',
			actualStep_activate_tooltip_title: 'Activate',

			actualStep_disable_success_message: 'Actual step disabled successfully',
			actualStep_enable_success_message: 'Actual step enabled successfully',

			actualStep_disabled_tooltip_title: 'Disable',
			actualStep_enabled_tooltip_title: 'Enable'
		},
		actualStep_details: {
			title_actualStep_details_label: 'Actual Step Details',
			name_label: 'Name',
			procedure_label: 'Procedures',
			edit_button_label: 'edit',
			close_button_label: 'Close'
		}
	},

	surgeon_dashboard: {
		tab_view: {
			tab_name_feed_label: 'Feed',
			tab_name_trending_label: 'Trending',
			tab_name_live_label: 'Live',
			tab_name_events_label: 'Events',
			account_setip_pending_message: 'Your account setup is pending. Please complete your profile and community selection to view a customised feed for you in the browser.'
		},
		message_receive: '%{name} sent a message',
		no_conference_sessions_found: 'No Active Sessions Found'
	},

	surgeon_profile: {
		surgeon_profile_block_message: '@%{handle} is blocked',
		surgeon_profile_block_description: 'This profile has been blocked by you! If you want to unblock @%{handle}, tap below.',
		surgeon_profile_unblock_user_button_label: 'Unblock user',

		impact: 'Impact',
		posts: 'Posts',
		mentions: 'Mentions',
		communities: 'Communities',
		dv_cases: 'dV Cases',

		menu_items: {
			block_user: 'Block user',
			report_user: 'Report user',
			edit_user: 'Edit profile'
		},

		connectIntuitiveButton: {
			connectToIntuitive: 'Connect to Intuitive',
			disconnectFromIntuitive: 'Disconnect from Intuitive',
			connectedFromIntuitive: 'Connected to Intuitive'
		},

		tab_view: {
			tab_name_about: 'About',
			tab_name_collections: 'Collections',
			tab_name_posts: 'Posts',
			tab_name_comments: 'Comments',
			tab_name_mentions: 'Mentions',
			tab_name_webinars: 'Webinars'
		},

		about_tab: {
			specialty: 'Specialty',
			specialities_supported: 'Specialities Supported',
			education_and_training: 'Education and Training',
			hospitals: 'Hospitals',
			about: 'About',
			website: 'Website'
		},

		user_block_dialog: {
			dialog_title_label: 'Block user',
			dialog_content_message: 'You’re about to block  @%{handle}  account',
			dialog_action_button_label: 'Block User',
			you_will_no_longer_be_able_to_message: 'You will no longer be able to: ',
			message_1: 'See their posts',
			message_2: 'See their comments',
			user_block_success_message: 'User block successfully',
			user_unblock_success_message: 'User unblock successfully'
		},

		user_report_dialog: {
			dialog_title_label: 'Report user',
			dialog_content_message: 'Why are you reporting this user? Select the most approriate category below so we can moderate approriately.',
			dialog_content_option_message: 'This is a violation of our content policy, which for reference states:',
			dialog_content_selected_message: 'You’re about to report @%{handle} account for %{optionName}.',
			dialog_action_button_label: 'Submit Report',
			user_report_success_message: 'User reported successfully'
		},

		post_report_dialog: {
			dialog_title_label: 'Report post',
			dialog_content_message: 'Why are you reporting this content? Select the most approriate category below so we can moderate approriately.',
			dialog_content_option_message: 'This is a violation of our content policy, which for reference states:',
			dialog_content_selected_message: 'You’re about to submit a report about @%{handle} content for %{optionName}.',
			dialog_action_button_label: 'Submit Report',
			user_report_success_message: 'User reported successfully'
		}
	},

	posts: {
		create_post: {
			create_a_post_label: 'Create a post',
			dialog_title_create_post: 'Create post',
			dialog_tttle_edit_post: 'Edit post',

			form_field_choose_a_community_label: 'Choose a community',
			form_field_post_title_label: 'Post title',
			form_field_post_poll_title_label: 'Ask a question...',
			form_field_post_poll_days_dropdown_singular: '%{count} day',
			form_field_post_poll_days_dropdown: '%{count} days',
			form_field_post_poll_days_dropdown_empty: 'Poll duration',
			form_field_post_description_label: 'Description (Optional)',
			form_field_tags_label: 'Tags',
			form_field_add_search_tags_label: 'Add search tags',

			form_field_live_post_label: 'Live post',
			form_field_poll_post_label: 'Poll',
			form_field_event_date_label: 'Event date',
			form_field_event_start_time_label: 'Event start time',
			form_field_event_end_time_label: 'Event end time',
			form_field_live_streaming_url_label: 'Live streaming url',
			form_field_live_streaming_start_date: 'Scheduled At',
			form_field_media_event_recording_label: 'Media link of event recording',
			form_field_thumbnail_image_label: 'Thumbnail image',
			form_field_speakers_label: 'Speakers',
			form_field_moderators_label: 'Moderators',
			form_field_timezone: 'Timezone',

			form_field_speciality_label: 'Speciality',
			form_field_domain_label: 'Domain',
			form_field_procedure_label: 'Procedure',
			form_field_approach_label: 'Approach',
			form_field_operative_steps_label: 'Operative steps',
			form_field_diagnosis_label: 'Diagnosis',
			form_post_button_label: 'Post'
		}
	},

	notification: {
		page_header_title: 'Notifications',
		new_notification_title: 'NEW',
		earlier_notification_title: 'EARLIER',
		no_notifications_available_message: 'No notifications available',
		redirect_to_mobile: 'Please log into the mobile app to check this notification'
	},

	message: {
		page_header_title: 'Messages',
		new_notification_title: 'NEW',
		earlier_notification_title: 'EARLIER',
		no_messages_available_message: 'No messages available'
	},

	chatbox: {
		select_a_user_placeholder: 'Search for people',
		type_a_message_placeholder: 'Type message',
		private_conversation_label: 'This conversation is private',
		chatbox_blocked_by_sender_message: 'This person is unavailable on Messenger.',
		chatbox_blocked_by_me_message: 'You’ve blocked this person. To unblock, visit their profile.'
	},

	surgeon_communities: {
		title: 'Communities',
		all_communities: 'All communities',
		trending_communities_title: 'Trending Communities',
		favourite_communities_title: 'Favorite Communities',
		my_communities_title: 'My Communities',
		community_join_action_button_label: 'Join',
		community_joined_action_button_label: 'Joined',
		community_mark_favourite_success_message: 'Community marked as a favorite successfully',
		community_remove_mark_favourity_success_message: 'Community removed from favorite successfully',
		community_subscribe_success_message: 'Community subscribe successfully',
		community_unsubscribe_success_message: 'Community unsubscribe successfully',
		community_unsubscribe_failed_message: 'Community unsubscribe failed',
		community_revoke_success_message: 'Cancel join request success',
		community_revoke_failed_message: 'Cancel join request failed',
		tab_view: {
			tab_name_feed_label: 'Feed',
			tab_name_trending_label: 'Trending',
			tab_name_live_label: 'Live',
			account_setip_pending_message: 'Your account setup is pending. Please complete your profile and community selection to view a customised feed for you in the browser.'
		},
		message_receive: '%{name} sent a message',

		community_details: {
			tab_name_posts: 'Posts',
			tab_name_live: 'Live',
			tab_name_members: 'Members',
			tab_name_about: 'About',
			community_details_tab_about_rules_header: 'Rules',
			about_section_private: 'Private',
			about_section_managed: 'Private Managed',
			about_section_private_details: 'Only members can see who’s in the community and what they post.',
			about_section_private_managed: 'Only members can see who’s in the group and what they post.',
			about_section_public: 'Public',
			about_section_public_details: 'Any one can see who’s in the community and what they post.',
			about_section_visible: 'Visible',
			about_section_visible_detail: 'Anyone can find this group',
			about_section_visible_detail_private: 'Anyone can find this group',
			about_section_visible_detail_managed: 'Only the members can find this group',

			about_section_history: 'History',
			about_section_history_detail: 'Group created on %{createdDate}.',
			about_section_rules: 'Rules from the admins',
			menu_item_leave_community: 'Leave community',
			menu_item_revoke_join_request: 'Cancel request'
		},

		leave_community_dialog: {
			title: 'Leave community',
			action_name: 'Leave',
			description: 'Do you really want to leave %{communityName} community?'
		},

		revoke_community_join_dialog: {
			title: 'Cancel community join request',
			action_name: 'Cancel Request',
			description: 'Do you really want to cancel join %{communityName} community request?'
		},

		all_community: {
			all_community_page_title: 'All Communities',
			all_community_search_field_label: 'Searching for',
			all_community_join_button_label: 'Join',
			all_community_private_label: 'Private',
			all_community_in_review_button_label: 'In review',
			all_community_joined_button_label: 'Joined'
		}
	}
};

export default en;
