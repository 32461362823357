import { BACKGROUND_UPLOAD_COMMENT, CREATE_COMMENT_PART, SET_COMMENT_PROGRESS, UNSET_COMMENT } from './BackgroundUploadActions';

const initialState = {
	backgroundUploadComment: undefined
};

/**
 * Surgeonlandingpage reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const BackgroundUploadCommentReducer = (state = initialState, action) => {
	switch (action.type) {
		//Append newly created comment
		case BACKGROUND_UPLOAD_COMMENT:
			return Object.assign({}, state, {
				backgroundUploadComment: {
					comment: action.comment,
					media: action.comment.media,
					isUploadStart: false,
					totalMediaSize: action.totalMediaSize,
					totalUploadedMediaSize: 0,
					progress: 0
				}
			});

		//Create new part in particular file
		case CREATE_COMMENT_PART: {
			let tempBackgroundUploadComment = Object.assign({}, state.backgroundUploadComment);
			if (tempBackgroundUploadComment && tempBackgroundUploadComment.media && tempBackgroundUploadComment.media.length > 0) {
				tempBackgroundUploadComment.media = tempBackgroundUploadComment.media.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						const part = {
							partNumber: action.partNumber,
							presignedUrl: action.presignedUrl,
							totalPartSize: action.totalPartSize,
							uploadedPartSize: 0
						};
						if (singleMedia.parts.length > 0) {
							const findPart = singleMedia.parts.find((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPart === undefined) {
								singleMedia.parts.push(part);
							}
						} else {
							singleMedia.parts.push(part);
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});
				tempBackgroundUploadComment.isUploadStart = true;
			} else {
			}

			return Object.assign({}, state, {
				backgroundUploadComment: tempBackgroundUploadComment
			});
		}

		//Set comment progress of part in particular file
		case SET_COMMENT_PROGRESS: {
			let tempBackgroundUploadCommentForSetProcess = Object.assign({}, state.backgroundUploadComment);
			if (tempBackgroundUploadCommentForSetProcess && tempBackgroundUploadCommentForSetProcess.media && tempBackgroundUploadCommentForSetProcess.media.length > 0) {
				tempBackgroundUploadCommentForSetProcess.media = tempBackgroundUploadCommentForSetProcess.media.map((singleMedia) => {
					if (singleMedia.fileNumber === action.fileNumber) {
						if (singleMedia.parts.length > 0) {
							const findPartIndex = singleMedia.parts.findIndex((singlePart) => singlePart.partNumber === action.partNumber);
							if (findPartIndex > -1) {
								singleMedia.parts[findPartIndex].uploadedPartSize = action.uploadedSize;
							}
						} else {
						}
						return singleMedia;
					} else {
						return singleMedia;
					}
				});

				// sum of uploaded size of each file part
				const uploadedSizeForEachFile = tempBackgroundUploadCommentForSetProcess.media.map((item) => {
					return item.parts.reduce((sum, part) => sum + part.uploadedPartSize, 0);
				});

				// sum of uploaded size including all file
				const totalUploadedSize = uploadedSizeForEachFile.reduce((sum, eachFileSize) => sum + eachFileSize, 0);
				tempBackgroundUploadCommentForSetProcess.totalUploadedMediaSize = totalUploadedSize;
				const percentCompleted = Math.round((totalUploadedSize * 100) / tempBackgroundUploadCommentForSetProcess.totalMediaSize);
				tempBackgroundUploadCommentForSetProcess.progress = percentCompleted;
			} else {
			}

			return Object.assign({}, state, {
				backgroundUploadComment: tempBackgroundUploadCommentForSetProcess
			});
		}

		//Empty comment list
		case UNSET_COMMENT:
			return Object.assign({}, state, {
				backgroundUploadComment: undefined
			});

		default:
			return state;
	}
};

export default BackgroundUploadCommentReducer;
