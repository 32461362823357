// Export Constants (Action names)
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const GET_ALL_PROFILE_POST = 'GET_ALL_PROFILE_POST';
export const GET_ALL_USER_COMMENTS = 'GET_ALL_USER_COMMENTS';
export const GET_ALL_MENTIONS_COMMENTS = 'GET_ALL_MENTIONS_COMMENTS';
export const EMPTY_PROFILE_POST_LIST = 'EMPTY_PROFILE_POST_LIST';
export const EMPTY_ALL_USER_COMMENTS = 'EMPTY_ALL_USER_COMMENTS';
export const EMPTY_ALL_MENTIONS_COMMENTS = 'EMPTY_ALL_MENTIONS_COMMENTS';

/**
 * Action called after get single profileData
 * @param profileData
 */
export const getUserProfileData = (profileData) => {
	return {
		type: PROFILE_UPDATE,
		profileData: profileData
	};
};

/**
 * Action called after get all post
 * @param posts
 */
export const getAllPost = (posts, currentPage) => {
	return {
		type: GET_ALL_PROFILE_POST,
		posts: posts,
		currentPage: currentPage
	};
};

/**
 * Action called after tab change
 */
export const setPostListEmpty = () => {
	return {
		type: EMPTY_PROFILE_POST_LIST
	};
};

export const setCommentsListEmpty = () => {
	return { type: EMPTY_ALL_USER_COMMENTS };
};

export const setMentionsListEmpty = () => {
	return { type: EMPTY_ALL_MENTIONS_COMMENTS };
};

/**
 * Action called after get all user comments
 * @param comments
 */
export const getAllUserComments = (comments, currentPage) => {
	return {
		type: GET_ALL_USER_COMMENTS,
		comments: comments,
		currentPage: currentPage
	};
};

/**
 * Action called after get all mentions comments
 * @param comments
 */
export const getAllMentionsComments = (comments, currentPage) => {
	return {
		type: GET_ALL_MENTIONS_COMMENTS,
		comments: comments,
		currentPage: currentPage
	};
};
