/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_DIAGNOSIS = 'GET_ALL_DIAGNOSIS';
export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS';
export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS';
export const ACTIVATE_DEACTIVATE_DIAGNOSIS = 'ACTIVATE_DEACTIVATE_DIAGNOSIS';

/**
 * Action called after get all diagnosis
 * @param diagnosis
 */
export const getAllDiagnosis = (diagnosis) => {
	return {
		type: GET_ALL_DIAGNOSIS,
		diagnosis: diagnosis
	};
};

/**
 * Action called after create diagnosis
 * @param diagnosis
 */

export const createDiagnosis = (diagnosis) => {
	return {
		type: CREATE_DIAGNOSIS,
		diagnosis
	};
};

/**
 * Action called after update diagnosis details
 * @param diagnosis
 */

export const updateDiagnosis = (diagnosis) => {
	return {
		type: UPDATE_DIAGNOSIS,
		diagnosis
	};
};

/**
 * Action called after activate deactivate diagnosis
 * @param diagnosis
 */

export const activateDeactivateDiagnosis = (diagnosis) => {
	return {
		type: ACTIVATE_DEACTIVATE_DIAGNOSIS,
		diagnosis
	};
};
