/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_ACTUAL_STEP, CREATE_ACTUAL_STEP, GET_ALL_ACTUAL_STEPS, UPDATE_ACTUAL_STEP } from './ActualStepsManagementActions';

const initialState = {
	actualSteps: []
};

/**
 * Approach reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ActualStepsReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of actualSteps to be shown
		case GET_ALL_ACTUAL_STEPS:
			return Object.assign({}, state, {
				actualSteps: action.actualSteps
			});

		//Append newly created actualStep
		case CREATE_ACTUAL_STEP:
			return Object.assign({}, state, {
				actualSteps: [...state.actualSteps.rows, action.actualStep]
			});

		//Update actualStep details
		case UPDATE_ACTUAL_STEP: {
			let data = Object.assign({}, state.actualSteps);

			data.rows = data.rows.map((actualStep) => {
				if (actualStep.id === action.actualStep.id) {
					return action.actualStep;
				}
				return actualStep;
			});

			return Object.assign({}, state, {
				actualSteps: data
			});
		}

		//Update actualStep details enabled/disabled
		case ACTIVATE_DEACTIVATE_ACTUAL_STEP: {
			let data = Object.assign({}, state.actualSteps);
			data.rows = data.rows.map((actualStep) => {
				if (actualStep.id === action.actualStep.id) {
					actualStep.enabled = !actualStep.enabled;
					return actualStep;
					// return action.actualStep;
				}
				return actualStep;
			});

			return Object.assign({}, state, {
				actualSteps: data
			});
		}

		default:
			return state;
	}
};

export default ActualStepsReducer;
