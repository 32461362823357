/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_SPECIALITIES = 'GET_ALL_SPECIALITIES';
export const CREATE_SPECIALITY = 'CREATE_SPECIALITY';
export const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
export const ACTIVATE_DEACTIVATE_SPECIALITY = 'ACTIVATE_DEACTIVATE_SPECIALITY';

/**
 * Action called after get all specialities
 * @param specialities
 */
export const getAllSpecialities = (specialities) => {
	return {
		type: GET_ALL_SPECIALITIES,
		specialities: specialities
	};
};

/**
 * Action called after create speciality
 * @param speciality
 */

export const createSpeciality = (speciality) => {
	return {
		type: CREATE_SPECIALITY,
		speciality
	};
};

/**
 * Action called after update speciality details
 * @param speciality
 */

export const updateSpeciality = (speciality) => {
	return {
		type: UPDATE_SPECIALITY,
		speciality
	};
};

/**
 * Action called after activate deactivate speciality
 * @param speciality
 */

export const activateDeactivateSpeciality = (speciality) => {
	return {
		type: ACTIVATE_DEACTIVATE_SPECIALITY,
		speciality
	};
};
