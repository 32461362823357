/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_DOMAIN, CREATE_DOMAIN, GET_ALL_DOMAINS, UPDATE_DOMAIN } from './DomainManagementActions';

const initialState = {
	domains: []
};

/**
 * Domains reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const DomainsReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of domains to be shown
		case GET_ALL_DOMAINS:
			return Object.assign({}, state, {
				domains: action.domains
			});

		//Append newly created domain
		case CREATE_DOMAIN:
			return Object.assign({}, state, {
				domains: [...state.domains.rows, action.domain]
			});

		//Update domain details
		case UPDATE_DOMAIN: {
			let data = Object.assign({}, state.domains);

			data.rows = data.rows.map((domain) => {
				if (domain.id === action.domain.id) {
					return action.domain;
				}
				return domain;
			});

			return Object.assign({}, state, {
				domains: data
			});
		}
		case ACTIVATE_DEACTIVATE_DOMAIN: {
			let data = Object.assign({}, state.domains);
			data.rows = data.rows.map((domain) => {
				if (domain.id === action.domain.id) {
					domain.enabled = !domain.enabled;
					return domain;
					// return action.domain;
				}
				return domain;
			});

			return Object.assign({}, state, {
				domains: data
			});
		}

		default:
			return state;
	}
};

export default DomainsReducer;
