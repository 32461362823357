// Export Constants (Action names)
export const BACKGROUND_UPLOAD_POST = 'BACKGROUND_UPLOAD_POST';
export const CREATE_PART = 'CREATE_PART';
export const SET_PROGRESS = 'SET_PROGRESS';
export const UNSET_POST = 'UNSET_POST';

export const BACKGROUND_UPLOAD_COMMENT = 'BACKGROUND_UPLOAD_COMMENT';
export const CREATE_COMMENT_PART = 'CREATE_COMMENT_PART';
export const SET_COMMENT_PROGRESS = 'SET_COMMENT_PROGRESS';
export const UNSET_COMMENT = 'UNSET_COMMENT';

export const BACKGROUND_UPLOAD_CONFERENCE = 'BACKGROUND_UPLOAD_CONFERENCE';
export const UNSET_CONFERENCE = 'UNSET_CONFERENCE';
export const CREATE_CONFERENCE_PART = 'CREATE_CONFERENCE_PART';
export const SET_CONFERENCE_PROGRESS = 'SET_CONFERENCE_PROGRESS';

export const BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION = 'BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION';
export const UNSET_CONFERENCE_PRESENTATION = 'UNSET_CONFERENCE_PRESENTATION';
export const CREATE_CONFERENCE_PRESENTATION_PART = 'CREATE_CONFERENCE_PRESENTATION_PART';
export const SET_CONFERENCE_PRESENTATION_PROGRESS = 'SET_CONFERENCE_PRESENTATION_PROGRESS';

export const BACKGROUND_UPLOAD_CONFERENCE_SESSION = 'BACKGROUND_UPLOAD_CONFERENCE_SESSION';
export const UNSET_CONFERENCE_SESSION = 'UNSET_CONFERENCE_SESSION';
export const CREATE_CONFERENCE_SESSION_PART = 'CREATE_CONFERENCE_SESSION_PART';
export const SET_CONFERENCE_SESSION_PROGRESS = 'SET_CONFERENCE_SESSION_PROGRESS';

export const CANCEL_AXIOS_TOKEN = 'CANCEL_AXIOS_TOKEN';

/**
 * Action called after create post
 * @param post
 */
export const addPostForBackgroundUpload = (post, totalMediaSize) => {
	return {
		type: BACKGROUND_UPLOAD_POST,
		post: post,
		totalMediaSize: totalMediaSize
	};
};

/**
 * Action called after create post
 * @param post
 */
export const addCommentForBackgroundUpload = (comment, totalMediaSize) => {
	return {
		type: BACKGROUND_UPLOAD_COMMENT,
		comment: comment,
		totalMediaSize: totalMediaSize
	};
};

/**
 * Action called after get presigned URL for multipart
 * @param posts
 *
 * [Bulk import] Step 8.8: Persist the presigned URL of the current file chunk being uploaded under the appropriate reducer state
 */
export const createPart = (data) => {
	console.log('Create part: ', data);
	return {
		type: data?.entityName.toLowerCase() === 'post' ? CREATE_PART : `CREATE_${data?.entityName.toUpperCase()}_PART`,
		fileNumber: data.fileNumber,
		path: data.path,
		uploadId: data.uploadId,
		partNumber: data.partNumber,
		presignedUrl: data.presignedUrl,
		totalPartSize: data.totalPartSize
	};
};

/**
 * Action called for set upload progress
 */
export const setUploadProgress = (data) => {
	return {
		type: data?.entityName.toLowerCase() === 'post' ? SET_PROGRESS : `SET_${data?.entityName.toUpperCase()}_PROGRESS`,
		fileNumber: data.fileNumber,
		partNumber: data.partNumber,
		uploadedSize: data.uploadedSize
	};
};

/**
 * Action called for unset post
 */
// [Bulk import] Step 8.29: Mark the background upload as completed in the appropriate reducer state.
export const backgroundUploadingDone = (entityName = 'post') => {
	return {
		type: entityName.toLowerCase() === 'post' ? UNSET_POST : `UNSET_${entityName.toUpperCase()}`
	};
};

/**
 * Action called for unset post
 */
export const backgroundUploadingFailed = (entityName = 'post') => {
	return {
		type: entityName.toLowerCase() === 'post' ? UNSET_POST : `UNSET_${entityName.toUpperCase()}`
	};
};

/**
 * Action called for unset comment
 */
export const commentBackgroundUploadingDone = () => {
	return {
		type: UNSET_COMMENT
	};
};

/**
 * Action called for unset comment
 */
export const commentBackgroundUploadingFailed = () => {
	return {
		type: UNSET_COMMENT
	};
};

// ****************** ConferencePresentation background actions *******************************
/**
 * Action called after media upload for conference
 * @param post
 */
export const addConferencePresentationForBackgroundUpload = (conferencePresentation, totalMediaSize) => {
	return { type: BACKGROUND_UPLOAD_CONFERENCE_PRESENTATION, conferencePresentation, totalMediaSize };
};

// ****************** ConferenceSession background actions *******************************
/**
 * Action called after media upload for conference
 * @param post
 */
export const addConferenceSessionForBackgroundUpload = (conferenceSession, totalMediaSize) => {
	return { type: BACKGROUND_UPLOAD_CONFERENCE_SESSION, conferenceSession, totalMediaSize };
};

// ****************** Conference background actions *******************************
/**
 * Action called after media upload for conference
 * @param post
 */
export const addConferenceForBackgroundUpload = (conference, totalMediaSize) => {
	console.log('Action BACKGROUND_UPLOAD_CONFERENCE called');
	return { type: BACKGROUND_UPLOAD_CONFERENCE, conference, totalMediaSize };
};
