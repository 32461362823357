/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import { ACTIVATE_DEACTIVATE_PROCEDURE, CREATE_PROCEDURE, GET_ALL_PROCEDURES, UPDATE_PROCEDURE } from './ProcedureManagementActions';

const initialState = {
	procedures: []
};

/**
 * Procedures reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const ProcedureReducer = (state = initialState, action) => {
	switch (action.type) {
		//Get list of procedures to be shown
		case GET_ALL_PROCEDURES:
			return Object.assign({}, state, {
				procedures: action.procedures
			});

		//Append newly created procedure
		case CREATE_PROCEDURE:
			return Object.assign({}, state, {
				procedures: [...state.procedures.rows, action.procedure]
			});

		//Update procedure details
		case UPDATE_PROCEDURE: {
			let data = Object.assign({}, state.procedures);

			data.rows = data.rows.map((procedure) => {
				if (procedure.id === action.procedure.id) {
					return action.procedure;
				}
				return procedure;
			});

			return Object.assign({}, state, {
				procedures: data
			});
		}
		case ACTIVATE_DEACTIVATE_PROCEDURE: {
			let data = Object.assign({}, state.procedures);
			data.rows = data.rows.map((procedure) => {
				if (procedure.id === action.procedure.id) {
					procedure.enabled = !procedure.enabled;
					return procedure;
					// return action.procedure;
				}
				return procedure;
			});

			return Object.assign({}, state, {
				procedures: data
			});
		}

		default:
			return state;
	}
};

export default ProcedureReducer;
