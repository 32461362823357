import { createTheme } from '@material-ui/core/styles';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
	palette,
	typography,
	overrides,
	zIndex: {
		appBar: 1200,
		drawer: 1100
	},
	breakpoints: {
		values: {
			xs: 300,
			sm: 760,
			md: 960,
			lg: 1200,
			xl: 1536
		}
	}
});

export default theme;
