import React, { lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { ROUTE_ACTIONS } from './utils/Constants';
import AppleSiteAssociation from './utils/components/AppleSiteAssociation/AppleSiteAssociation';
import SuspenseContainer from './utils/components/SuspenseContainer/SuspenseContainer';
const PageNotAvailable = lazy(() => import('./utils/components/PageNotAvailable/PageNotAvailable'));
const Dashboard = lazy(() => import('./modules/Dashboard/Dashboard'));
const Home = lazy(() => import('./modules/Home/Home'));
const SignIn = lazy(() => import('./modules/Authentication/components/SignIn/SignIn'));
const Signup = lazy(() => import('./modules/Authentication/components/Signup/Signup'));
const VerificationCode = lazy(() => import('./modules/Authentication/components/VerificationCode/VerificationCode'));
const ChangeEmail = lazy(() => import('./modules/Authentication/components/ChangeEmail/ChangeEmail'));
const EnterEmail = lazy(() => import('./modules/Authentication/components/EnterEmail/EnterEmail'));
const ProfileSetup = lazy(() => import('./modules/Authentication/components/ProfileSetup/ProfileSetup'));
const ForgotPassword = lazy(() => import('./modules/Authentication/components/ForgotPassword/ForgotPassword'));
const ForgotPasswordNew = lazy(() => import('./modules/Authentication/components/ForgotPassword/ForgotPasswordNew'));
const ResetPassword = lazy(() => import('./modules/Authentication/components/ResetPassword/ResetPassword'));
const ResetPasswordNew = lazy(() => import('./modules/Authentication/components/ResetPassword/ResetPasswordNew'));
const ForceResetPass = lazy(() => import('./modules/Authentication/components/ForceResetPassword/ForceResetPass'));
const CommunitySelection = lazy(() => import('./modules/Authentication/components/CommunitySelection/CommunitySelection'));
const SpecialitySelection = lazy(() => import('./modules/Authentication/components/SpecialitySelection/SpecialitySelection'));
const SurgeonDashboard = lazy(() => import('./modules/Dashboard/SurgeonDashboard'));
const PublicPost = lazy(() => import('./modules/PublicPost/PublicPost'));
const PublicConference = lazy(() => import('./modules/Conferences/PublicConference'));
const Share = lazy(() => import('./modules/Share/Share'));
const OpenMobile = lazy(() => import('./modules/OpenMobile/OpenMobile'));
const InviteIR = lazy(() => import('./modules/InviteIR/InviteIR'));
const PageNotFound = lazy(() => import('./utils/components/PageNotFound/PageNotFound'));
const ReleaseVersion = lazy(() => import('./utils/components/ReleaseVersion/ReleaseVersion'));
const IntuitiveSignup = lazy(() => import('./modules/IntuitiveSignup/IntuitiveSignup'));
const AppleSignup = lazy(() => import('./modules/Authentication/components/SignIn/AppleLogin'));

function Routes() {
	return (
		<Router>
			<Switch>
				<Route
					exact
					path="/"
					component={(props) => (
						<SuspenseContainer>
							<Home {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SIGNUP}
					component={(props) => (
						<SuspenseContainer>
							<Signup {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.VERIFICATION}
					component={(props) => (
						<SuspenseContainer>
							<VerificationCode {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CHANGE_EMAIL_SURGEON}
					component={(props) => (
						<SuspenseContainer>
							<ChangeEmail {...props} />
						</SuspenseContainer>
					)}
				/>

				<Route exact path="/surgeonProfile/mentions/:id" render={({ match }) => <Redirect to={`/profile/${match.params.id}/mentions`} />} />

				<Route
					exact
					path={ROUTE_ACTIONS.ENTER_EMAIL}
					component={(props) => (
						<SuspenseContainer>
							<EnterEmail {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PROFILE_SETUP}
					component={(props) => (
						<SuspenseContainer>
							<ProfileSetup {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SIGNIN}
					component={(props) => (
						<SuspenseContainer>
							<SignIn {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SELECT_COMMUNITY}
					component={(props) => (
						<SuspenseContainer>
							<CommunitySelection {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SELECT_SPECIALITY}
					component={(props) => (
						<SuspenseContainer>
							<SpecialitySelection {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.FORGOT_PASSWORD}
					component={(props) => (
						<SuspenseContainer>
							<ForgotPasswordNew {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.RESET_PASSWORD}
					component={(props) => (
						<SuspenseContainer>
							<ResetPasswordNew {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.FORCE_RESET_PASSWORD}
					component={(props) => (
						<SuspenseContainer>
							<ForceResetPass {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.INVITE_IR}
					component={(props) => (
						<SuspenseContainer>
							<InviteIR {...props} />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.USERS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Users" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.ADD_USER}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Add Users" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.UPDATE_USER}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Update Users" />
						</SuspenseContainer>
					)}
				/>
				{/* <Route exact path={ROUTE_ACTIONS.CHANGE_PASSWORD}
					component={(props) => <SuspenseContainer><Dashboard {...props} menuName="Change Password"/></SuspenseContainer>}/> */}
				<Route
					exact
					path={ROUTE_ACTIONS.VENDORS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Vendors" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.VENDOR_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Vendor Details" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCES}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Conferences" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCE_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Conference Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCE_SESSION_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Conference Session Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCE_PRESENTATION_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Conference Presentation Details" />
						</SuspenseContainer>
					)}
				/>

				{/* <Route exact path={ROUTE_ACTIONS.PRODUCT_CATEGORY} component={(props) => <SuspenseContainer><Dashboard {...props} menuName="Product Category" /></SuspenseContainer>}/>
        <Route exact path={ROUTE_ACTIONS.ADD_VENDOR_PRODUCT} component={(props) => <SuspenseContainer><Dashboard {...props} menuName="Add Product" /></SuspenseContainer>}/>
        <Route exact path={ROUTE_ACTIONS.UPDATE_VENDOR_PRODUCT} component={(props) => <SuspenseContainer><Dashboard {...props} menuName="Update Product" /></SuspenseContainer>}/>
        <Route exact path={ROUTE_ACTIONS.PRODUCT_DETAILS} component={(props) => <SuspenseContainer><Dashboard {...props} menuName="Product Details" /></SuspenseContainer>}/> */}

				<Route
					exact
					path={ROUTE_ACTIONS.COMMUNITY_CATEGORY}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Community Category" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.COMMUNITIES}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Communities" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.ADD_COMMUNITY}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Add Community" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.UPDATE_COMMUNITY}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Update Community" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.COMMUNITY_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Community Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.DIAGNOSIS}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Diagnosis" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SPECIALITY}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Specilaity" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.DOMAIN}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Domain" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PROCEDURE}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Procedure" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.APPROACH}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Approach" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.ACTUAL_STEP}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Actual Step" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.DASHBOARD}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="User Management" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CONTENT_MANAGEMENT}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Content Management" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CHANGE_EMAIL}
					component={(props) => (
						<SuspenseContainer>
							<Dashboard {...props} menuName="Change Email" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.LIVE_CONFERENCE_SESSION_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Conference Session" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PUBLIC_CONFERENCE}
					component={(props) => (
						<SuspenseContainer>
							<PublicConference {...props} menuName="Public Conference" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PUBLIC_CONFERENCE_SESSION}
					component={(props) => (
						<SuspenseContainer>
							<PublicConference {...props} menuName="Public Conference Session" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PUBLIC_CONFERENCE_PRESENTATION}
					component={(props) => (
						<SuspenseContainer>
							<PublicConference {...props} menuName="Public Conference Presentation" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.LIVE_EVENT}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Live Event" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_DASHBOARD}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Surgeon Dashboard" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.POST_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Post Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.NOTIFICATIONS}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Notifications" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.MESSAGES}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.CHAT_BOX}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.CHAT_BOX_USER}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCE_CHAT_BOX}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.CONFERENCE_SESSION_CHAT_BOX}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.NEW_CHAT_BOX}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Messages" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_PROFILE_WITH_ID}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Post Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_PROFILE}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Post Details" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.PUBLIC_POST}
					component={(props) => (
						<SuspenseContainer>
							<PublicPost {...props} menuName="Public Post" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SHARE}
					component={(props) => (
						<SuspenseContainer>
							<Share {...props} menuName="Share" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.ALL_COMMUNITIES}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="All Communities" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_COMMUNITIES}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Communities" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_COMMUNITY}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Community Details" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.SURGEON_COMMUNITY_DETAILS}
					component={(props) => (
						<SuspenseContainer>
							<SurgeonDashboard {...props} menuName="Community Details" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.INTUITIVE_SIGNUP}
					component={(props) => (
						<SuspenseContainer>
							<IntuitiveSignup {...props} menuName="IntuitiveSignup" />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.APPLE_SIGNUP}
					component={(props) => (
						<SuspenseContainer>
							<AppleSignup {...props} menuName="AppleSignup" />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.OPEN_MOBILE}
					component={(props) => (
						<SuspenseContainer>
							<OpenMobile {...props} menuName="Surgeon" />
						</SuspenseContainer>
					)}
				/>

				<Route exact path={ROUTE_ACTIONS.APPLE_SITE_ASSOCIATION} component={(props) => <AppleSiteAssociation />} />

				<Route
					exact
					path={ROUTE_ACTIONS.RELEASE_VERSION}
					component={() => (
						<SuspenseContainer>
							<ReleaseVersion />
						</SuspenseContainer>
					)}
				/>

				<Route
					exact
					path={ROUTE_ACTIONS.PAGE_NOT_FOUND}
					component={() => (
						<SuspenseContainer>
							<PageNotFound />
						</SuspenseContainer>
					)}
				/>
				<Route
					exact
					path={ROUTE_ACTIONS.PAGE_NOT_AVAILABLE}
					component={() => (
						<SuspenseContainer>
							<PageNotAvailable />
						</SuspenseContainer>
					)}
				/>

				<Route
					component={() => (
						<SuspenseContainer>
							<PageNotFound />
						</SuspenseContainer>
					)}
				/>
			</Switch>
		</Router>
	);
}

export default Routes;
